import React, { useEffect, useState } from "react";
import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import GridMako from "./GridMako";
import GridTrending from "./GridTrending";
import GridDealCanMiss from "./GridDealCanMiss";
import GridEndSoon from "./GridEndSoon";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Highlight = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [couponCategory, setCouponCategory] = useState([]);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [couponItems, setCouponItems] = useState([]);
  // console.log({ couponCategory });


  useEffect(() => {
    Axios.post(`${baseURL}api/coupons/getCouponCategory`).then((response) => {
      if (response.data.success) {
        setCouponCategory(response.data.couponCategory);
        setDataSuccess(true);
      } else {
        alert("Failed to get coupon Category");
      }
    });

    // get all cooupon Items
    // Axios.get(`${baseURL}api/coupons/getAllCouponItems`).then((response) => {
    //   if (response.data.success) {
    //     setCouponItems(response.data.couponItems);
    //     setDataSuccess(true)
    //   } else {
    //     alert("Failed to get coupon Category");
    //   }
    // });



 





  }, []);


  if (dataSuccess) {
    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{}}
          display={"flex"}
          alignItems={"center"}
          mx={{ xs: "20px", md: 0 }}
        >
          <Typography
            mr={"1rem"}
            color={"primary.contrastText"}
            fontWeight={500}
          >
            View:{" "}
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            TabIndicatorProps={{ sx: { backgroundColor: "secondary.black" } }}
            sx={{
              "& button": { borderRadius: "5px" },
              "& button:hover": {
                backgroundColor: "primary.white",
                fontWeight: 700,
              },
              "& button:active": { color: "secondary.black", fontWeight: 900 },
              "& button:focus": { color: "secondary.black", fontWeight: 700 },
              "& button.Mui-selected": { color: "secondary.black" },
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { display: "none" },
              },
            }}
          >
            {couponCategory.map((category, index) => (
              <Tab label={category.name} {...a11yProps({ index })} wrapped />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} fullWidth>
          <GridMako data={"65f0f2ba581d486f06bb9c67"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GridTrending data={"65f0f2ba581d486f06bb9c68"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <GridDealCanMiss data={"65f0f2ba581d486f06bb9c69"}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <GridEndSoon data={"65f0f2ba581d486f06bb9c6a"}/>
        </CustomTabPanel>
      </Box>
    );
  } else {
    return <Loading3 />;
  }
};

export default Highlight;
