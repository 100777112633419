import { useSelector, useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  useScrollTrigger,
  Badge,
} from "@mui/material";
import { cloneElement, useState } from "react";
import { Link } from "react-router-dom";
import menuConfigs from "../../configs/menu.configs";
import themeConfigs, { themeModes } from "../../configs/theme.configs";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setAutOperatorhModalOpen } from "../../redux/features/authOperModalSlice";
import { setThemeMode } from "../../redux/features/themeModeSlice";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import Sidebar from "./Sidebar";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useNavigate } from "react-router-dom";
import { Spin as Hamburger } from "hamburger-react";

// new icon///
import {
  PersonOutline,
  ShoppingBagOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { Typography } from "antd";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import Search from "./search/Search";

const ScrollAppBar = ({ children, window }) => {
  const { themeMode } = useSelector((state) => state.themeMode);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    sx: {
      color: trigger
        ? "text.primary"
        : themeMode === themeModes.light
        ? "primary.white"
        : "text.primary",
      backgroundColor: trigger
        ? "background.paper"
        : themeMode === themeModes.light
        ? "transparent"
        : "background.paper",
    },
  });
};
const Topbar = ({ socketId }) => {
  const { user } = useSelector((state) => state.user);
  const { appState } = useSelector((state) => state.appState);
  // console.log({appState})
  const { themeMode } = useSelector((state) => state.themeMode);
  // console.log({ themeMode });



  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [open, setMenuOpen] = useState(false);

  const onSwithTheme = () => {
    const theme =
      themeMode === themeModes.dark ? themeModes.light : themeModes.dark;
    dispatch(setThemeMode(theme));
  };

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />
      <ScrollAppBar>
        <AppBar elevation={0} sx={{ zIndex: 1 }}>
          {/* <Box
            height={{ lg: "30px", xs: "40px" }}
            sx={{ background: "#000", color: "#fff" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <p style={{ color: "white" }}>
              CELEBRATE VALENTINE'S DAY. MORE GIFT IDEAS
            </p>
          </Box> */}
          <Toolbar
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              flex: "1",
              // border: "1px solid green",
            }}
          >
            {/* <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              // height="10rem"
            >
              <Box
                sx={{ display: { xs: "inline-block", md: "none" } }}
                onClick={toggleSidebar}
              >
                <Logo />
              </Box>
            </Stack> */}

            {/* main menu */}
            <Box
              flex="1"
              alignItems="center"
              display={{ xs: "none", md: "flex" }}
              // height="10rem"
              // border="1px solid red"
              padding={{ xs: "5px", md: "15px" }}
            >
              {menuConfigs.main.map((item, index) => (
                <Button
                  key={index}
                  sx={{
                    color: appState.includes(item.state)
                      ? "primary.red"
                      : "inherit",
                    mr: 2,
                    textTransform:'capitalize'
                  }}
                  component={Link}
                  to={item.path}
                  // variant={appState.includes(item.state) ? "contained" : "text"}
                >
                  {item.display}
                </Button>
              ))}
            </Box>
            <Box
              flex="1"
              // border="1px solid red"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ xs: "5px", md: "10px" }}
            >
              <Logo />
            </Box>
            {/* <Box
              flex="1"
              border="1px solid red"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding={{ xs: "5px", md: "10px" }}
            >
              <Search />
            </Box> */}
            {/* user menu */}
            <Box
              flex="1"
              // border="1px solid red"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Stack spacing={0} direction="row" alignItems="center">
                {!user && (
                  <>
                    {/* <Box
                      borderRight="1px solid gray"
                      padding={{ xs: "2px", md: "10px" }}
                    >
                      <IconButton
                        sx={{
                          color: "inherit",
                        }}
                      >
                        <NotificationsNoneIcon />
                        {notifications.length > 0 && (
                          <div className="counter">{notifications.length}</div>
                        )}
                      </IconButton>
                    </Box> */}
                    {/* <Box
                      borderRight="1px solid gray"
                      padding={{ xs: "2px", md: "10px" }}
                    >
                      <IconButton
                        sx={{
                          color: "inherit",
                        }}
                        onClick={() => navigate(`/lokalai`)}
                      >
                        <QrCodeIcon />
                      </IconButton>
                    </Box> */}
                    {/* <Box
                      borderRight="1px solid gray"
                      padding={{ xs: "2px", md: "10px" }}
                    >
                      <IconButton
                        sx={{ color: "inherit" }}
                        onClick={onSwithTheme}
                      >
                        {themeMode === themeModes.dark && (
                          <DarkModeOutlinedIcon />
                        )}
                        {themeMode === themeModes.light && (
                          <WbSunnyOutlinedIcon />
                        )}
                      </IconButton>
                    </Box> */}
                    <Box
                      borderRight="1px solid gray"
                      padding={{ xs: "2px", md: "10px" }}
                    >
                      <IconButton
                        sx={{
                          color: "inherit",
                        }}
                        onClick={() => dispatch(setAuthModalOpen(true))}
                      >
                        <PersonOutline />
                      </IconButton>
                    </Box>
                    {/* <IconButton
                      sx={{
                        color: "inherit",
                      }}
                    >
                      <a
                      href="https://operator.lokalai.com/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color:'inherit',
                        border: '1px solid red',
                        padding:0,
                        margin:'0px'
                      }}
                    >
                      <StorefrontOutlinedIcon />
                    </a>
                    </IconButton> */}
                  </>
                )}
                {/* 
              <IconButton
                color="inherit"
                sx={{ mr: 2, display: { md: "none" } }}
                
              > */}
                {/* <MenuIcon /> */}

                {/* </IconButton> */}

              </Stack>
              {user && <UserMenu socketId={socketId} />}
              <Hamburger
                  toggled={sidebarOpen}
                  toggle={setSidebarOpen}
                  duration={0.8}
                  size={20}
                />
            </Box>
            {/* user menu */}
          </Toolbar>
        </AppBar>
      </ScrollAppBar>
    </>
  );
};

export default Topbar;
