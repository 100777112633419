import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import theme, { shades } from "../../theme";

function Footer() {
  const {
    palette: { neutral },
  } = useTheme();
  return (
    <Box
      marginTop="70px"
      margin="0 auto"
      padding="40px 0"
      display="grid"
      // gridTemplateRows="1fr 2fr"
      gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
      // border="1px solid red"
    >
      <Box
        padding="00px 0"
        // border="1px solid green"
        width="80%"
        margin="0 auto"
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          mb="30px"
          color="#000"
          // color={shades.secondary[500]}
        >
          ROYAL VIETNAMESE CAFE
        </Typography>
        <Typography mb="30px">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat
        </Typography>
      </Box>
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
        // border="1px solid green"
      >
        <Box>
          <Typography variant="h5" fontWeight="bold" mb="30px">
            About Us
          </Typography>
          <Typography mb="00px">Careers</Typography>
          <Typography mb="00px">Our Stores</Typography>
          <Typography mb="00px">Terms & Conditions</Typography>
          <Typography mb="30px">Privacy Policy</Typography>
        </Box>

        <Box>
          <Typography variant="h5" fontWeight="bold" mb="30px">
            Customer Care
          </Typography>
          <Typography mb="00px">Help Center</Typography>
          <Typography mb="00px">Track Your Order</Typography>
          <Typography mb="00px">Corporate & Bulk Purchasing</Typography>
          <Typography mb="30px">Returns & Refunds</Typography>
        </Box>

        <Box>
          <Typography variant="h5" fontWeight="bold" mb="30px">
            Contact Us
          </Typography>
          <Typography mb="0px">
            8 Brake Street, Upper Riccarton, Christchurch 8041
          </Typography>
          <Typography mb="0px" sx={{ wordWrap: "break-word" }}>
            Email: royalvietnamesecafe@gmail.com
          </Typography>
          <Typography mb="30px">03 348 9381</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
