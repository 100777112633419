import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Box } from "@mui/system";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModalOpen } from "../../../redux/features/authModalSlice";
import { baseURL } from "../../../api/client/URL";
import { toast } from "react-toastify";

function Subscriber(props) {
  // console.log("subcribe props :", { props });

  const { user } = useSelector((state) => state.user);
  // console.log({ user });
  const dispatch = useDispatch();

  const userTo = props.userTo;

  const [SubscribeNumber, setSubscribeNumber] = useState(0);
  const [Subscribed, setSubscribed] = useState(false);
  const [joinRoyaled, setJoinRoyaled] = useState(false);
  const [joinRoyalNumber, setJoinRoyalNumber] = useState(0);

  useEffect(() => {
    const subscribeNumberVariables = { userTo: userTo };
    //GET FOLLOW (SUBCRIBER) NUMBER
    axios
      .post(`${baseURL}api/subscribe/subscribeNumber`, subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setSubscribeNumber(response.data.subscribeNumber);
        } else {
          alert("Failed to get subscriber Number");
        }
      });
    if (!user) return dispatch(setAuthModalOpen(true));
    // GET SUBCRIBER INFORMATION (SUBCRIBE OR NOT)

    if (user) {
      const subscribeInformationVariables = {
        userTo: userTo,
        userFrom: user.id,
      };
      axios
        .post(
          `${baseURL}api/subscribe/subscribed`,
          subscribeInformationVariables
        )
        .then((response) => {
          if (response.data.success) {
            setSubscribed(response.data.subcribed);
            // console.log("response.data.subcribed", response.data.subcribed);
            toast.info("successull get subcribed  of the user");
          } else {
            alert("Failed to get Subscribed Information");
          }
        });
    }

    //GET JOIN ROYAL PROGRAM NUMBER
    axios
      .post(`${baseURL}api/royalprogram/joinNumber`, subscribeNumberVariables)
      .then((response) => {
        if (response.data.success) {
          setJoinRoyalNumber(response.data.joinNumber);
        } else {
          alert("Failed to get join Royal program Number");
        }
      });
  }, []);
  let joinVariables = {
    userTo: userTo,
    userFrom: user.id,
  };

  axios
    .post(`${baseURL}api/royalprogram/joined`, joinVariables)
    .then((response) => {
      if (response.data.success) {
        // console.log(
          // `${user.displayName} JOINED ROYAL PROGRAM :`,
          // response.data.joined
        // );
        setJoinRoyaled(response.data.joined);
      } else {
        alert("Failed to get Subscribed Information");
      }
    });

  const onSubscribe = () => {
    console.log({ Subscribed });

    let subscribeVariables = {
      userTo: userTo,
      userFrom: user.id,
    };

    console.log(subscribeVariables);
    if (Subscribed) {
      //when we are already subscribed
      axios
        .post(`${baseURL}api/subscribe/unSubscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            setSubscribeNumber(SubscribeNumber - 1);
            setSubscribed(!Subscribed);
            console.log(response.data.success);
          } else {
            alert("Failed to unsubscribe");
          }
        });
    } else {
      // when we are not subscribed yet

      axios
        .post(`${baseURL}api/subscribe/subscribe`, subscribeVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setSubscribeNumber(SubscribeNumber + 1);
            setSubscribed(!Subscribed);
          } else {
            alert("Failed to subscribe");
          }
        });
    }
  };

  const onJoinRoyal = () => {
    let joinVariables = {
      userTo: userTo,
      userFrom: user.id,
    };

    console.log({ joinVariables });

    //GET USER ALREADY JOINED ROYAL PROGRAM

    if (joinRoyaled) {
      //when users are already joined Royalty Program -> UnJoin
      axios
        .post(`${baseURL}api/royalprogram/unjoinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            setJoinRoyalNumber(joinRoyalNumber - 1);
            setJoinRoyaled(!joinRoyaled);
            console.log(response.data.success);
          } else {
            alert("Failed to unjoin loyalty program");
          }
        });
    } else {
      // when users are not joint the Royalty Program -> JOINED

      axios
        .post(`${baseURL}api/royalprogram/joinLoyalty`, joinVariables)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.success);
            setJoinRoyalNumber(joinRoyalNumber + 1);
            setJoinRoyaled(!joinRoyaled);
          } else {
            alert("Failed to join loyalty program");
          }
        });
    }
  };

  return (
    <Box display="flex" flexDirection={{ xs: "row", md: "row", lg: "row" }} alignItems={"center"}>
      <Box display="flex" flexDirection="column" justifyContent="flex-start" mr={2}>
        <span
          style={{
            textTransform: "capitalize",
            fontWeight: "600",

          }}
        >
          {props.operatorname}
        </span>
        <small style={{display: "flex", alignItems:"center",}}>
          {SubscribeNumber} followers{" "}
          {Subscribed ? `${joinRoyalNumber} joined Royalty` : null}{" "}
        </small>
      </Box>
      <Box
        onClick={onSubscribe}
        sx={{
          backgroundColor: `${Subscribed ? "transparent" : "primary.black"}`,
          color: "white",
          borderRadius: "25px",
          padding: "5px",
          margin: "auto",
        }}
        ml={1}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px 10px 5px  10px",
            textTransform: "capitalize",
            fontSize: "0.8rem",
          }}
        >
          {/* {Subscribed ? <NotificationsOutlinedIcon /> : null} */}
          {Subscribed ? <HowToRegIcon sx={{color: "black"}}/> : "follow"}
        </span>
      </Box>
      {Subscribed && (
        <>
          <Box
            onClick={onJoinRoyal}
            sx={{
              backgroundColor: `${
                joinRoyaled ? "transparent" : "primary.black"
              }`,
              border: `${joinRoyaled ? "1px solid black" : null}`,
              borderRadius: "25px",
              padding: "5px",
              margin: "auto",
              width: "70px",
            }}
          >
            {/* {SubscribeNumber} {Subscribed ? 'followed' : 'follow'} */}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
                justifyContent: "center",
                textTransform: "capitalize",
                fontSize: "0.8rem",
                color:`${joinRoyaled ? "black" : "#fff"}`,
              }}
            >
              {joinRoyaled ? "Joined" : "Join"}
            </span>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Subscriber;
