import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import themeConfigs from "./configs/theme.configs";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import routes from "./routes/routes";
import PageWrapper from "./components/common/PageWrapper";
//socket client
import socketIO from "socket.io-client";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Admin Dashboard section
import AdminDashboard from "./components/layout/AdminDashboard";
import Dashboard from "./adminDashboard/pages/Dashboard";
import Enquiries from "./adminDashboard/pages/Enquiries";
import Bloglist from "./adminDashboard/pages/Bloglist";
import Productlist from "./adminDashboard/pages/Productlist";
import Addproduct from "./adminDashboard/pages/Addproduct";

//------- User dashboard ------------------------------
import UserDashboard from "./components/layout/UserDashboard";

//--------Menu section----------------------------------------
import Menusection from "./3.menuSection/menu";
import ItemDetail from "./3.menuSection/scenes/itemDetails/ItemDetails";

//---------Upload section
import UploadImage from "./adminDashboard/pages/UploadImage";
import UploadVideo from "./adminDashboard/pages/UploadVideo";
// import Bookme from "./adminDashboard/pages/Bookme/Bookme";

//--------Product Detail---------------------------------
// import BestDealDetail from "./pages/BestDealDetail";
// import TrendDetail from "./pages/TrendDetail";
// import CannotMissDetail from "./pages/CannotMissDetail";
// import EndSoonDetail from "./pages/EndSoonDetail";
//-------Scroll to top ---------------------------------
import ScrollToTop from "./components/common/ScrollToTop";
import CartMenu from "./pages/CartMenu";
// import RoyalDetailItem from "./pages/RoyalDetailItem";
import QRcodePage from "./pages/QRcodepage";
//---------OPERATOR DETAIL------------
import OperatorDetail from "./pages/OperatorDetail";
import FollowingPage from "./pages/FollowingPage";
import QRLokalai from "./pages/QRLokalai";
import ProfileUpdate from "./pages/ProfileUpdate";
import ProtectedPage from "./components/common/ProtectedPage";
import { Socket_URL } from "./api/client/URL";
//--------------------------------------------------------------------------------------
import UserInbox from "./pages/UserInbox";
import Royalty from "./pages/Royalty";

import userApi from "./api/modules/user.api";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/features/userSlice";
import { setAuthModalOpen } from "./redux/features/authModalSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { Client_Google_URL, baseURL } from "./api/client/URL";
import MenuPage from "./pages/Menu";

//-------------check out order --------------------------------------------//
import CheckPOutPage from "./pages/CheckPOutPage";
import CouponDetailItem from "./pages/CouponDetailItem";
import Whaton from "./pages/Whaton";
import Discover from "./pages/Discover";
import Booking from "./pages/Booking";

const ENDPOINT = Socket_URL;

const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });

const App = () => {
  const { themeMode } = useSelector((state) => state.themeMode);
  // console.log("socket APP.js client :",socketId)
  const dispatch = useDispatch();

  //googe login
  const [userGoogle, setUserGoogle] = useState([null]);
  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [stopEffect, setStopEffect] = useState(false);
  const [userObject, setUserObject] = useState();

  // useEffect(() => {
  //   axios.get(`${baseURL}api/google`, {}).then((res) => {
  //     // console.log(res.data);
  //     if (res.data) {
  //       setUserGoogle(res.data);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${Client_Google_URL}auth/getuser`, { withCredentials: true })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data) {
  //         setUserGoogle(res.data);
  //       }
  //     });
  // }, []);

  // useEffect(() => {
  //   const getUser = () => {
  //     fetch(`${Client_Google_URL}auth/getuser`, {
  //       method: "GET",
  //       credentials: "include",
  //       mode: "no-cors",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       //   "Access-Control-Allow-Credentials": true,
  //       //   "Access-Control-Allow-Headers":"*"
  //       // },
  //     })
  //       .then((response) => {
  //         if (response.status === 200) return response.json();
  //         throw new Error("authentication has been failed!");
  //       })
  //       .then((resObject) => {
  //         setUserGoogle(resObject.user);
  //         console.log(resObject.user);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   getUser();
  //   // console.log({user})
  // }, []);

  // useEffect(() => {
  //   if (!stopEffect) {
  //     const Signin = async () => {
  //       const values = {
  //         // username: userGoogle._json.email,
  //         username: userGoogle[userGoogle.length - 1].email,
  //         password: "googleprovider",
  //       };
  //       try {
  //         setErrorMessage(undefined);
  //         setIsLoginRequest(true);
  //         const { response, err } = await userApi.signin(values);
  //         // console.log({err})
  //         setIsLoginRequest(false);

  //         if (response) {
  //           //try to sign in first
  //           dispatch(setUser(response));
  //           dispatch(setAuthModalOpen(false));
  //           toast.success("Sign in success");
  //         } else if (err) {
  //           setStopEffect(true);
  //           const signUpInfo = {
  //             // username: userGoogle._json.email,
  //             // displayName: userGoogle._json.name,
  //             // password: "googleprovider",
  //             // confirmPassword: "googleprovider",
  //             // photo: userGoogle._json.picture,

  //             username: userGoogle[userGoogle.length - 1].email,
  //             displayName: userGoogle[userGoogle.length - 1].displayName,
  //             password: "googleprovider",
  //             confirmPassword: "googleprovider",
  //             photo: userGoogle[userGoogle.length - 1].image,
  //           };
  //           //if err then sign up a new user
  //           setErrorMessage(undefined);
  //           setIsLoginRequest(true);
  //           const { response, err } = await userApi.signup(signUpInfo);
  //           setIsLoginRequest(false);

  //           if (response) {
  //             dispatch(setUser(response));
  //             dispatch(setAuthModalOpen(false));
  //             toast.success("Sign up success");
  //           }
  //         }

  //         setStopEffect(true);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
  //     Signin();
  //   }
  // });

  return (
    <>
      <ThemeProvider theme={themeConfigs.custom({ mode: themeMode })}>
        {/* config toastify */}

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          theme={themeMode}
        />
        {/* mui reset css */}
        <CssBaseline />

        {/* app routes */}
        <BrowserRouter>
          <Routes>
            {/* Admin Dashboard section */}

            <Route path="/admin-dashboard" element={<AdminDashboard />}>
              <Route index element={<Dashboard />} />
              <Route path="enquiries" element={<Enquiries />} />
              <Route path="blog-list" element={<Bloglist />} />

              <Route path="list-product" element={<Productlist />} />
              <Route path="product" element={<Addproduct />} />
              {/* Menu Dashboard section */}
              <Route path="menu" element={<Menusection />} />
              <Route path="menu/item/:itemId" element={<ItemDetail />} />
              {/* Upload section */}
              <Route path="image" element={<UploadImage />} />
              <Route path="video" element={<UploadVideo />} />
              {/* <Route path="bookme" element={<Bookme />} /> */}
            </Route>

            <Route path="/" element={<MainLayout socketId={socketId} />}>
              {/* <Route path="bestdeal/:bestdealId" element={<BestDealDetail />} />
              <Route path="trend/:trendId" element={<TrendDetail />} />
              <Route path="missdeal/:missId" element={<CannotMissDetail />} />
              <Route path="endDeal/:endingId" element={<EndSoonDetail />} /> */}
              {/* <Route path="royal/:royalId" element={<RoyalDetailItem />} /> */}
              <Route path="operator/:operatorId" element={<OperatorDetail />} />
              <Route path="operator/menu/:operatorId" element={<MenuPage />} />

              {/* Detail Coupon Item - March 24 */}
              <Route path="coupons/:couponId" element={<CouponDetailItem />} /> 
              {/* What's on Page */}
              <Route path="whaton" element={<Whaton />} /> 
              {/* Discover Page */}
              <Route path="discover" element={<Discover />} /> 

              <Route
                path="checkout/:operatorId"
                element={
                  <ProtectedPage>
                    <CheckPOutPage />
                  </ProtectedPage>
                }
              />
              {/* booking Page */}
              <Route
                path="booking/:operatorId"
                element={
                  <ProtectedPage>
                    <Booking />
                  </ProtectedPage>
                }
              />
              <Route
                path="following"
                element={
                  <ProtectedPage>
                    <FollowingPage />
                  </ProtectedPage>
                }
              />
              <Route path="lokalai" element={<QRLokalai />} />
              <Route path="profileUpdate" element={<ProfileUpdate />} />
              <Route
                path="userInbox"
                element={
                  <ProtectedPage>
                    <UserInbox socketId={socketId} />
                  </ProtectedPage>
                }
              />

              {/* Navigate to stamp page */}
              <Route
                path="qrcode"
                element={
                  <ProtectedPage>
                    <QRcodePage socketId={socketId} />
                  </ProtectedPage>
                }
              />
              {/* live stamp page */}
              <Route
                path="royalty"
                element={
                  <ProtectedPage>
                    <Royalty socketId={socketId} />
                  </ProtectedPage>
                }
              />

              {routes.map((route, index) =>
                route.index ? (
                  <Route
                    index
                    key={index}
                    element={
                      route.state ? (
                        <PageWrapper state={route.state}>
                          {route.element}
                        </PageWrapper>
                      ) : (
                        route.element
                      )
                    }
                  />
                ) : (
                  <Route
                    path={route.path}
                    key={index}
                    element={
                      route.state ? (
                        <PageWrapper state={route.state}>
                          {route.element}
                        </PageWrapper>
                      ) : (
                        route.element
                      )
                    }
                  />
                )
              )}
            </Route>
            {/* User Dashboard section */}
            <Route path="/user-dashboard" element={<UserDashboard />}>
              {/* <Route index element={<Dashboard1 />} /> */}
            </Route>
          </Routes>
          <CartMenu />
        </BrowserRouter>
        {/* app routes */}

        <ScrollToTop />
      </ThemeProvider>
    </>
  );
};

export default App;
