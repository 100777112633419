import React, { useEffect, useState } from "react";
import axios from "axios";

import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Container from "../components/common/Container";
import uiConfigs from "../configs/ui.configs";
import userApi from "../api/modules/user.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setUser } from "../redux/features/userSlice";
import { setAuthModalOpen } from "../redux/features/authModalSlice";
import { baseURL } from "../api/client/URL";
import { useDispatch, useSelector } from "react-redux";

const ProfileUpdate = () => {
  window.scrollTo(0, 0);
  const { user } = useSelector((state) => state.user);

  const [onRequest, setOnRequest] = useState(false);

  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userInforVariable = { userId: user.id };

  useEffect(() => {
    axios
      .post(`${baseURL}api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });
  }, []);

  const form = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "password minimum 8 characters")
        .required("password is required"),
      newPassword: Yup.string()
        .min(8, "newPassword minimum 8 characters")
        .required("newPassword is required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "confirmNewPassword not match")
        .min(8, "confirmNewPassword minimum 8 characters")
        .required("confirmNewPassword is required"),
    }),
    onSubmit: async (values) => onUpdate(values),
  });

  const onUpdate = async (values) => {
    axios
      .post(`${baseURL}api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });
    // if (onRequest) return;
    // setOnRequest(true);

    // const { response, err } = await userApi.passwordUpdate(values);

    // setOnRequest(false);

    // if (err) toast.error(err.message);
    // if (response) {
    //   form.resetForm();
    //   navigate("/");
    //   dispatch(setUser(null));
    //   dispatch(setAuthModalOpen(true));
    //   toast.success("Update password success! Please re-login");
    // }
  };

  return (
    <Box sx={{ ...uiConfigs.style.mainContent }}>
      <Container header="update profile information">
        <Box component="form" maxWidth="400px" onSubmit={form.handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label="Display Name"
              type="text"
              placeholder="password"
              name="password"
              fullWidth
              value={userInfo.displayName}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="Photo URL"
              fullWidth
              value={userInfo.photo}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="Gender"
              fullWidth
              value={userInfo.gender}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="UserName"
              fullWidth
              value={userInfo.username}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="Biography"
              fullWidth
              value={userInfo.bio}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="Date of birth"
              fullWidth
              placeholder={userInfo.dateOfBirth}
              value={userInfo.dateOfBirth}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="text"
              label="Phone"
              fullWidth
              value={userInfo.phone}
              onChange={form.handleChange}
              color="success"
            />
            <TextField
              type="password"
              placeholder="password"
              name="password"
              fullWidth
              value={form.values.password}
              onChange={form.handleChange}
              color="success"
              error={
                form.touched.password && form.errors.password !== undefined
              }
              helperText={form.touched.password && form.errors.password}
            />
            <TextField
              type="password"
              placeholder="new password"
              name="newPassword"
              fullWidth
              value={form.values.newPassword}
              onChange={form.handleChange}
              color="success"
              error={
                form.touched.newPassword &&
                form.errors.newPassword !== undefined
              }
              helperText={form.touched.newPassword && form.errors.newPassword}
            />
            <TextField
              type="password"
              placeholder="confirm new password"
              name="confirmNewPassword"
              fullWidth
              value={form.values.confirmNewPassword}
              onChange={form.handleChange}
              color="success"
              error={
                form.touched.confirmNewPassword &&
                form.errors.confirmNewPassword !== undefined
              }
              helperText={
                form.touched.confirmNewPassword &&
                form.errors.confirmNewPassword
              }
            />

            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginTop: 4 }}
              loading={onRequest}
            >
              update password
            </LoadingButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default ProfileUpdate;
