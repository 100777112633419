import { Grid } from "@mui/material";
import JoinRoyaltyItem from "./JoinRoyaltyItem";

const JoinRoyaltyGrid = ({ medias }) => {
  // console.log({medias})
  return (
    <Grid container spacing={1} sx={{ marginRight: "-8px!important" }}>
      {medias.map((media, index) => (
        <Grid item xs={12} sm={4} md={3} key={index}>
          <JoinRoyaltyItem media={media} />
        </Grid>
      ))}
    </Grid>
  );
};

export default JoinRoyaltyGrid;