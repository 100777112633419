import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  deepPurple,
  red,
  blue,
  blueGrey,
  purple,
  lightBlue,
  pink,
  green,
  amber,
  brown,
  orange,
  grey,
  deepOrange,
} from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import { useNavigate } from "react-router-dom";

const GridDealCanMiss = ({ data }) => {
  const [dealCanMiss, setDealCanMiss] = useState([]);
  // console.log({ dealCanMiss });
  const [dataSuccess, setDataSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const dealcanmiss = {
      _id: data,
    };
    Axios.post(`${baseURL}api/coupons/getCouponItems`, dealcanmiss).then(
      (response) => {
        if (response.data.success) {
          toast.success("get Deal can miss coupon item");
          setDealCanMiss(response.data.couponItems);
          setDataSuccess(true);
        } else {
          alert("Failed to get coupon item");
        }
      }
    );
  }, []);
  // console.log({data})
  const isNonMobile = useMediaQuery("(min-width:600px)");

  var min = 0;
  var max = 4;
  var random = Math.round(min + Math.random() * (max - min));

  console.log({random})


  if (dataSuccess) {
    return (
      <>
        <Box
          mt={0}
          display="grid"
          gap="0px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        >
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 0 < dealCanMiss.length ? random + 0 : random + 0 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random+0 < dealCanMiss.length ? random + 0 : random + 0 - dealCanMiss.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={lightBlue[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random+0 < dealCanMiss.length ? random + 0 : random + 0 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {dealCanMiss[random+0 < dealCanMiss.length ? random + 0 : random + 0 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random+0 < dealCanMiss.length ? random + 0 : random + 0 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 1 < dealCanMiss.length ? random + 1 : random + 1 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 1 < dealCanMiss.length ? random + 1 : random + 1 - dealCanMiss.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={pink[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 1 < dealCanMiss.length ? random + 1 : random + 1 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {dealCanMiss[random + 1 < dealCanMiss.length ? random + 1 : random + 1 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 1 < dealCanMiss.length ? random + 1 : random + 1 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 2 < dealCanMiss.length ? random + 2 : random + 2 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 2 < dealCanMiss.length ? random + 2 : random + 2 - dealCanMiss.length].images[2]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={deepOrange[800]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 2 < dealCanMiss.length ? random + 2 : random + 2 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {dealCanMiss[random + 2 < dealCanMiss.length ? random + 2 : random + 2 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 2 < dealCanMiss.length ? random + 2 : random + 2 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 3 < dealCanMiss.length ? random + 3 : random + 3 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 3 < dealCanMiss.length ? random + 3 : random + 3 - dealCanMiss.length3].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={blueGrey[200]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 3 < dealCanMiss.length ? random + 3 : random + 3 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {dealCanMiss[random + 3 < dealCanMiss.length ? random + 3 : random + 3 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 3 < dealCanMiss.length ? random + 3 : random + 3 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[4]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 4 < dealCanMiss.length ? random + 4 : random + 4 - dealCanMiss.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={purple[800]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 4 < dealCanMiss.length ? random + 4 : random + 4 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {dealCanMiss[random + 4 < dealCanMiss.length ? random + 4 : random + 4 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 4 < dealCanMiss.length ? random + 4 : random + 4 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={100}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 2" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 5 < dealCanMiss.length ? random + 5 : random + 5 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 5 < dealCanMiss.length ? random + 5 : random + 5 - dealCanMiss.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={amber[300]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 5 < dealCanMiss.length ? random + 5 : random + 5 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {dealCanMiss[random + 5 < dealCanMiss.length ? random + 5 : random + 5 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 5 < dealCanMiss.length ? random + 5 : random + 5 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 2" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 6 < dealCanMiss.length ? random + 6 : random + 6 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 6 < dealCanMiss.length ? random + 6 : random + 6 - dealCanMiss.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={pink[200]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 6 < dealCanMiss.length ? random + 6 : random + 6 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {dealCanMiss[random + 6 < dealCanMiss.length ? random + 6 : random + 6 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 6 < dealCanMiss.length ? random + 6 : random + 6 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
            onClick={() => navigate(`/coupons/${dealCanMiss[random + 7 < dealCanMiss.length ? random + 7 : random + 7 - dealCanMiss.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={dealCanMiss[random + 7 < dealCanMiss.length ? random + 7 : random + 7 - dealCanMiss.length].images[1]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={brown[800]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {dealCanMiss[random + 7 < dealCanMiss.length ? random + 7 : random + 7 - dealCanMiss.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {dealCanMiss[random + 7 < dealCanMiss.length ? random + 7 : random + 7 - dealCanMiss.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {dealCanMiss[random + 7 < dealCanMiss.length ? random + 7 : random + 7 - dealCanMiss.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <Box
      height={100}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
    >
          <Box display={"flex"} flexDirection={"column"}>
          <img src={data[8].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[900]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{data[8].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500} textAlign={"center"}> {data[8].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {data[8].deal}</Button>
          </Box>
          </Box>
      </Box> */}
          {/* <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
    >
          <Box display={"flex"} flexDirection={"column"}>
          <img src={data[9].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={amber.A200} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{data[9].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"center"}> {data[9].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {data[9].deal}</Button>
          </Box>
          </Box>
      </Box> */}
        </Box>
        <Typography
          variant="body2"
          color={"secondary.black"}
          px={1}
          mt={2}
          mb={5}
          display={"flex"}
        >
          {" "}
          Find more{" "}
          <a
            href="#"
            style={{
              textDecoration: "underline",
              fontWeight: 700,
              margin: "0 5px ",
              color: "#000",
            }}
          >
            {" "}
            Deals can't miss{" "}
          </a>{" "}
          at Lokalai
        </Typography>
      </>
    );
  } else {
    <Loading3 />;
  }
};

export default GridDealCanMiss;
