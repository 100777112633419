import { Typography, useTheme, Box } from "@mui/material";
import logo_mobile from "../../asset/lokalai24_mobile.png";
import logo_desk from "../../asset/lokalai24_desk.png";
import "./logo.css";
import { Link } from "react-router-dom";
import { cloneElement, useState } from "react";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import logo_mobile_w from "../../asset/logo_mobile_w.png"
import logo_mobile_b from "../../asset/logo_mobile_b.png"

import logo_desktop_w from "../../asset/logo_desktop_w.png"
import logo_desktop_b from "../../asset/logo_desktop_b.png"

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <Box in={trigger}>
      <Link to="/">
        <div className="logo_logo">
          {!trigger ? (
            <img src={logo_desktop_b} height={30} className="desktop_logo" />
          ) : (
            <img src={logo_desktop_w} height={30} className="desktop_logo" />
          )}

          {!trigger ? (
            <img src={logo_mobile_b} height={30} className="mobile_logo" />
          ) : (
            <img src={logo_mobile_w} height={30} className="mobile_logo" />
          )}
        </div>
      </Link>
    </Box>
  );
}

const LogoSignIn = (props) => {
  return <HideOnScroll {...props} />;
};

export default LogoSignIn;
