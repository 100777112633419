import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useState, useEffect } from "react";
import * as yup from "yup";
import Payment from "./Payment";
import Shipping from "./Shipping";
import styled from "@emotion/styled";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setContactInfo,
} from "../../../../redux/features/cartState";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import OrderingMethod from "./OrderingMethod";
import AvailableTime from "./AvailableTime";
import PaymentMethod from "./PaymentMethod";
import { toast } from "react-toastify";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../api/client/URL";
import { SiTruenas } from "react-icons/si";

const Checkout = ({ operatorId }) => {
  const [operatorInfo, setOperatorInfo] = useState([]);
  const [data, setData] = useState(false);
  // console.log({operatorId})

  useEffect(() => {
    let variable = { operator: operatorId };

    axios
      .post(`${baseURL}api/products/operatorInfo`, variable)
      .then((response) => {
        if (response.data.success) {
          // toast.info("Successfull to get Operator Info");
          setOperatorInfo(response.data.operator);
          // console.log("operatorInfo :", operatorInfo);
          setData(true);
        } else {
          toast.error("Failed to get operator info");
        }
      });
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const cart = useSelector((state) => state.cart.cart);
  // console.log({ cart });

  const userId = useSelector((state) => state.user.user._id);
  console.log({userId})

  const FlexBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const totalPrice = cart.reduce((total, item) => {
    return total + item.count * item.price;
  }, 0);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleFormSubmit = async (value, actions) => {
    await sleep(500);
    setActiveStep(activeStep + 1);

    // console.log(value);
  };

  const handleRedux = (values) => {
    // console.log({ values });
    dispatch(setContactInfo(values));
  };

  const [valueTotal, setValueTotal] = useState([]);

  // console.log({ valueTotal });

  async function makePayment(values) {}

  const [isValidTotal, setIsValidTotal] = useState(true);

  const [beginingCheck, setBeginingCheck] = useState(false);
  // console.log({beginingCheck})

  const validate = () => {
    return valueTotal.availableDate.length &&
    valueTotal.availableTime.length &&
    valueTotal.billingAddress.city.length &&
    valueTotal.billingAddress.country.length &&
    valueTotal.billingAddress.email.length &&
    valueTotal.billingAddress.firstName.length &&
    valueTotal.billingAddress.lastName.length &&
    valueTotal.billingAddress.phoneNumber.length &&
    valueTotal.billingAddress.street1.length &&
    valueTotal.billingAddress.street2.length &&
    valueTotal.orderingMethod.length &&
    valueTotal.paymentMethod.length 
  };

  // console.log({valueTotal})

  useEffect(() => {
    if (beginingCheck) {
      const isValidTotal = validate();
      setIsValidTotal(!isValidTotal);
    }

  });

  //send to backend for CREATE NEW ORDER (PLACE ORDER)

  const placeOrderHandler = () => {

    // console.log("Place order Handerler clicked")
    let variable = { 
    operator: operatorId,
    user: userId,
    customer_info:{
      email: valueTotal.billingAddress.email,
      firstname: valueTotal.billingAddress.phoneNumber,
      phoneNumber: valueTotal.billingAddress.firstName
    },
    orderItems: cart?.map((item)=>{
      return{
        title: item.title,
        qty: item.count,
        image: "",
        price: item.price,
        product: {
          type: item.category,
        },
      }
    }),
    shippingAddress: 
       {
        address: valueTotal.billingAddress.street1,
        city: valueTotal.billingAddress.city,
        postalCode: valueTotal.billingAddress.street2,
        country: valueTotal.billingAddress.contry
      }
    ,
    paymentMethod: valueTotal.paymentMethod,
    itemsPrice: 0,
    shippingPrice: 0,
    taxPrice: 0,
    totalPrice: totalPrice,
  
  
  };

    axios
      .post(`${baseURL}api/orders/`, variable)
      .then((response) => {
        if (response.data.success) {
          toast.info("Successfull to create new Order");
          // setOperatorInfo(response.data.createOrder);
          console.log("Create New ORDER :", response.data.createOrder);
          alert(`Congratuation, ${valueTotal.billingAddress.firstName}. You are successfull to place new Order. Please check your e-mail at ${valueTotal.billingAddress.email}`)
          navigate(`/operator/${operatorId}`)
        } else {
          toast.error("Failed to create a new order");
        }
      });
  };

  // console.log({ isValidTotal });
  if (data) {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: "pointer" }}
        >
          <Link
            to={`/operator/menu/${operatorId}`}
            style={{ textDecoration: "none" }}
          >
            <Avatar
              src={operatorInfo[0].photo}
              sx={{ margin: "0 10px", width: 56, height: 56 }}
            />
          </Link>
        </Box>
        <Box
          width="100%"
          m="0rem auto"
          border={0}
          px={0}
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <Box flex={"1 1 60%"} p={3} bgcolor={"#fff"} boxShadow={2}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema[activeStep]}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Shipping
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />

                  <Divider />

                  <OrderingMethod
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider />

                  <AvailableTime
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider />

                  <PaymentMethod
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider />
                  {/* {console.log(values)} */}

                  {setValueTotal(values)}
                  {values.billingAddress.firstName !== "" && setBeginingCheck(true)}

                  {/* <Box display="flex" justifyContent="space-between" gap="50px">
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{
                      backgroundColor: "lightgray",
                      boxShadow: "none",
                      color: "white",
                      borderRadius: 0,
                      padding: "15px 40px",
                    }}
                    onClick={() => handleRedux(values)}
                  >
                    Redux
                  </Button>
                  {!isFirstStep && (
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      sx={{
                        backgroundColor: "lightgray",
                        boxShadow: "none",
                        color: "white",
                        borderRadius: 0,
                        padding: "15px 40px",
                      }}
                      onClick={() => setActiveStep(activeStep - 1)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      backgroundColor: "lightgray",
                      boxShadow: "none",
                      color: "white",
                      borderRadius: 0,
                      padding: "15px 40px",
                    }}
                  >
                    {!isSecondStep ? "Next" : "Place Order"}
                  </Button>
                </Box> */}
                </form>
              )}
            </Formik>
          </Box>
          {/* CART SECTION */}
          <Box flex={"1 1 40%"} bgcolor={"#fff"} boxShadow={2}>
            <Box p={3} overflow="auto">
              {/* HEADER */}
              <FlexBox border={0}>
                <FlexBox width={"20%"} height={40}>
                  <Typography variant="h6">Qty</Typography>
                  <Typography variant="h6">Item</Typography>
                </FlexBox>
                <FlexBox>
                  <Typography variant="h6">Price</Typography>
                </FlexBox>
              </FlexBox>
              <Divider />

              {/* CART LIST */}
              <Box my={2}>
                {cart.length === 0 ? (
                  <Typography variant="h4" textAlign={"center"}>
                    Cart is Empty
                  </Typography>
                ) : undefined}
                {cart.map((item) => (
                  <Box key={`${item.title}-${item._id}`} border={0}>
                    <FlexBox px={1} border={0} py={1}>
                      <Box flex="1 1 100%" border={0}>
                        <FlexBox mt="0px" border={0} alignItems={"start"}>
                          <Box display="flex" alignItems="start" gap={1}>
                            <Typography color={"primary.contrastText"}>
                              {item.count}
                            </Typography>
                            <Typography color={"primary.contrastText"}>
                              x
                            </Typography>
                            <Typography
                              color={"primary.contrastText"}
                              fontSize={"small"}
                            >
                              {item.title}
                            </Typography>
                          </Box>
                          <FlexBox border={0} justifyContent={"end"} pl={1}>
                            <Typography
                              fontWeight="bold"
                              color={"primary.contrastText"}
                            >
                              ${item.price * item.count}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                dispatch(removeFromCart({ _id: item._id }))
                              }
                              color={"gray"}
                              sx={{ border: 0 }}
                            >
                              <CloseIcon color={"gray"} fontSize={"small"} />
                            </IconButton>
                          </FlexBox>
                        </FlexBox>
                      </Box>
                    </FlexBox>
                  </Box>
                ))}
              </Box>
              <Divider />
              {/* ACTIONS */}
              <Box m={0}>
                <FlexBox mx={2} border={0} my={1}>
                  <Typography color={"gray"} fontSize={"small"}>
                    Sub-Total
                  </Typography>
                  <Typography color={"gray"} fontSize={"small"}>
                    ${Math.round(totalPrice * 100) / 100}
                  </Typography>
                </FlexBox>
                <FlexBox mx={2} border={0} my={1}>
                  <Typography color={"gray"} fontSize={"small"}>
                    GST (15% included)
                  </Typography>
                  <Typography color={"gray"} fontSize={"small"}>
                    ${Math.round(((totalPrice * 3) / 23) * 10) / 10}
                  </Typography>
                </FlexBox>
                <FlexBox mx={2} border={0} my={1}>
                  <Typography
                    color={"primary.contrastText"}
                    fontSize={"medium"}
                    fontWeight={700}
                  >
                    TOTAL
                  </Typography>
                  <Typography
                    color={"primary.contrastText"}
                    fontSize={"medium"}
                    fontWeight={700}
                  >
                    ${Math.round(totalPrice * 10) / 10}
                  </Typography>
                </FlexBox>
              </Box>
            </Box>
          </Box>
        </Box>


        {/* REPLACE AN ORDER */}
        <FlexBox
          border={0}
          bgcolor={"#fff"}
          boxShadow={2}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"start"}
            p={3}
            flex={"1 1 60%"}
          >
            <Typography fontSize={"small"} ml={{ xs: 0, sm: 3 }}>
              By placing this order you accept the:
            </Typography>
            <Typography fontSize={"small"} ml={{ xs: 0, sm: 3 }}>
              - Data Processing Policy - End User License Agreement
            </Typography>
            <Typography fontSize={"small"} ml={{ xs: 0, sm: 3 }}>
              - Restaurant Terms - Privacy Policy
            </Typography>
          </Box>
          <FlexBox
            flex={{ xs: "1 1 100%", sm: "1 1 40%" }}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              borderRadius: "5px",
            }}
          >
            <Box px={3} borderRight={1}>
              <Typography color={"white"}>TOTAL</Typography>
              <Typography color={"white"} fontWeight={"bold"}>
                ${totalPrice}
              </Typography>
            </Box>

            {/* Button enable when all info are filled and cart is not empty */}

             <Button
                disabled={isValidTotal}
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  borderRadius: 0,
                  // minWidth: "100%",
                  padding: "0px 40px",
                  m: "20px 0",
                  fontSize: "1.3rem",
                }}
                onClick={placeOrderHandler}

                
              >
                Place Order
              </Button>
      
          </FlexBox>
        </FlexBox>
      </>
    );
  } else {
    return(
      <div>Loading</div>
    )
    
  }
};

const initialValues = {
  billingAddress: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    zipCode: "",
  },
  shippingAddress: {
    isSameAddress: true,
    firstName: "",
    lastName: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    zipCode: "",
  },
  orderingMethod: "",
  availableDate: "",
  availableTime: "",
  paymentMethod: "",
};
const checkoutSchema = [
  yup.object().shape({
    billingAddress: yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      email: yup.string().required("required"),
      phoneNumber: yup.string().required("required"),
      country: yup.string().required("required"),
      street1: yup.string().required("required"),
      street2: yup.string(),
      city: yup.string().required("required"),
      zipCode: yup.string().required("required"),
    }),
    shippingAddress: yup.object().shape({
      isSameAddress: yup.boolean(),
      firstName: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      lastName: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      country: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      street1: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      street2: yup.string(),
      city: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      state: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      zipCode: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
    }),
  }),
  yup.object().shape({
    orderingMethod: yup.string().required("required"),
    availableDate: yup.string().required("required"),
    availableTime: yup.string().required("required"),
    paymentMethod: yup.string().required("required"),
  }),
];
export default Checkout;
