import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography, Button } from "@mui/material";
import {
  deepPurple,
  red,
  blue,
  blueGrey,
  purple,
  lightBlue,
  pink,
  green,
  amber,
} from "@mui/material/colors";
import uiConfigs from "../../../configs/ui.configs";
import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import { useNavigate } from "react-router-dom";

const GridMako = ({ data }) => {
  console.log(data);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [makoRecommend, setMakoRecommend] = useState([]);

  const [dataSuccess, setDataSuccess] = useState(false);
  // console.log({ makoRecommend });

  const navigate = useNavigate();
  useEffect(() => {
    const mako = {
      _id: data,
    };
    Axios.post(`${baseURL}api/coupons/getCouponItems`, mako).then(
      (response) => {
        if (response.data.success) {
          toast.success("get coupon Mako recommend item");
          setMakoRecommend(response.data.couponItems);
          setDataSuccess(true);
        } else {
          alert("Failed to get coupon item");
        }
      }
    );
  }, []);


  var min = 0;
  var max = 6;
  var random = Math.round(min + Math.random() * (max - min));

  console.log({random})

  if (dataSuccess) {
    return (
      <>
        <Box
          mt={0}
          display="grid"
          gap="0px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"

        >
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 0 < makoRecommend.length ? random + 0 : random + 0 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 0 < makoRecommend.length ? random + 0 : random + 0 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={deepPurple[500]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 0 < makoRecommend.length ? random + 0 : random + 0 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {makoRecommend[random + 0 < makoRecommend.length ? random + 0 : random + 0 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 0 < makoRecommend.length ? random + 0 : random + 0 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 1 < makoRecommend.length ? random + 1 : random + 1 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 1 < makoRecommend.length ? random + 1 : random + 1 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={lightBlue[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 1 < makoRecommend.length ? random + 1 : random + 1 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {makoRecommend[random + 1 < makoRecommend.length ? random + 1 : random + 1 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 1 < makoRecommend.length ? random + 1 : random + 1 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 2 < makoRecommend.length ? random + 2 : random + 2 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 2 < makoRecommend.length ? random + 2 : random + 2 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={purple[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 2 < makoRecommend.length ? random + 2 : random + 2 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {makoRecommend[random + 2 < makoRecommend.length ? random + 2 : random + 2 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 2 < makoRecommend.length ? random + 2 : random + 2 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 3 < makoRecommend.length ? random + 3 : random + 3 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 3 < makoRecommend.length ? random + 3 : random + 3 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={red[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 3 < makoRecommend.length ? random + 3 : random + 3 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                >
                  {" "}
                  {makoRecommend[random + 3 < makoRecommend.length ? random + 3 : random + 3 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 3 < makoRecommend.length ? random + 3 : random + 3 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 4 < makoRecommend.length ? random + 4 : random + 4 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 4 < makoRecommend.length ? random + 4 : random + 4 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={blueGrey[300]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 4 < makoRecommend.length ? random + 4 : random + 4 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 4 < makoRecommend.length ? random + 4 : random + 4 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 4 < makoRecommend.length ? random + 4 : random + 4 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={100}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 5 < makoRecommend.length ? random + 5 : random + 5 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 5 < makoRecommend.length ? random + 5 : random + 5 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={blue[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 5 < makoRecommend.length ? random + 5 : random + 5 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 5 < makoRecommend.length ? random + 5 : random + 5 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 5 < makoRecommend.length ? random + 5 : random + 5 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 6 < makoRecommend.length ? random + 6 : random + 6 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 6 < makoRecommend.length ? random + 6 : random + 6 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={pink[200]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 6 < makoRecommend.length ? random + 6 : random + 6 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 6 < makoRecommend.length ? random + 6 : random + 6 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 6 < makoRecommend.length ? random + 6 : random + 6 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 7 < makoRecommend.length ? random + 7 : random + 7 - makoRecommend.length]._id}`)}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 7 < makoRecommend.length ? random + 7 : random + 7 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={green[800]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 7 < makoRecommend.length ? random + 7 : random + 7 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 7 < makoRecommend.length ? random + 7 : random + 7 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 7 < makoRecommend.length ? random + 7 : random + 7 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={100}
            sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 8 < makoRecommend.length ? random + 8 : random + 8 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={purple[900]}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.white"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 8 < makoRecommend.length ? random + 8 : random + 8 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.white"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 8 < makoRecommend.length ? random + 8 : random + 8 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.white", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 8 < makoRecommend.length ? random + 8 : random + 8 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            height={400}
            sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
            onClick={() => navigate(`/coupons/${makoRecommend[random + 9 < makoRecommend.length ? random + 9 : random + 9 - makoRecommend.length]._id}`)}

          >
            <Box display={"flex"} flexDirection={"column"}>
              <img
                src={makoRecommend[random + 9 < makoRecommend.length ? random + 9 : random + 9 - makoRecommend.length].images[0]}
                height={220}
                style={{ objectFit: "cover", filter: "brightness(80%)" }}
              />
              <Box
                bgcolor={amber.A200}
                height={180}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"start"}
                flexDirection={"column"}
              >
                <Typography
                  color={"secondary.black"}
                  p={0}
                  sx={{ textDecoration: "underline", mt: 1 }}
                >
                  {makoRecommend[random + 9 < makoRecommend.length ? random + 9 : random + 9 - makoRecommend.length].category}
                </Typography>
                <Typography
                  variant="h6"
                  color={"secondary.black"}
                  p={1}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  {" "}
                  {makoRecommend[random + 9 < makoRecommend.length ? random + 9 : random + 9 - makoRecommend.length].title}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: "secondary.black", border: "1px solid gray" }}
                >
                  {" "}
                  {makoRecommend[random + 9 < makoRecommend.length ? random + 9 : random + 9 - makoRecommend.length].deal}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography
          variant="body2"
          color={"secondary.black"}
          px={1}
          mt={2}
          mb={5}
          display={"flex"}
        >
          {" "}
          Find more{" "}
          <a
            href="#"
            style={{
              textDecoration: "underline",
              fontWeight: 700,
              margin: "0 5px ",
              color: "#000",
            }}
          >
            {" "}
            Deals recommended{" "}
          </a>{" "}
          at Lokalai
        </Typography>
      </>
    );
  } else <Loading3 />;
};

export default GridMako;
