import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MediaItem from "../components/common/MediaItem";
import Container from "../components/common/Container";
import uiConfigs from "../configs/ui.configs";
import favoriteApi from "../api/modules/favorite.api";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import { removeFavorite } from "../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Operator = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <Box sx={{ ...uiConfigs.style.maxContent, zIndex:999 }}>
          <Box
                display={{ xs: "none", md: "flex" }}
                sx={{
                  height: "40px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                mt="10rem"
              >
                <small style={{ textTransform: "uppercase" }}>
                  <strong>{user.displayName}</strong>
                </small>
                <small>{user.username}</small>
              </Box>
    </Box>
  )
}

export default Operator