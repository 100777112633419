import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import tmdbConfigs from "../api/configs/tmdb.configs";
import reviewApi from "../api/modules/review.api";
import Container from "../components/common/Container";
import uiConfigs from "../configs/ui.configs";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { routesGen } from "../routes/routes";
import { useSelector } from "react-redux";
import {useNavigate } from "react-router-dom"
import QRcode from "qrcode";
import ImageHeaderOperators from "../components/common/ImageHeaderOperators";

const QRcodePage = ({socketId}) => {
  // console.log("QRcodepage :",socketId)
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [srcUser, setSrcUser] = useState("");
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate ();

  useEffect(() => {
    socketId.emit("addUser", user.id);
    socketId.on("getNavigate", (data) => {
      // console.log("getNavigate", data)
      navigate(data.type)
    });

  }, []);

  // console.log({ user });

  const dispatch = useDispatch();

  const skip = 2;

  useEffect(() => {
    const getReviews = async () => {
      dispatch(setGlobalLoading(true));
      const { response, err } = await reviewApi.getList();
      dispatch(setGlobalLoading(false));

      if (err) toast.error(err.message);
      if (response) {
        setCount(response.length);
        setReviews([...response]);
        setFilteredReviews([...response].splice(0, skip));
      }
    };

    getReviews();
  }, []);

  const onLoadMore = () => {
    setFilteredReviews([
      ...filteredReviews,
      ...[...reviews].splice(page * skip, skip),
    ]);
    setPage(page + 1);
  };

  const onRemoved = (id) => {
    console.log({ reviews });
    const newReviews = [...reviews].filter((e) => e.id !== id);
    console.log({ newReviews });
    setReviews(newReviews);
    setFilteredReviews([...newReviews].splice(0, page * skip));
    setCount(count - 1);
  };

  const { displayName, username, id } = user;
  useEffect(() => {
    QRcode.toDataURL(id).then(setSrcUser);
  }, []);
  return (
    <>
    <ImageHeaderOperators imgPath={"/hero/lokalai24-25.jpg"}/>
    <Box sx={{ ...uiConfigs.style.mainContent }}>
      <Container >
        <div className="title">
          <br/>
          <h2>Welcome "{displayName}"</h2>
          <div className="underline"></div>
        </div>
        <Stack spacing={2}>
          <div className="title">
            <Typography sx={{color:"secondary.black"}} variant="h4">Your Unit QR code</Typography>

            <Typography sx={{color:"secondary.black"}} >to receive Stamp Reward in Royalty Program</Typography>
            <Divider
              sx={{
                display: { xs: "block", md: "none" },
              }}
            />
            <Box sx={{ width: { xs: "300px", md: "400px" }, margin: "2rem  auto" }}>
              <img src={srcUser} style={{ margin: "0 auto", minWidth: "300px"}} />
            </Box>
          </div>
        </Stack>
      </Container>
    </Box>
    </>
  );
};

export default QRcodePage;
