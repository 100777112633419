import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { colors } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uiConfigs from "../../configs/ui.configs";
import Button from "@mui/material/Button";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { Box, Chip, Divider, Stack } from "@mui/material";
import BookingHotDeal from "./BookingHotDeal";
import HighlightCastSlide from "./HighlightCastSlide";
import DealCastSlide from "./DealCastSlide";
import { useNavigate } from "react-router-dom";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DealItem({ media }) {
  const { title, img, desc, location, shop, shopImg,images,id, follower, deal, views } = media;
  const navigate = useNavigate();

  // console.log(media)
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: "100%", maxWidth: "390px", margin: "0 auto" }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: colors.grey["300"], fontWeight: "600" }}
            aria-label="recipe"
            src={shopImg}
          />
        }
        title={
          <Typography
            sx={{
              ...uiConfigs.style.typoLines(1, "left"),
              color: "secondary.contrastText",
              fontWeight: "700",
            }}
          >
            {shop}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2" color="primary.contrastText">
            {follower} followers
          </Typography>
        }
      />
      {/* <CardMedia component="img" height="194" image={img} alt="Paella dish" /> */}
      <DealCastSlide casts={media.images}/>

      <CardHeader
        action={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m="8px"
            p="5px"
            sx={{
              backgroundColor: "primary.main",
              inlineSize: "90px",
              color: "primary.contrastText",
              cursor:"pointer"
            }}
            borderRadius="3px"
            onClick={() => navigate(`/missdeal/${id}`)}
          >
            <span style={{ fontSize: "0.6rem" }}>Best deal</span>
            <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {deal}
            </span>
          </Box>
        }
        title={
          <Typography
            sx={{
              ...uiConfigs.style.typoLines(1, "left"),
              color: "secondary.contrastText",
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
        }
        subheader={
          <Button
            variant="text"
            sx={{ width: "max-content", color: "primary.contrastText" }}
            size="small"
            startIcon={<VisibilityTwoToneIcon />}
          >
            {views}
          </Button>
        }
      />
      <Box
        mb="0px"
        // border="1px solid green"
        sx={{
          zIndex: 2,
        }}
      >
        <BookingHotDeal />
      </Box>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <Button variant="text" onClick={handleExpandClick}>
          RESERVE
        </Button>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>About us:</Typography>
          <Typography paragraph>
            Taste your way through our delicious and handcrafted menu, carefully
            curated with your taste buds in mind. We know that you’ll find
            yourself on an exciting culinary journey at Richmond Road cafe, and
            we invite you to come back for more.
          </Typography>

          <Typography paragraph>
            Inspired by travel and a passion for different cultures, Richmond
            Road cafe has a vast menu of culinary delights, utilizing the
            freshest ingredients to bring homemade dishes straight to your
            table. Come dine with us today.
          </Typography>
          <Typography paragraph>
            Richmond Road Cafe is a premier and authentic Cafe that has been
            proudly serving the Auckland area and beyond since 2019 Since then,
            our mission has been to provide high-quality food for all those that
            wish to combine fun and enjoyable ambiance with skillful cooking
            into one extraordinary dining experience. Scroll down to learn more
            about us.
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
