import React, { useEffect, useState } from "react";
import "./detailGripImages.css";
import ImageGallery from "react-image-gallery";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";

const DetailGripImages = ({ images, title }) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  // console.log({title})
  
  const [image3, setImage3]=useState([])
  useEffect(() => {
    let images1 = [];
    images.map((item) => {
      images1.push({
        original: `${item}`,
        thumbnail: `${item}`,
      });
    });
    setImage3(images1)
    // console.log({ images1 });
  }, []);
  // console.log({ image3 });
  const handleOpen = (i) => {
    // setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 9 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 9 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  return (
    <>
      {open && (
        // <div className="slider">
        //   <CloseIcon className="close" onClick={() => setOpen(false)} />
        //   <ImageGallery items={image3} />
        // </div>
        <div className="slider">
          <CloseIcon className="close" onClick={() => setOpen(false)} />
          <ArrowBackIosIcon className="arrow" onClick={() => handleMove("l")} />
          <Box
            className="sliderWrapper"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <img src={images[slideNumber]} alt="" className="sliderImg" />
            <Typography
              variant="h2"
              color="primary.main"
              width={20}
              sx={{
                margin: "1rem",
                fontSize: { xs: "1rem", sm: "3rem" },
                inlineSize: { xs: "20rem", sm: "30rem" },
                textAlign:"center"
              }}
            >
              {title}
            </Typography>
          </Box>
          <ArrowForwardIosIcon
            className="arrow"
            onClick={() => handleMove("r")}
          />
        </div>
      )}
      <div class="wrapper">
        <div class="gallery">
          <div class="gallery__item gallery__item--1">
            <div class="gallery__link">
              <img
                src={images[0]}
                class="gallery__image"
                onClick={() => handleOpen(0)}
              />
              <div class="gallery__overlay">
                <span>LOKALAI</span>
              </div>
            </div>
          </div>
          {/* <div class="gallery__item gallery__item--2">
                <a href="#" class="gallery__link">
                    <img src={images[1]} class="gallery__image" />
                    <div class="gallery__overlay">
                        <span>Roaring Lion</span>
                    </div>
                </a>
            </div> */}
          <div class="gallery__item gallery__item--3">
            <a href="#" class="gallery__link">
              <img
                src={images[1]}
                class="gallery__image"
                onClick={() => handleOpen(1)}
              />
              <div class="gallery__overlay">
                <span>Empower businesses</span>
              </div>
            </a>
          </div>
          <div class="gallery__item gallery__item--4">
            <a href="#" class="gallery__link">
              <img
                src={images[2]}
                class="gallery__image"
                onClick={() => handleOpen(2)}
              />
              <div class="gallery__overlay">
                <span>LOKALAI</span>
              </div>
            </a>
          </div>
          <div class="gallery__item gallery__item--5">
            <a href="#" class="gallery__link">
              <img
                src={images[3]}
                class="gallery__image"
                onClick={() => handleOpen(3)}
              />
              <div class="gallery__overlay">
                <span>Empower businesses</span>
              </div>
            </a>
          </div>
          <div class="gallery__item gallery__item--6">
            <a href="#" class="gallery__link">
              <img
                src={images[4]}
                class="gallery__image"
                onClick={() => handleOpen(4)}
              />
              <div class="gallery__overlay">
                <span>LOKALAI</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailGripImages;
