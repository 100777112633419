import { Grid } from "@mui/material";
import DealItem from "./DealItem";

const DealGrid = ({ medias }) => {
  // console.log({medias})
  return (
    <Grid container spacing={1} sx={{ marginRight: "-8px!important" }}>
      {medias.map((media, index) => (
        <Grid item xs={12} sm={4} md={3} key={index}>
          <DealItem media={media} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DealGrid;