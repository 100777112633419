import { Grid } from "@mui/material";
import CardDesignItem from "./CardDesignItem";

const CardDesignGrid = ({ medias, socket }) => {
  // console.log({medias})
  // console.log({socket})
  return (
    <Grid container spacing={1} sx={{ marginRight: "-8px!important" }}>
      {medias.map((media, index) => (
        <Grid item xs={12} sm={4} md={3} key={index}>
          <CardDesignItem media={media} socket={socket}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardDesignGrid;