import React from "react";
import "./marqueetop.css";
import logo_icon from '../../../asset/favicon_b.png'

const Icon = () =>{
  return(
    <img src={logo_icon} height={25} style={{margin:"0 10px"}}/>
  )
}

const Marqueetop = () => {
  return (
    <div class="marquee">
      <div class="marquee__inner">
        <p class="marquee__line">christchurch <Icon /> wellington <Icon />  auckland <Icon />  melbourne <Icon />  sydney <Icon />  brisbane <Icon />  perth <Icon />  toronto <Icon />  vancover <Icon />  saskatoon</p>
        <p class="marquee__line">christchurch <Icon /> wellington <Icon />  auckland <Icon />  melbourne <Icon />  sydney <Icon />  brisbane <Icon />  perth <Icon />  toronto <Icon />  vancover <Icon />  saskatoon</p>



      </div>
    </div>
  );
};

export default Marqueetop;
