import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { TopBar } from "./data/datahero";
import { Pagination, Navigation } from "swiper";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Autoplay } from "swiper/modules";

//lightbutton
import "./light_button.css";

const HeroSlide = ({ mediaType, mediaCategory }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [movies, setMovies] = useState(TopBar);

  // console.log({movies})
  const [genres, setGenres] = useState([]);

  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "transparent",
    textAlign: "center",
  }));

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          position: "relative",
          color: "primary.contrastText",
          "&::before": {
            content: '""',
            width: "100%",
            height: "20%",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 2,
            pointerEvents: "none",
            // ...uiConfigs.style.gradientBgImage[theme.palette.mode],
          },
        }}
      >
        <Swiper
          navigation={false}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={2000}
          modules={[Autoplay]}
          onPause={true}
          slidesPerView={1}
          // style={{ width: "100%", height: "70vh" }}
          className="swiper_hero"
          direction={"vertical"}
          loop={true}
        >
          {movies.map((movie, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  paddingTop: {
                    xs: "140%",
                    sm: "180%",
                    md: "120%",
                    lg: "70%",
                  },
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: `url("${movie.img}")`,
                  filter: "brightness(50%)", //darker image by FILTER
                  objectFit: "cover",
                }}
              />
              {/* <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Box
                  // paddingTop={{ xs: "170px", md: "220px" }}
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingX: "1.5rem",
                    color: "text.main",
                    width: { sm: "unset", md: "100%", lg: "100%" },
                  }}
                >
                  <Stack
                    spacing={1}
                    direction="column"
                    displajustifyContent="center"
                  >
                    <Typography
                      variant="h4"
                      fontSize={{ xs: "1rem", md: "1rem", lg: "1.5rem" }}
                      fontWeight="700"
                      color={{
                        xs: "primary.contrastText",
                        md: "primary.contrastText",
                      }}
                      sx={{
                        ...uiConfigs.style.typoLines(3, "center"),
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                      }}
                    >
                      {movie.title}
                    </Typography>
                    <Typography
                      fontSize={{ xs: "0.8rem", md: "0.8rem", lg: "1rem" }}
                      color={{
                        xs: "primary.contrastText",
                        md: "primary.contrastText",
                      }}
                      sx={{
                        ...uiConfigs.style.typoLines(1, "center"),
                        letterSpacing: "1px",
                      }}
                    >
                      {movie.desc}
                    </Typography>
                  </Stack>
                </Box>
              </Box> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box
        zIndex={10}
        position={"absolute"}
        top={{ xs: "30%", sm: "18%" }}
        left={0}
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
      >
        <div class="title-wrapper">
          <h1 class="sweet-title">
            <span data-text="Best">Best</span>
            <span data-text="Deals">Deals</span>
          </h1>
          <span class="top-title">Get me all the</span>
        </div>
        <Item>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="heroneon" onClick={() => navigate(`/whaton`)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Explore what's on
            </div>
            {/* <Button
              variant="contained"
              onClick={() => navigate(`/discover`)}
              sx={{
                bgcolor: "secondary.white",
                color: "primary.black",
                padding: "15px 25px",
                m: 1,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "secondary.white",
                  boxShadow: "1px",
                },
              }}
            >
              Discover
            </Button> */}
          </Box>
        </Item>
      </Box>
    </Box>
  );
};

export default HeroSlide;
