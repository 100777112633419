import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import { Box, Button, Stack, Typography } from "@mui/material";
import uiConfigs from "../configs/ui.configs";
import ImageHeader from "../components/common/ImageHeader";
import Container from "../components/common/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import OperatorGrid from "../components/common/operator/OperatorGrid";
import DealGrid from "../components/common/DealsGrid";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";

const FollowingPage = () => {
  window.scrollTo(0, 0);
  const { user } = useSelector((state) => state.user);
  // console.log({ user });
  const [products, setProducts] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [onRequest, setOnRequest] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  let variable = { userFrom: user.id };
  let userInforVariable = { userId: user.id };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(true));

    axios
      .post(`${baseURL}api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });
    //GET PRODUCTS OF FOLLOWERS
    axios
      .post(`${baseURL}api/products/getSubscriptionProducts`, variable)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.products);
          setProducts(response.data.products);
        } else {
          alert("Failed to get subscription products");
        }
      });

    //GET LIST FOLLOWERS
    axios
      .post(`${baseURL}api/products/getList`, variable)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.operators);
          setFollowers(response.data.operators);
        } else {
          alert("Failed to get list of followers ");
        }
      });

      dispatch(setGlobalLoading(false));

  }, []);

  return (
    <>
      <ImageHeader imgPath={userInfo.photo} />
      <Box marginTop="-4rem" sx={{ ...uiConfigs.style.mainContent }}>
        <Stack spacing={1}>
          <Avatar
            src={userInfo.photo}
            sx={{ margin: "10px auto", width: 56, height: 56 }}
          />
          <Item>
            <Typography variant="h4" color="primary.main" fontWeight={700}>
              {userInfo.displayName}
            </Typography>
          </Item>
          <Item>
            <p>Address:{userInfo.email}</p>
            <p>Email: {userInfo.email}</p>
            <p>Phone: {userInfo.phone}</p>
            <p>Introduction: {userInfo.bio}</p>
          </Item>
        </Stack>
        <Container header="List Business followed">
          <Stack>
            <Item>
              <OperatorGrid medias={followers} />
            </Item>
          </Stack>
        </Container>
        <Container header="List Products followed">
          <DealGrid medias={products} />
        </Container>
      </Box>
    </>
  );
};

export default FollowingPage;
