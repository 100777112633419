import { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import mediaApi from "../../../api/modules/media.api";
import { toast } from "react-toastify";
import OperatorItem2 from "./OperatorItem2";
import CityAutoSwiper from "../CityAutoSwiper";
import { newzealand } from "../../../data/datatext";

import { baseURL } from "../../../api/client/URL";
import axios from "axios";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import uiConfigs from "../../../configs/ui.configs";
import "./operator.css";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  deepPurple,
  red,
  blue,
  blueGrey,
  purple,
  lightBlue,
  pink,
  green,
  amber,
  brown,
  orange,
  grey,
  deepOrange,
} from "@mui/material/colors";

const OperatorSlide = () => {
  const [medias, setMedias] = useState(newzealand);

  //FETCH LIST OF OPERATORS
  const [operators, setOperators] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  console.log({ operators });
  useEffect(() => {
    const fetchOperator = async () => {
      const getOperatorsUrl = `${baseURL}api/operator/listofoperators`;
      // console.log(getproducturl)
      const { data } = await axios.get(getOperatorsUrl);
      setOperators(data);
      console.log("List of Operators :", { data });
    };
    fetchOperator();
  }, []);
  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "transparent",
    textAlign: "center",
  }));
  return (
    <Box>
      <Item>
        <Typography
          variant="h5"
          color={"secondary.black"}
          sx={{ fontWeight: 700 }}
          mb={3}
          mx={3}
        >
          Lokalai is proud home to high rated Operators:
        </Typography>
      </Item>
      {/* Grid operators */}

      <Box
        mt={0}
        display="grid"
        gap="0px"
        gridTemplateColumns={isNonMobile ? "repeat(4, minmax(0, 1fr))" : "repeat(2, minmax(0, 1fr))"}
        border={0}
      >
        {operators.map((operator, index) => {
          if (index > 14) {
            return (
              <Link
                to={`/operator/${operator._id}`}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <Box
                  sx={{ gridColumn: !isNonMobile ? "span 4"  : "span 1" }}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  border={0}
                >
                  <img src={operator.photo} className="img_logo" />
                  {isNonMobile && <Typography width={150} color={"primary.black"} textTransform={"uppercase"}>{operator.name}</Typography>}
                  {!isNonMobile && <Typography width={70} color={"primary.black"} textTransform={"uppercase"} sx={{fontSize: "12px"}}>{operator.name}</Typography>}

                </Box>
              </Link>
            );
          }
        })}
      </Box>
    </Box>
    // <CityAutoSwiper>
    //   {Operators.map((operator, index) => (
    //     <SwiperSlide key={index}>
    //       <OperatorItem2 media={operator}  />
    //     </SwiperSlide>
    //   ))}
    // </CityAutoSwiper>
  );
};

export default OperatorSlide;
