import React from "react";
import { useEffect, useState } from "react";

import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { RiUserStarLine } from "react-icons/ri";
import { BiBookReader } from "react-icons/bi";
import { MdOutlineMedicalServices } from "react-icons/md";
import { BsHeadphones } from "react-icons/bs";
import { Badge, Box } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import uiConfigs from "../../../configs/ui.configs";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { setIsCartOpen } from "../../../redux/features/cartState";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";

const Nav = () => {
  const [value, setValue] = React.useState();
  const dispatch = useDispatch();

  const operator = useSelector((state) => state.cart.contactInfo);
  // console.log({operator})

  const cart = useSelector((state) => state.cart.cart);

  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [activeNav, setActiveNav] = useState("#");
  return (
    <Box display={{ md: "none" }} zIndex={11}>
      {/* <nav> */}
      {showNav && (
        <Box
          className="backgroudNAV"
          sx={{
            width: "100vw",
            height: "5rem",
            zIndex: 1,
            position: "fixed",
            left: "50%",
            bottom: "0rem",
            transform: "translateX(-50%)",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              // border: "1px solid red",
              backgroundColor: "transparent",
              maxWidth: "max-content",
              padding: "0rem 2rem",
              zIndex: 5,
              position: "fixed",
              left: "50%",
              bottom: "0rem",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "1.5rem",
            }}
          >
            <Tab
              icon={<CottageOutlinedIcon fontSize="medium" />}
              label="Home"
              style={{
                fontSize: "0.5rem",
                minWidth: "17%",
                color: "primary.black",
              }}
              LinkComponent={Link}
              to={"/"}
            />
            <Tab
              icon={<FavoriteBorderOutlinedIcon fontSize="medium" />}
              label="Like"
              style={{
                fontSize: "0.5rem",
                minWidth: "17%",
                color: "primary.black",
              }}
              LinkComponent={Link}
              to={"/following"}
            />
            {operator.length === 0 ? (
              <Tab
                icon={<GridViewOutlinedIcon fontSize="medium" />}
                label="Category"
                style={{ fontSize: "0.5rem", minWidth: "17%" }}
                LinkComponent={Link}
                to={"/favorites"}
              />
            ) : (
              <Tab
                icon={
                  <Avatar
                    src={operator[0].photo}
                    sx={{ width: 60, height: 60, border: "2px solid white" }}
                  />
                }
                label=""
                style={{ fontSize: "0.5rem", minWidth: "17%" }}
                LinkComponent={Link}
              />
            )}

            <Tab
              icon={
                <Badge
                  badgeContent={cart.length}
                  showZero
                  color="secondary"
                >
                  <ShoppingCartOutlinedIcon fontSize="medium" />
                </Badge>
              }
              label="Cart"
              style={{
                fontSize: "0.5rem",
                minWidth: "17%",
                color: "primary.black",
              }}
              onClick={() => dispatch(setIsCartOpen({}))}
              to={"/favorites"}
            />

            <Tab
              icon={<QuestionAnswerOutlinedIcon fontSize="medium" />}
              label="Message"
              style={{
                fontSize: "0.5rem",
                minWidth: "17%",
                color: "primary.black",
              }}
              LinkComponent={Link}
              to={"/userInbox"}
            />
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

export default Nav;
