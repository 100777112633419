import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip, Icon } from "antd";
import Axios from "axios";
import { FaBeer, FaHandPeace, FaRegHandPeace } from "react-icons/fa";
import { format } from "timeago.js";
import { baseURL } from "../../../api/client/URL";

function View(props) {
  // console.log("view props", props);
  const { user } = useSelector((state) => state.user);
  // console.log("view user", user);

  const [views, setViews] = useState(0);



  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  let viewVariablegetViewNumber = {
    userTo: props.userTo,
    // userFrom: user.id,
  };



  const [viewAction, setViewAction] = useState(false);

  useEffect(() => {
    Axios.post(`${baseURL}api/view/getViews`, viewVariablegetViewNumber).then((response) => {
      if (response.data.success) {
        //How many views does this video or comment have
        // console.log("getViews: ", response.data.viewNumber);
        setViews(response.data.viewNumber);
      } else {
        alert("Failed to get Views");
      }
    });
    // increase View Count if it has not viewed

    if (!viewAction && user) {
      //when we are already subscribed
      
      let viewVariable = {
        userTo: props.userTo,
        userFrom: user.id,
      };

      // console.log({ viewVariable });

      Axios.post(`${baseURL}api/view/upView`, viewVariable).then((response) => {
        if (response.data.success) {
          setViewAction(true);
        } else {
          alert("Failed to increase views");
        }
      });
    } else {
      // when we are not subscribed yet

      console.log("Hi ...from Dr. Man ha", { viewAction });
    }
  }, []);

  const onView = () => {};

  return (
    <span>
      <Tooltip title="View" onClick={onView}>
        <span style={{ cursor: "auto" }}>{nFormatter(views +1 , 1)}</span>
        <span style={{ paddingRight: "0.5rem" }}> views</span>
      </Tooltip>
      <span>{format(props.date)}</span>
    </span>
  );
}

export default View;
