import React, { useState } from "react";
import { IconButton, Box, Typography, useTheme, Button } from "@mui/material";
import { shades } from "../../../theme";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// import img1 from './images/item-1.jpeg'

const Menu = ({ items, category }) => {
  // console.log({ items });
  // console.log({ category });
  const [isHovered, setIsHovered] = useState(false);
  const [count, setCount] = useState(1);
  return (
    <Box m="0 auto">
      <h1 style={{ fontSize: "2rem" }}>{category}</h1>
      <Box
        margin="0 auto"
        display="grid"
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
        justifyContent="space-around"
        rowGap="0px"
        columnGap="30px"
      >
        
        <img src={require(`${items[0].img}`)} className="photo" />
        {items.map((item) => {
          const { id, title, img, desc, price } = item;
          return (
            <Box
              display="grid"
              // gridTemplateColumns="1fr 2fr"
              borderBottom="0.5px dotted hsl(210, 22%, 49%)"
              alignItems="center"
              key={id}
            >
              <Box
                position="relative"
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              >
                {/* <img
                  alt={item.title}
                  // src={img}
                  // src={`http://localhost:3000${img}`}
                  src={require(`${img}`)}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    overflow: "hidden",
                    float: "left",
                    objectFit: "cover",
                    width: "100px",
                    height: "100px"
                  }}
                /> */}
                {/* <Box
                  display={isHovered ? "block" : "none"}
                  position="absolute"
                  bottom="10%"
                  left="0"
                  width="100%"
                  padding="0 5%"
                >
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      display="flex"
                      alignItems="center"
                      backgroundColor={shades.neutral[100]}
                      borderRadius="3px"
                    >
                      <IconButton
                        onClick={() => setCount(Math.max(count - 1, 1))}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography color={shades.primary[300]}>
                        {count}
                      </Typography>
                      <IconButton onClick={() => setCount(count + 1)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Button
                      // onClick={() => {
                      //   dispatch(addToCart({ item: { ...item, count } }));
                      // }}
                      sx={{
                        backgroundColor: shades.primary[300],
                        color: "white",
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box> */}
              </Box>

              <Box
                ml="5px"
                position="relative"
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              >
                <header
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    alignItems: "center",
                  }}
                >
                  <Typography color="black">
                    <h4>{title}</h4>
                  </Typography>
                  <Typography fontWeight="bold" color="#c59d5f">
                    ${price}
                  </Typography>
                </header>
                <Typography
                  className="limitedtext" //index.css global css
                  fontSize="0.8rem"
                  paddingTop="0.5rem"
                  color="hsl(210, 22%, 49%)"
                  mb="1.25rem"
                >
                  {desc}
                </Typography>
                <Box
                  display={isHovered ? "block" : "none"}
                  position="absolute"
                  bottom="10%"
                  left="0"
                  width="100%"
                  padding="0 5%"
                >
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      display="flex"
                      alignItems="center"
                      backgroundColor={shades.neutral[100]}
                      borderRadius="3px"
                    >
                      <IconButton
                        onClick={() => setCount(Math.max(count - 1, 1))}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography color={shades.primary[300]}>
                        {count}
                      </Typography>
                      <IconButton onClick={() => setCount(count + 1)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Button
                      // onClick={() => {
                      //   dispatch(addToCart({ item: { ...item, count } }));
                      // }}
                      sx={{
                        backgroundColor: shades.primary[300],
                        color: "white",
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
        
      </Box>
    </Box>
  );
};

export default Menu;
