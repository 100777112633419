import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { Icon } from 'antd';
import Axios from 'axios';
import Colors from '../../../constant/Colors';
import {base_url} from "../../utils/baseUrl"

import { MdAddToPhotos } from 'react-icons/md';



function FileUploadS3(props) {

    const [Images, setImages] = useState([])

    const onDrop = (files) => {

        let formData = new FormData();
        const config = {
            header: { 'content-type': 'multipart/form-data' }
        }
        formData.append("file", files[0])
        //save the Image we chose inside the Node Server 
        Axios.post(`${base_url}product/uploadImageS3`, formData, config)
        // // console.log(formData)
        // // console.log(config)
        // console.log({formData})
        // console.log("file",files[0])
            .then(response => {
                
                if (response.data.success) {

                    setImages([...Images, response.data.location])
                    props.refreshFunction([...Images, response.data.location])
                    alert('Successfull uploaded pictures')
                    console.log(response.data.location)
                    console.log(Images)

                } else {
                    alert('Failed to save the Image in Server')
                }
            })
    }


    const onDelete = (image) => {
        const currentIndex = Images.indexOf(image);

        let newImages = [...Images]
        newImages.splice(currentIndex, 1)

        setImages(newImages)
        props.refreshFunction(newImages)
    }

    return (
        <div style={{ display: 'flex',  alignContent: 'flex-end', position:'relative' }}>
                <div style={{ display: 'flex', width: '900px', height: '240px', overflowX: 'scroll', border : '1px solid lightgray', }}>

                    {Images.map((image, index) => (
                        <div onClick={() => onDelete(image)}>
                            <img style={{ minWidth: '300px', width: '300px', height: '240px' }} src={image} alt={`productImg-${index}`} />
                        </div>
                    ))}
            </div>
            <Dropzone
                onDrop={onDrop}
                multiple={false}
                maxSize={800000000}
            >
                {({ getRootProps, getInputProps }) => (
                    <div style={{
                        width: '150px', height: '40px', border: '1px solid lightgray',
                        borderRadius: '10px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        background: Colors.light.yellow,
                        position: 'absolute',
                        left: '15%',
                        top: '10%',
                    }}
                        {...getRootProps()}
                    >
                        {/* {console.log('getRootProps', { ...getRootProps() })}
                        {console.log('getInputProps', { ...getInputProps() })} */}
                        <input {...getInputProps()} />
                        {/* <Icon type="plus" style={{ fontSize: '20px' }} /> */}
                        <MdAddToPhotos style={{ fontSize: '20px' }}/>  Add photos
                    </div>
                )}
            </Dropzone>



        </div>
    )
}

export default FileUploadS3
