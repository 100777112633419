import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import tmdbConfigs from "../api/configs/tmdb.configs";
import reviewApi from "../api/modules/review.api";
import Container from "../components/common/Container";
import uiConfigs from "../configs/ui.configs";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { routesGen } from "../routes/routes";
import { useSelector } from "react-redux";
import QRcode from "qrcode";
import logo from "../asset/lokalai5.png";

const QRLokalai = () => {
  const [srcUser, setSrcUser] = useState("");

  const [srcBusiness, setSrcBusiness] = useState("");
  useEffect(() => {
    QRcode.toDataURL("https://lokalai.com/").then(setSrcUser);
    QRcode.toDataURL("https://operator.lokalai.com/").then(
      setSrcBusiness
    );
  }, []);
  return (
    <Box sx={{ ...uiConfigs.style.mainContent }}>
      <Container>
        <div className="title" style={{marginTop:"2rem"}}>
          <h1>FOR USERS</h1>
          <h4>Get The Best Deals From Local Businesses</h4>
          <div className="underline" style={{margin:"1rem auto"}}></div>
          <img src={srcUser} className="QR-section" width={200} />
        </div>
        <div className="title" style={{marginTop:"2rem"}}>
          <h1>FOR BUSINESS</h1>
          <h4>Unclock the power of AI to supercharge Local Business</h4>
          <div className="underline" style={{margin:"1rem auto"}}></div>
          <img src={srcBusiness} className="QR-section" width={200} />
        </div>
      </Container>
    </Box>
  );
};

export default QRLokalai;
