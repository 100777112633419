import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const AvailableTime = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  // console.log({values})
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const [submitted, setSubmitted] = useState(false);
  const handleEdited = async () => {
    setSubmitted(!submitted);
    await sleep(5000);
  };

  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    return values.availableDate.length && values.availableTime.length;
  };

  useEffect(() => {
    const isValid = validate();
    setIsValid(!isValid);
  });

  const options = [
    { time: "5:00 PM", value: "5:00 PM" },
    { time: "5:15 PM", value: "5:15 PM" },
    { time: "5:30 PM", value: "5:30 PM" },
    { time: "5:45 PM", value: "5:45 PM" },
    { time: "6:00 PM", value: "6:00 PM" },
  ];
  return (
    <Box m="30px auto">
      {/* BILLING FORM */}
      <Box>
        <Typography
          sx={{ mb: "15px" }}
          fontSize="18px"
          fontWeight={700}
          textTransform={"uppercase"}
          color={"primary.contrastText"}
          display={"flex"}
        >
          <AccessAlarmIcon sx={{ mr: 1 }} /> AVAILABLE TIME CHOICE
        </Typography>
      </Box>
      {!submitted && (
        <>
          <div role="group" aria-labelledby="my-radio-group">
            <Box display={"flex"} sx={{ flexDirection: "column" }} gap={2} >
              <Box display={"flex"} border={`1px solid lightgray`} borderRadius={"5px"} justifyContent={"space-between"}>
                <CalendarTodayIcon
                  sx={{ bgcolor: "lightgrey", p: 1, m: 0, borderRadius: "5px" }}
                  fontSize="large"
                  color="white"
                />
                <Field
                  name="availableDate"
                  as="select"
                  style={{ border: "none", width: "100%", marginLeft: "10px",marginRight: "10px" }}
                >
                  <option value={"Today"}>Today</option>
                  <option value={"Tomorrow"}>Tomorrow</option>
                </Field>
              </Box>
              <Box display={"flex"} border={`1px solid lightgray`} borderRadius={"5px"}>
                <AccessTimeIcon
                  sx={{ bgcolor: "lightgrey", p: 1, m: 0, borderRadius: "5px" }}
                  fontSize="large"
                  color="white"
                />
                <Field
                  name="availableTime"
                  as="select"
                  style={{ border: "none", width: "100%", marginLeft: "10px", marginRight: "10px"}}
                >
                  <option value={"5:00 PM"}>5:00 PM</option>
                  <option value={"5:15 PM"}>5:15 PM</option>
                  <option value={"5:30 PM"}>5:30 PM</option>
                  <option value={"5:45 PM"}>5:45 PM</option>
                  <option value={"6:00 PM"}>6:00 PM</option>
                  <option value={"6:15 PM"}>6:15 PM</option>
                  <option value={"6:30 PM"}>6:30 PM</option>
                  <option value={"6:45 PM"}>6:45 PM</option>
                  <option value={"7:00 PM"}>7:00 PM</option>
                  <option value={"7:15 PM"}>7:15 PM</option>
                  <option value={"7:30 PM"}>7:30 PM</option>
                  <option value={"7:45 PM"}>7:45 PM</option>
                  <option value={"8:00 PM"}>8:00 PM</option>
                </Field>
              </Box>
            </Box>
          </div>
        </>
      )}
      {submitted && (
        <Box>
          <div>{values.availableDate} : {values.availableTime}</div>
        </Box>
      )}
      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={isValid}
        sx={{
          backgroundColor: "primary.main",
          boxShadow: "none",
          color: "white",
          borderRadius: 0,
          padding: "15px 40px",
          mt: "20px",
        }}
        onClick={handleEdited}
      >
        {submitted ? (
          <Typography display={"flex"} color={"#fff"}>
            <AccessAlarmIcon mr={1} />
            EDIT TIME
          </Typography>
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
};

export default AvailableTime;
