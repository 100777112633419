import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uiConfigs from "../../configs/ui.configs";
import { Box, Button, Chip, Divider, Stack } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import BookingHotDeal from "./BookingHotDeal";
import HighlightCastSlide from "./HighlightCastSlide";
import DealCastSlide from "./DealCastSlide";

import { useNavigate } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HotItem({ itemImg }) {
  // console.log({itemImg})
  const { title, desc, location, deal, views,id } = itemImg;

  const navigate = useNavigate();

  // console.log(title)
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: { xs: "360", sm: "max-content" },margin: "0 auto" }} >
      <CardMedia component="img" height="400" image={itemImg.images[0]} alt="Paella dish" onClick={() => navigate(`/trend/${id}`)}/>
      {/* <DealCastSlide casts={img}/> */}
      <CardHeader
        action={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m="8px"
            p="5px"
            sx={{
              backgroundColor: "primary.main",
              inlineSize: "90px",
              color: "primary.contrastText",
              cursor:"pointer"
            }}
            borderRadius="3px"
            onClick={() => navigate(`/trend/${id}`)}
          >
            <span style={{ fontSize: "0.6rem" }}>Best deal</span>
            <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {deal}
            </span>
          </Box>
        }
        title={
          <Typography
            sx={{
              ...uiConfigs.style.typoLines(1, "left"),
              color: "secondary.contrastText",
              fontWeight: "700"
            }}
          >
            {title}
          </Typography>
        }
        subheader={
          <Button
            variant="text"
            sx={{ width: "max-content", color: "primary.contrastText" }}
            size="small"
            startIcon={<VisibilityTwoToneIcon />}
            
          >
            {views}
          </Button>
        }
      />
      <Box
        mb="0px"
        // border="1px solid green"
        sx={{
          zIndex: 2,
        }}
      >
        <BookingHotDeal />
      </Box>
    </Card>
  );
}
