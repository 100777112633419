import React, {useEffect, useState} from 'react'
import { Box, Typography, Button } from "@mui/material";
import {deepPurple, red, blue, blueGrey, purple, lightBlue, pink, green, amber, brown, orange, grey } from '@mui/material/colors';
import useMediaQuery from "@mui/material/useMediaQuery";

import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import { useNavigate } from "react-router-dom";


const GridTrending = ({data}) => {
    // console.log({data})
const isNonMobile = useMediaQuery("(min-width:600px)");
const navigate = useNavigate();


const [trending, setTrending] = useState([]);
const [dataSuccess, setDataSuccess] = useState(false)
// console.log({ trending });
useEffect(() => {

const trending = {
  _id: data,
};
Axios.post(`${baseURL}api/coupons/getCouponItems`, trending).then(
  (response) => {
    if (response.data.success) {
      toast.success("get Trending coupon item");
      setTrending(response.data.couponItems);
      setDataSuccess(true);
    } else {
      alert("Failed to get coupon item");
    }
  }
);

},[])

var min = 0;
var max = 5;
var random = Math.round(min + Math.random() * (max - min));

console.log({random})

if (dataSuccess) {
  return (
    <>
    <Box
    mt={0}
    display="grid"
    gap="0px"
    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
  >
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
      onClick={() => navigate(`/coupons/${trending[random + 0 < trending.length ? random + 0 : random + 0 - trending.length]._id}`)}
    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 0 < trending.length ? random + 0 : random + 0 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[900]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 0 < trending.length ? random + 0 : random + 0 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500}> {trending[random + 0 < trending.length ? random + 0 : random + 0 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {trending[random + 0 < trending.length ? random + 0 : random + 0 - trending.length].deal}</Button>
          </Box>
      </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
      onClick={() => navigate(`/coupons/${trending[random + 1 < trending.length ? random + 1 : random + 1 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 1 < trending.length ? random + 1 : random + 1 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={blueGrey[100]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 1 < trending.length ? random + 1 : random + 1 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500}> {trending[random + 1 < trending.length ? random + 1 : random + 1 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {trending[random + 1 < trending.length ? random + 1 : random + 1 - trending.length].deal}</Button>
          </Box>
      </Box>
  </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
      onClick={() => navigate(`/coupons/${trending[random + 2 < trending.length ? random + 2 : random + 2 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 2 < trending.length ? random + 2 : random + 2 - trending.length].images[2]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[700]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 2 < trending.length ? random + 2 : random + 2 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500}> {trending[random + 2 < trending.length ? random + 2 : random + 2 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {trending[random + 2 < trending.length ? random + 2 : random + 2 - trending.length].deal}</Button>
          </Box>
      </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1"  }}
      onClick={() => navigate(`/coupons/${trending[random + 3 < trending.length ? random + 3 : random + 3 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 3 < trending.length ? random + 3 : random + 3 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={blueGrey[200]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 3 < trending.length ? random + 3 : random + 3 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500}> {trending[random + 3 < trending.length ? random + 3 : random + 3 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {trending[random + 3 < trending.length ? random + 3 : random + 3 - trending.length].deal}</Button>
          </Box>
      </Box>
    </Box>
    <Box
      height={400}      
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
      onClick={() => navigate(`/coupons/${trending[random + 4 < trending.length ? random + 4 : random + 4 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 4 < trending.length ? random + 4 : random + 4 - trending.length].images[1]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[800]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 4 < trending.length ? random + 4 : random + 4 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500} textAlign={"center"}> {trending[random + 4 < trending.length ? random + 4 : random + 4 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {trending[random + 4 < trending.length ? random + 4 : random + 4 - trending.length].deal}</Button>
          </Box>
      </Box>
    </Box>
    <Box
      height={100}
       sx={{ gridColumn: !isNonMobile ? "span 2" : "span 2" }}
       onClick={() => navigate(`/coupons/${trending[random + 5 < trending.length ? random + 5 : random + 5 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 5 < trending.length ? random + 5 : random + 5 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={blueGrey[300]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 5 < trending.length ? random + 5 : random + 5 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"center"}> {trending[random + 5 < trending.length ? random + 5 : random + 5 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {trending[random + 5 < trending.length ? random + 5 : random + 5 - trending.length].deal}</Button>
          </Box>
          </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 2" }}
      onClick={() => navigate(`/coupons/${trending[random + 6 < trending.length ? random + 6 : random + 6 - trending.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 6 < trending.length ? random + 6 : random + 6 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[300]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 6 < trending.length ? random + 6 : random + 6 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"center"}> {trending[random + 6 < trending.length ? random + 6 : random + 6 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {trending[random + 6 < trending.length ? random + 6 : random + 6 - trending.length].deal}</Button>
          </Box>
          </Box>
  </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
      onClick={() => navigate(`/coupons/${trending[random + 7 < trending.length ? random + 7 : random + 7 - trending.length]._id}`)}

    >
          <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 7 < trending.length ? random + 7 : random + 7 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[800]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 7 < trending.length ? random + 7 : random + 7 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500} textAlign={"center"}> {trending[random + 7 < trending.length ? random + 7 : random + 7 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {trending[random + 7 < trending.length ? random + 7 : random + 7 - trending.length].deal}</Button>
          </Box>
          </Box>
  </Box>
    <Box
      height={100}
      sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
      onClick={() => navigate(`/coupons/${trending[random + 8 < trending.length ? random + 8 : random + 8 - trending.length]._id}`)}

    >
          <Box display={"flex"} flexDirection={"column"}>
          <img src={trending[random + 8 < trending.length ? random + 8 : random + 8 - trending.length].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[900]} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.white"} p={0} sx={{textDecoration:"underline", mt:1}}>{trending[random + 8 < trending.length ? random + 8 : random + 8 - trending.length].category}</Typography>
              <Typography variant="h6" color={"secondary.white"} p={1} fontWeight={500} textAlign={"center"}> {trending[random + 8 < trending.length ? random + 8 : random + 8 - trending.length].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.white", border:"1px solid gray"}}> {trending[random + 8 < trending.length ? random + 8 : random + 8 - trending.length].deal}</Button>
          </Box>
          </Box>
      </Box>
    {/* <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 4" : "span 2" }}
    >
          <Box display={"flex"} flexDirection={"column"}>
          <img src={data[9].images[0]} height={220} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={amber.A200} height={180} display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"}>
              <Typography color={"secondary.black"} p={0} sx={{textDecoration:"underline", mt:1}}>{data[9].category}</Typography>
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"center"}> {data[9].title}</Typography>
              <Button  variant="outlined" sx={{color:"secondary.black", border:"1px solid gray"}}> {data[9].deal}</Button>
          </Box>
          </Box>
      </Box> */}

    </Box>
        <Typography variant="body2" color={"secondary.black"} px={1} mt={2} mb={5} display={"flex"} > Find more <a href="#" style={{textDecoration: "underline", fontWeight: 700, margin:"0 5px ", color:"#000"}}>{" "} Deals trending {" "}</a> at Lokalai</Typography>
  </>
  )
} else {
  <Loading3 />
}

  
}

export default GridTrending