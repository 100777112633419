import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Button, IconButton } from "@mui/material";

function ScrollToTop() {
  const [backToTopButton, setBackToTopButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="containerScroll">
      {backToTopButton && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: {xs: "100px", sm: "50px"},
            right: {xs: "20px", sm: "50px"},
            backgroundColor: "primary.black",
            // border:"3px solid black",
            color:"primary.main",
            padding: {xs: "0.5rem", sm: "1rem"},
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
            transition: "0.4s ease-in-out",
          }}
          onClick={scrollUp}
        >
          <ArrowUpwardIcon sx={{fontSize:{xs: "1.5rem",md: "2rem"}}}/>
        </IconButton>
      )}
    </div>
  );
}

export default ScrollToTop;
