import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  deepPurple,
  red,
  blue,
  blueGrey,
  purple,
  lightBlue,
  pink,
  green,
  amber,
  brown,
  orange,
  grey,
  deepOrange,
} from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import { useNavigate } from "react-router-dom";

const Now = ({ data }) => {
  const [nowEvents, setNowEvents] = useState(data);
  // console.log({ dealCanMiss });
  const [dataSuccess, setDataSuccess] = useState(true);
  const navigate = useNavigate();

  console.log({ nowEvents });
  const isNonMobile = useMediaQuery("(min-width:600px)");

  var min = 0;
  var max = 9;
  var random = Math.round(min + Math.random() * (max - min));

  if (dataSuccess) {
    return (
      <>
        <Box
          mt={0}
          display="grid"
          gap={{xs: "10px", sm: "20px"}}
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          border={0}
          
        >
          {nowEvents.map((e, index) => (
            <>
              <Box
                height={300}
                sx={{ gridColumn: !isNonMobile ? "span 2" : "span 1" }}
                borderRadius={"10px"}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <img
                    src={e.img}
                    height={200}
                    style={{ objectFit: "cover", filter: "brightness(80%)", width: "100%", overflow: "hidden" }}
                  />
                  <Box
                    bgcolor={grey[400]}
                    height={100}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"start"}
                    flexDirection={"column"}
                  >

                    <Typography
                      variant="h6"
                      color={"secondary.white"}
                      p={1}
                      fontWeight={500}
                      fontSize={{xs:"1.5rem", sm:"2rem"}}
                      mt={-7}
                      zIndex={10}
                      textTransform={"uppercase"}
                    >
                      {" "}
                      {e.title}
                    </Typography>
                    <Typography
                      color={"secondary.black"}
                      p={0}
                      sx={{ textDecoration: "underline", mt: 1 }}
                    >
                      {e.price}
                    </Typography>
                    <Button
                      variant="text"
                      sx={{
                        color: "secondary.black",
                      }}
                    >
                      {" "}
                      {e.address}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </Box>
      </>
    );
  } else {
    <Loading3 />;
  }
};

export default Now;
