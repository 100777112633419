import React, { useState, useEffect} from 'react'
import { Typography, Button, Form, message, Input, Icon } from 'antd';
import FileUploadS3 from './utils/FileUploadS3';


import Axios from 'axios';
// import { USER_SERVER } from '../../Config';
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
// import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";

//-----MAKO form ----
// import {
//     FormGroup,
//     Select,
//     SelectItem,
//     Loading,
//   } from "carbon-components-react";

// import { years, months, costCentres, accounts, price } from "../../utils/utils";

import {base_url} from "../utils/baseUrl"


const { Title } = Typography;
const { TextArea } = Input;
// Geocode.setApiKey("AIzaSyAgiVRPAwUyTPdL6Z1AS2ohtHZr4sxgfbY");
// Geocode.setLanguage("en");
// Geocode.setRegion("es");
// Geocode.setLocationType("ROOFTOP");
// Geocode.enableDebug();

const Continents = [
    { key: 1, value: "Africa" },
    { key: 2, value: "Europe" },
    { key: 3, value: "Asia" },
    { key: 4, value: "North America" },
    { key: 5, value: "South America" },
    { key: 6, value: "Australia" },
    { key: 7, value: "Antarctica" }
]

const Storecategories = [
  {
      _id: "Restaurant",
      value: 1
  },
  {
    _id: "Take away",
    value: 2
  },
  {
    _id: "Cafe",
    value: 3
  },
  {
    _id: "Entertainment",
    value: 4 
  },
  {
    _id: "Shopping",
    value: 5
  },
  {
    _id: "Gift shop",
    value: 6
  },
  {
    _id: "Service",
    value: 7
  },
  {
    _id: "Hair & Beauty",
    value: 8
  },
  {
    _id: "Mobile",
    value: 9
  },
  {
    _id: "Sport",
    value: 10
  },
  {
    _id: "Clothing & Fashion",
    value: 11
  }
]

function UploadImage(props) {

    // const user = useSelector(state => state.user)
    const { user } = useSelector((state) => state.user);
    console.log(user)
    console.log(user.displayName)
    console.log(user.id)
    console.log(user.token)

    const [TitleValue, setTitleValue] = useState("")
    const [DescriptionValue, setDescriptionValue] = useState("")
    const [PriceValue, setPriceValue] = useState(0)
    const [ContinentValue, setContinentValue] = useState(1)
    const [CategoryValue, setCategoryValue] = useState(1)


    const [Images, setImages] = useState([])

    const navigate = useNavigate();


    //----Mako variables --------------------------
    const [year, setYear] = useState(2019);
    const [month, setMonth] = useState("Jan");
    const [costCentre, setCostCentre] = useState("CC101");
    const [account, setAccount] = useState(1000000);
    const [prediction, setPrediction] = useState();
    const [scores, setScores] = useState([]);

    const userId = localStorage.getItem('userId');
    const latituteValue = localStorage.getItem('latitute');
    const longituteValue = localStorage.getItem('longitute');
    // console.log(userId)


    //----Mako variables --------------------------

    //----get Lat and Lng from user props --------------------------



    const onTitleChange = (event) => {
        setTitleValue(event.currentTarget.value)
    }

    const onDescriptionChange = (event) => {
        setDescriptionValue(event.currentTarget.value)
    }

    // const onPriceChange = (event) => {
    //     setPriceValue(event.currentTarget.value)
    // }

  //   const onContinentsSelectChange = (event) => {
  //       setContinentValue(event.currentTarget.value)
  //       // console.log({ContinentValue})
  //   }

  //   const onCategorySelectChange = (event) => {
  //     setCategoryValue(event.currentTarget.value)
  //     // console.log({CategoryValue})
  // }

    const updateImages = (newImages) => {
        console.log('updateImages :',newImages)
        setImages(newImages)
    }

    
    
    const onSubmit = (event) => {
        event.preventDefault();


        // if (!TitleValue || !DescriptionValue || !PriceValue ||
        //     !ContinentValue || !Images || !prediction) {
        //     return alert('fill all the fields first!')
        // }

        const variables = {
            writer: user.id,
            title: TitleValue,
            description: DescriptionValue,
            price: PriceValue,
            images: Images,
            continents: ContinentValue,
            score: parseInt(prediction),
            latitute: latituteValue,
            longitute: longituteValue,
        }

        console.log('Product infor :', variables)
        Axios.post(`${base_url}product/uploadProduct`, variables)
            .then(response => {
                if (response.data.success) {
                    alert('Dr. Man Ha Product Successfully Uploaded')
                    // props.history.push('/pictures')
                  navigate("/")
                } else {
                    alert('Failed to upload Product')
                }
            })

    }

    //-----run Mako




    return (

      

        <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <Title level={2}> Upload Photos </Title>
                <p>Your product photos will be uploaded here</p>
            </div>


            <Form onSubmit={onSubmit} >

                <FileUploadS3 refreshFunction={updateImages} />   

                <br />
                <label>Title</label>
                <Input
                    onChange={onTitleChange}
                    value={TitleValue}
                />
                <br />
                <label>Description</label>
                <TextArea
                    onChange={onDescriptionChange}
                    value={DescriptionValue}
                />

                <h3>Interest Score</h3>


                <hr/>
                <Button
                    onClick={onSubmit}
                    type="text"
                    style={{width: 375, background: "yellow",
                      color: "green", }}
                >
                    Submit
                </Button>

            </Form>

        </div>
    )

}

export default UploadImage
