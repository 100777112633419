import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  IconButton,
  easing,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useDispatch, useSelector } from "react-redux";


import Loading3 from "../components/Loading/Loading3";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import { styled } from "@mui/material/styles";

//SUPPORT
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import DealGrid from "../components/common/DealsGrid";
import { useNavigate } from "react-router-dom";
import LikeDislikes from "../components/common/productsupport/LikeDislikes";
import Subscriber from "../components/common/productsupport/Subscriber";
import Quotes from "../components/common/quotes/Quotes";

import Badge from "@mui/material/Badge";
import { grey, deepOrange } from "@mui/material/colors";

import useMediaQuery from "@mui/material/useMediaQuery";

//image list
import StarBorderIcon from "@mui/icons-material/StarBorder";

import PropTypes from "prop-types";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ImageHeaderOperators from "../components/common/ImageHeaderOperators";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { setAuthModalOpen } from "../redux/features/authModalSlice";
import { setContactInfo } from "../redux/features/cartState";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OperatorDetail() {

  const theme = useTheme();
  const { operatorId } = useParams();
  const [coupons, setCoupons] = useState([]);
  const [products, setProducts] = useState([]);
  const [operatorInfo, setOperatorInfo] = useState([]);
  const [data, setData] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const { user } = useSelector((state) => state.user);
  // console.log({ user });
  // console.log({ operatorInfo });
  // console.log({ coupons });

  useEffect(() => {
    let variable = { operator: operatorId };

    axios
      .post(`${baseURL}api/products/operatorInfo`, variable)
      .then((response) => {
        if (response.data.success) {
          // toast.info("Successfull to get Operator Info");
          setOperatorInfo(response.data.operator);
          // console.log("operatorInfo :", operatorInfo);
          setData(true);
          dispatch(setContactInfo(response.data.operator))
        } else {
          toast.error("Failed to get operator info");
        }
      });

    axios
      .post(`${baseURL}api/coupons/getCouponOfOperator`, variable)
      .then((response) => {
        if (response.data.success) {
          // toast.success("Successfull to get coupons of Operator");
          setCoupons(response.data.coupons);
          // console.log("product:", products[0].operator)
        } else {
          toast.error("Failed to get operator products");
        }
      });

    axios
      .post(`${baseURL}api/products/getOperatorProduct`, variable)
      .then((response) => {
        if (response.data.success) {
          // toast.success("Successfull to get coupons of Operator");
          setProducts(response.data.products);
          // console.log("product:", products[0].operator)
        } else {
          toast.error("Failed to get operator products");
        }
      });
  }, []);

  const handleMessageSubmit = () => {
    toast.success("send message clicked");
    const groupTitle = operatorInfo[0].name + " & " + user.displayName;
    const userId = user.id;
    const sellerId = operatorInfo[0]._id;
    console.log({ groupTitle });
    console.log({ userId });
    console.log({ sellerId });

    axios
      .post(`${baseURL}api/chat/create-new-conversation`, {
        groupTitle,
        userId,
        sellerId,
      })
      .then((response) => {
        toast.success("received responce from server");
        // navigate(`/conversation/${response.data.conversation._id}`);
        navigate(`/userInbox`);

        // if (response.data.success) {
        //   // toast.success("Successfull to get Products of Operator");
        //   setProducts(response.data.products);
        //   // console.log("product:", products[0].operator)
        // } else {
        //   toast.error("Failed to get operator products");
        // }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const {
    images,
    title,
    desc,
    location,
    open_hours,
    videos,
    operator,
    logo,
    operatorname,
    updatedAt,
    _id,
  } = products;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (data) {
    return (
      <>
        <ImageHeaderOperators
          imgPath={operatorInfo[0].coverPhoto}
          height={""}
        />
        <Box sx={{ ...uiConfigs.style.mainContent }} border={0}>
          <Box
            display="flex"
            flexWrap="wrap"
            columnGap="00px"
            my="30px"
            sx={{ borderTop: 0, borderColor: "divider" }}
          >
            <Box
              flex="1 1 30%"
              mb="0px"
              border={0}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                onClick={() => navigate(`/operator/${operator}`)}
                sx={{ cursor: "pointer" }}
                border={0}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={operatorInfo[0].photo}
                    sx={{
                      width: { xs: 80, sm: 150 },
                      height: { xs: 80, sm: 150 },
                      border: "2px solid #fff",
                    }}
                  />
                </StyledBadge>
              </Box>
            </Box>
            <Box flex="1 1 65%" mb="0px" border={0}>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={"start"}
              >
                <Typography
                  variant="h6"
                  color="secondary.black"
                  fontWeight={{ xs: 500, sm: 700 }}
                  border={0}
                  mr={3}
                  lineHeight={2}
                  // sx={{ ...uiConfigs.style.typoLines(2, "left") }}
                  sx={{ border: 0, textAlign: "left", display: "inline-block" }}
                >
                  {/* {operatorInfo[0].address} */}
                  Paralox Resort
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      mr: 1,
                      height: "max-content",
                      width: "max-content",
                      bgcolor: `${grey[400]}`,
                      color: "primary.black",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: `${grey[600]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    Follow
                  </Button>
                  <Button
                    onClick={handleMessageSubmit}
                    variant="contained"
                    sx={{
                      mr: 1,
                      height: "max-content",
                      width: "max-content",
                      bgcolor: `${grey[300]}`,
                      color: "primary.black",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: `${grey[500]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    Message
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      mr: 0,
                      px: 0,
                      height: "max-content",
                      width: "max-content",
                      color: "primary.black",
                      borderRadius: "5px",
                      bgcolor: `${grey[300]}`,
                      "&:hover": {
                        backgroundColor: `${grey[500]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    {isNonMobile && "Share"}
                    {!isNonMobile && <IosShareOutlinedIcon />}
                  </Button>
                </Box>
              </Box>
              {isNonMobile && (
                <Box
                  mt={2}
                  display={"flex"}
                  justifyContent={"start"}
                  maxWidth={500}
                  columnGap={2}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      {coupons.length}
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>best deals</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      30
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>followers</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      15
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>loyalty</Typography>
                  </Box>
                </Box>
              )}
              {isNonMobile && (
                <Box m={2}>
                  <Typography
                    variant="body1"
                    color={"primary.black"}
                    lineHeight={2}
                    sx={{
                      ...uiConfigs.style.typoLines(2, "left"),
                      display: "inline-block",
                    }}
                  >
                    {operatorInfo[0].bio}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={"primary.black"}
                    lineHeight={2}
                  >
                    E-mail: {operatorInfo[0].email}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={"primary.black"}
                    lineHeight={2}
                  >
                    Contact: {operatorInfo[0].phone}
                  </Typography>
                </Box>
              )}
            </Box>
            {!isNonMobile && (
              <Box m={2}>
                <Typography
                  variant="body1"
                  color={"primary.black"}
                  lineHeight={1.5}
                  sx={{ ...uiConfigs.style.typoLines(2, "left") }}
                  mx={2}
                >
                  {operatorInfo[0].bio}
                </Typography>
                <Typography
                  variant="body1"
                  color={"primary.black"}
                  lineHeight={1.5}
                >
                  E-mail: {operatorInfo[0].email}
                </Typography>
                <Typography
                  variant="body1"
                  color={"primary.black"}
                  lineHeight={1.5}
                >
                  Contact: {operatorInfo[0].phone}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ ...uiConfigs.style.mainContent }}>
          <Box display="flex" flexWrap="wrap" columnGap="40px" border={0}>
            {/* ACTIONS */}
            <Box flex="1 1 70%" mb="40px">
              <Box sx={{ width: "100%", mt: 0 }}>
                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    TabIndicatorProps={{
                      sx: { backgroundColor: "secondary.black", top: 0 },
                    }}
                    sx={{
                      margin: "auto",
                      "& button": { borderRadius: "5px" },
                      "& button:hover": {
                        backgroundColor: "primary.white",
                        fontWeight: 700,
                      },
                      "& button:active": {
                        color: "secondary.black",
                        fontWeight: 900,
                      },
                      "& button:focus": {
                        color: "secondary.black",
                        fontWeight: 700,
                      },
                      "& button.Mui-selected": {
                        color: "secondary.black",
                      },
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { display: "none" },
                      },
                    }}
                  >
                    <Tab
                      label={<RestaurantMenuIcon />}
                      {...a11yProps(0)}
                      sx={{ mx: 2 }}
                    />
                    <Tab label={<LoyaltyIcon />} sx={{ mx: 2 }} />
                    <Tab label={<BookOnlineIcon />} sx={{ mx: 2 }} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Box border={0} px={0}>
                    <Typography
                      color={"secondary.black"}
                      mb={2}
                      display={"block"}
                      sx={{
                        ...uiConfigs.style.typoLines(4),
                        textAlign: "justify",
                      }}
                    >
                      Inspired by Loklai, {operatorInfo[0].name} food menu is
                      light, sunny and made for snacking, sharing or savouring
                      solo. Fresh, seasonal and sustainable, the menu showcases
                      suppliers and provenance and seafood is the star.
                    </Typography>
                    <Typography color={"secondary.black"}>
                      View the full dynamic menu{" "}
                      <Link
                        to={`/operator/menu/${operatorId}`}
                        style={{ textDecoration: "underline" }}
                      >
                        here
                      </Link>
                    </Typography>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box border={0} px={0}>
                    <Typography
                      color={"secondary.black"}
                      mb={2}
                      display={"block"}
                      sx={{
                        ...uiConfigs.style.typoLines(4),
                        textAlign: "justify",
                      }}
                    >
                      {operatorInfo[0].name} provide Loyalty program to rewards
                      their loyalty customers.
                    </Typography>
                    <Typography color={"secondary.black"}>
                      Join the loyalty program{" "}
                      {user ? (
                        <Subscriber
                          userTo={operator}
                          operatorname={operatorname}
                        />
                      ) : (
                        <Button
                          onClick={() => dispatch(setAuthModalOpen(true))}
                          variant="contained"
                          sx={{
                            mr: 1,
                            height: "max-content",
                            width: "max-content",
                            bgcolor: `${grey[300]}`,
                            color: "primary.black",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor: `${grey[500]}`,
                              boxShadow: "none",
                            },
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </Typography>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Box border={0} px={0}>
                    <Typography
                      color={"secondary.black"}
                      mb={2}
                      display={"block"}
                      sx={{
                        ...uiConfigs.style.typoLines(4),
                        textAlign: "justify",
                      }}
                    >
                      Find your table for any occasion
                    </Typography>
                    <Typography color={"secondary.black"}>
                      Reserve a table{" "}
                      {user ? (
                        <Button
                          variant="contained"
                          onClick={()=>navigate(`/booking/${operatorId}`)}
                          sx={{
                            mr: 1,
                            height: "max-content",
                            width: "max-content",
                            bgcolor: `${grey[300]}`,
                            color: "primary.black",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor: `${grey[500]}`,
                              boxShadow: "none",
                            },
                          }}
                        >
                          Reserve
                        </Button>
                      ) : (
                        <Button
                          onClick={() => dispatch(setAuthModalOpen(true))}
                          variant="contained"
                          sx={{
                            mr: 1,
                            height: "max-content",
                            width: "max-content",
                            bgcolor: `${grey[300]}`,
                            color: "primary.black",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor: `${grey[500]}`,
                              boxShadow: "none",
                            },
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </Typography>
                  </Box>
                </CustomTabPanel>
              </Box>
              <Divider />
              {!isNonMobile && (
                <Box p={2} display={"flex"} justifyContent={"space-between"}>
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={"center"}
                    width={100}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      {coupons.length}
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>best deals</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={"center"}
                    width={100}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      30
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>followers</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={"center"}
                    width={100}
                  >
                    <Typography fontWeight={500} color={"primary.black"}>
                      15
                    </Typography>{" "}
                    <Typography color={`${grey[500]}`}>loyalty</Typography>
                  </Box>
                </Box>
              )}
              <Item>                
                <Box
                  mt={0}
                  display="grid"
                  gap={{ xs: "1px", sm: "5px" }}
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                  border={0}
                >
                  {coupons.map((coupon, index) => (
                    <Box
                      height={{ xs: 150, sm: 300 }}
                      sx={{ gridColumn: !isNonMobile ? "span 1" : "span 1" }}
                      borderRadius={"0px"}
                      onClick={() => navigate(`/coupons/${coupon._id}`)}
                    >
                      <Box position={"relative"}>
                        <img
                          src={coupon.images[0]}
                          className="imageCouponOperator"
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "25%",
                            pointerEvents: "none",
                            background:
                            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                          }}
                        />

                        <Typography
                          sx={{
                            textDecoration: "none",
                            position: "absolute",
                            top: "5px",
                            left: "10px",
                            color: "secondary.white",
                            display: "flex"
                          }}
                        >
                          <StarBorderIcon /> {coupon.deal}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Item>
            </Box>

            {/* BOOKING CARD */}
            <Box
              flex="1 1 25%"
              mb="40px"
              // border="1px solid green"
              sx={{
                marginTop: { xs: "0rem", lg: "0rem" },
                zIndex: 2,
              }}
            >
              <Quotes />
            </Box>
          </Box>
          <DealGrid medias={products} />
        </Box>
      </>
    );
  } else {
    return (
      <div>
        <Loading3 />
      </div>
    );
  }
}

export default OperatorDetail;
