import React, { useState } from "react";
import HeroSlide from "../components/common/HeroSlide";
import tmdbConfigs from "../api/configs/tmdb.configs";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import MediaSlide from "../components/common/MediaSlide";
import LandingPage from "./LandingPage/LandingPage";
import MediaGrid from "../components/common/MediaGrid";
import {
  endingdata,
  cannotmissdata,
  bestdeal,
  trendingdata,
} from "../components/common/data/datahero";
import { Box, Button, Stack, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import TextList from "./TextList";
import CitySlide from "../components/common/CitySlide";
import DealGrid from "../components/common/DealsGrid";
import EndingSlide from "../components/common/EndingSlide";
import HotBackdropSlide from "../components/common/HotBackdropSlide";
// import BestDealSlide from "../components/common/BestDealSlide";
import RoyalSlide from "../components/common/RoyalSlide";
import OperatorSlide from "../components/common/operator/OperatorSlide";
import Marqueetop from "../components/utils/marquee/marqueetop";
import Highlight from "../components/common/highlights/Highlight";
import MainQuotes from "../components/utils/quotes/MainQuotes";

const HomePage = () => {
  const category = ["top attraction", "top rated", "popular", "MaKo selects"];
  const { mediaType } = "Top Rated";
  const [currCategory, setCurrCategory] = useState(0);
  const [medias, setMedias] = useState([]);
  const [currPage, setCurrPage] = useState(1);

  const onCategoryChange = (categoryIndex) => {
    if (currCategory === categoryIndex) return;
    setMedias([]);
    setCurrPage(1);
    setCurrCategory(categoryIndex);
  };

  return (
    <>
      <HeroSlide
        mediaType={tmdbConfigs.mediaType.movie}
        mediaCategory={tmdbConfigs.mediaCategory.popular}
      />

      <Box marginTop="0rem" sx={{ ...uiConfigs.style.mainContent }}>
        <Container header="Highlights">
          <Highlight />
          {/* <RoyalSlide /> */}
        </Container>
        {/* LIST OF BUSINESS STORE */}
        {/* <Container header="List of Business Resigtered">
          <OperatorSlide />
        </Container> */}
        {/* <Container header="Best deals for you">
          <BestDealSlide bestdeal={bestdeal} />
        </Container>  */}
        {/* <Container header="Trending now"> */}
        {/* <MediaSlide
            mediaType={tmdbConfigs.mediaType.movie}
            mediaCategory={tmdbConfigs.mediaCategory.popular}
          /> */}
        {/* <HotBackdropSlide backdrops={trendingdata} />
        </Container> */}

        {/* Global Mira slide include backend 


        <Container header="Global mira">
          <LandingPage />
        </Container>  */}
        {/* <Container header="Awe-deals around New Zealand">
          <CitySlide
            mediaType={tmdbConfigs.mediaType.movie}
            mediaCategory={tmdbConfigs.mediaCategory.top_rated}
          />
        </Container>
        <Container header="Deal you can't miss">
          <br />
        </Container>

        <DealGrid medias={cannotmissdata} mediaType={cannotmissdata} />

        <Container header="...ending soon">
          <EndingSlide medias={endingdata} />
        </Container> */}
        {/* <br />
        <TextList
          medias={tmdbConfigs.mediaType.movie}
          mediaType={tmdbConfigs.mediaCategory.top_rated}
        /> */}
      </Box>
      <Marqueetop />
      <MainQuotes />
      <Box marginTop="0rem" sx={{ ...uiConfigs.style.mainContent }}>
        <Container header="Top Ten">
          <OperatorSlide />
        </Container>
      </Box>
    </>
  );
};

export default HomePage;
