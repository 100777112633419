import * as React from "react";
import { useEffect, useState, useRef } from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Stack } from "@mui/system";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Chip, Divider } from "@mui/material";
import LocalCafeTwoToneIcon from "@mui/icons-material/LocalCafeTwoTone";
import LunchDiningTwoToneIcon from "@mui/icons-material/LunchDiningTwoTone";
import RestaurantMenuTwoToneIcon from "@mui/icons-material/RestaurantMenuTwoTone";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./quotes.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Quotes() {
  const [expanded, setExpanded] = React.useState(false);

  const [expanded1, setExpanded1] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const [value, setValue] = useState("breakfast");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var min = 1;
  var max = 21;
  var random = Math.round(min + Math.random() * (max - min));

  // console.log({ random });

  // random = 21;

  return (
    <Box
      sx={{
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
      border={0}
    >
      {random === 1 && (
        <div class="quote-wrapper">
          <blockquote class="text">
            <p>The power of AI suppercharge local business</p>
            <footer>Dr. M. Ha - founder</footer>
          </blockquote>
        </div>
      )}

      {random === 2 && (
        <div className="div1">
          <h2>
            Take risk, if you win you will be happy. If you lose, you will be
            wise
          </h2>
          {/* <p>
            lorem ipsum dolor sit, amet consectetur adipisicing elit. Ullam
            eveniet dicta laborum ut necessitatibus. Pariatur, illum.
            Consequatur porro nemo voluptatum similique nam, perspiciatis velit
            accusamus exercitationem optio necessitatibus libero odio?
          </p> */}
          <h4 align="right">-Dr. M. Ha</h4>
        </div>
      )}

      {random === 3 && (
        <blockquote class="sidekick">
          The only thing permanent in life is impermanence.{" "}
          <cite> Thor in Endgame</cite>
        </blockquote>
      )}

      {random === 4 && (
        <div class="ct1">
          <div class="corner1 left_top"></div>
          <div class="corner1 left_bottom"></div>
          <div class="corner1 right_top"></div>
          <div class="corner1 right_bottom"></div>
          <span class="ct1_span">Dr. M. Ha</span>
          <blockquote className="b2">
            <p style={{ color: "black" }}>
              <i>
                &ldquo;A bird sitting on a tree is never afraid of the branch
                breaking, because its trust is not on the branch but on its own
                wings.&rdquo;{" "}
              </i>
            </p>
          </blockquote>
        </div>
      )}

      {random === 5 && (
        <blockquote>
          <p>
            If people aren't laughing at your dreams, your dreams aren't big
            enough !
          </p>
          <footer>
            <cite>
              <a>Dr. M. Ha</a>
            </cite>
          </footer>
          <div className="div2"></div>
        </blockquote>
      )}

      {random === 6 && (
        <div class="blockquote-wrapper">
          <div class="blockquote2">
            <h4 className="block_h2">
              I walk <span style={{ color: "#000" }}>slowly</span> but never{" "}
              <span style={{ color: "#000" }}> backwards</span>
            </h4>
            <h4 className="block_h4">
              {/* &mdash; */}
              Abraham Lincoln
              {/* <br />
              <em>Web Site Usability: A Designer's Guide</em> */}
            </h4>
          </div>
        </div>
      )}

      {random === 7 && (
        <article class="container">
          <blockquote className="b1">
            <strong>Imagination</strong> is <em>more important</em> than{" "}
            <strong>knowledge</strong>
          </blockquote>
          <b>Einstein, A.</b>
        </article>
      )}

      {random === 8 && (
        <>
          <blockquote class="otro-blockquote">
            If you can write the problem down clearly, then the matter is half
            solved
            <span>Kidlin's law</span>
          </blockquote>
        </>
      )}

      {random === 9 && (
        <blockquote class="q-card q-card-color-1">
          <div class="content">
            If you put information and intelligence first at all time then the
            money keeps coming in{" "}
          </div>
          <div class="author">Walson's Law</div>
        </blockquote>
      )}

      {random === 10 && (
        <div class="blockquote-wrapper10">
          <div class="blockquote10">
            <h1 class="h1_title">Start before you are ready</h1>
            <h4 class="h4_title">Steven Pressfield</h4>
          </div>
        </div>
      )}

      {random === 11 && (
        <div class="container11">
          <h1 class="content11">Silence is the ultimate weapon of power</h1>
          <cite class="cite11">Charles de Gaulle</cite>
        </div>
      )}

      {random === 12 && (
        <div class="perspective-text">
          <div class="perspective-line">
            <p className="p12"></p>
            <p className="p12">There is</p>
          </div>
          <div class="perspective-line">
            <p className="p12">There is</p>
            <p className="p12">Beauty</p>
          </div>
          <div class="perspective-line">
            <p className="p12">Beauty</p>
            <p className="p12">in</p>
          </div>
          <div class="perspective-line">
            <p className="p12">in</p>
            <p className="p12">Simplicity</p>
          </div>
          <div class="perspective-line">
            <p className="p12">Simplicity</p>
            <p className="p12"></p>
          </div>
        </div>
      )}

      {random === 13 && (
        <>
          <section class="section top-section">
            <div class="content-container content-theme-dark">
              <div class="content-inner">
                <div class="content-center">
                  <h1>LOKALAI</h1>
                  <p>Empower local businesses</p>
                </div>
              </div>
            </div>
          </section>
          <section class="section bottom-section">
            <div class="content-container content-theme-light">
              <div class="content-inner">
                <div class="content-center">
                  <h1>LOKALAI</h1>
                  <p>Empower local businesses</p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {random === 14 && (
        <div id="wrapper_14">
          <div id="container_14">
            <h1 className="h1_14">
              If you cannot do great things, do small things in a great way
            </h1>
            <p>
              <small>Napoleon Hill</small>
            </p>
          </div>
        </div>
      )}

      {random === 15 && (
        <div>
          <span className="span_15">
            {" "}
            The Future belongs to those who believe in the beauty of their
            dreams
          </span>
          <p style={{ color: "#000" }}>Eleanor Roosevelt</p>
        </div>
      )}

      {random === 16 && (
        <div>
          <h1 className="h1_16">
            <span className="span_16">Who</span>
            <span className="span_16">ever</span>
            <span className="span_16"> </span>
            <span className="span_16">is</span>
            <span className="span_16">happy</span>
            <span className="span_16"></span>
            <span className="span_16"> </span>
            <span className="span_16">Will</span>
            <span className="span_16">make</span>
            <span className="span_16"> </span>
            <span className="span_16"></span>
            <span className="span_16">others</span>
            <span className="span_16"> </span>
            <span className="span_16">Happy</span>
            <span className="span_16">too</span>
            <span className="span_16">!</span>
          </h1>
          <p style={{ color: "#000" }}>Anne Frank</p>
        </div>
      )}

      {random === 17 && (
        <div>
          <h1 className="h1_17">
            It always
            <br />
            <span class="underlined underline-clip"> seems</span>
            <br />
            <span class="underlined underline-mask">impossile</span>
            <br />
            until it's <br />
            <span class="underlined underline-overflow"> done</span>
          </h1>
        </div>
      )}

      {random === 18 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // border: "1px solid green",
          }}
        >
          <div class="text_18">
            <div class="wrapper_18">
              <div id="L" class="letter">
                L
              </div>
              <div class="shadow">L</div>
            </div>
            <div class="wrapper_18">
              <div id="0" class="letter">
                0
              </div>
              <div class="shadow">0</div>
            </div>
            <div class="wrapper_18">
              <div id="K" class="letter">
                K
              </div>
              <div class="shadow">K</div>
            </div>
            <div class="wrapper_18">
              <div id="A" class="letter">
                A
              </div>
              <div class="shadow">A</div>
            </div>
            <div class="wrapper_18">
              <div id="L" class="letter">
                L
              </div>
              <div class="shadow">L</div>
            </div>
            <div class="wrapper_18">
              <div id="A" class="letter">
                A
              </div>
              <div class="shadow">A</div>
            </div>
            <div class="wrapper_18">
              <div id="I" class="letter">
                I
              </div>
              <div class="shadow">I</div>
            </div>
          </div>
        </div>
      )}

      {random === 19 && (
        <div className="bg_19">
          <h1 aria-label="Lokalai" className="h1_19"></h1>
        </div>
      )}

      {random === 20 && (
        <div class="container_20">
          <h1 class="heading">LOKALAI</h1>
          <h1 class="heading1">empowered local businesses</h1>
        </div>
      )}

      {random === 21 && (
        <div class="wrapper_21">
          <div class="bg"> lokalai </div>
          <div class="fg"> lokalai </div>
        </div>
      )}

      <List sx={{ mt: 2 }}>
        <ListItem disablePadding>
          <ListItemButton component="a" href="#simple-list">
            <ListItemText
              primary="Covid-safe information"
              sx={{ textDecoration: "underline" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href="#simple-list">
            <ListItemText
              primary="Frequently asked questions"
              sx={{ textDecoration: "underline" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
