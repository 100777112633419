// export const baseURL = "http://localhost:5005/";
export const baseURL = "https://lokalai-be-mar24.vercel.app/";



// export const Socket_URL = "http://localhost:6005/"

export const Socket_URL = "https://socket-lokalai-23jul.onrender.com/"

// export const Client_Google_URL = "http://localhost:8005/"

export const Client_Google_URL = "https://server-auth-client-7b48f2b45c17.herokuapp.com/"




