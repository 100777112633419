import * as React from "react";
import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { colors } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uiConfigs from "../../configs/ui.configs";
import Button from "@mui/material/Button";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { Box, Chip, Divider, Stack } from "@mui/material";
import BookingHotDeal from "./BookingHotDeal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from "../../redux/features/globalLoadingSlice";
import { baseURL } from "../../api/client/URL";
import Stamps from "../../components/common/liveStamp/stamps";
import { toast } from "react-toastify";

import coffee from "../../asset/coffee.PNG";
import favoriteApi from "../../api/modules/favorite.api";

export default function CardDesignItem({ media, socket }) {
  // console.log({socket})
  const { user } = useSelector((state) => state.user);

  const {
    CardColor,
    NoofStamps,
    writer,
    cardDesc1,
    cardDesc2,
    cardDesc3,
    cardDesc4,
    cardDesc5,
    pickunImg,
    StampColor,
    unStampColor,
  } = media;

  const PickImg = coffee;

  // console.log({media})
  const navigate = useNavigate();
  const [operatorInfo, setOperatorInfo] = useState([]);

  // console.log({media})
  // console.log({writer})

  const [medias, setMedias] = useState([]);
  const [filteredMedias, setFilteredMedias] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const skip = 8;
  useEffect(() => {
    const getFavorites = async () => {
      dispatch(setGlobalLoading(true));
      const { response, err } = await favoriteApi.getList();
      dispatch(setGlobalLoading(false));

      if (err) toast.error(err.message);
      if (response) {
        setCount(response.length);
        setMedias([...response]);
        setFilteredMedias([...response].splice(0, skip));
      }
    };

    getFavorites();
  }, []);

  const [stampExist, setstampExist] = useState(0);

  // console.log({user})

  const dispatch = useDispatch();
  let operatorInforVariable = { operator: writer };
  const stampAwardNumberVariables = { userFrom: writer, userTo: user.id };

  // console.log({operatorInforVariable})

  useEffect(() => {
    dispatch(setGlobalLoading(true));

    //GET OPERATOR INFORMATION
    axios
      .post(`${baseURL}api/royalprogram/getOperatorInfo`, operatorInforVariable)
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data.operatorInfo);
          setOperatorInfo(response.data.operatorInfo);
        } else {
          alert("Failed to get Operator Information");
        }
      });

    //GET NUMBER OF STAMPS AWARD
    axios
      .post(`${baseURL}api/stamp/stampAwardNumber`, stampAwardNumberVariables)
      .then((response) => {
        if (response.data.success) {
          // console.log("response.data.stampAwardNumber :",response.data.stampAwardNumber);
          setstampExist(response.data.stampAwardNumber);
        } else {
          alert("Failed to get subscriber Number");
        }
      });

    dispatch(setGlobalLoading(false));
  }, []);

  useEffect(() => {
    socket.emit("addUser", user.id);
    socket.on("getLiveStamp", (data) => {
      // console.log("getLiveStamp", data);
      setstampExist((prevCount) => prevCount + 1);
      // console.log("getLiveStamp :", stampExist)
    });
  }, []);


  useEffect(() => {
    socket.on("getRemoveStamp", (data) => {
      console.log("getRemoveStamp", data);
      setstampExist((prevCount) => prevCount - 1);
      console.log("getRemoveStamp",stampExist)
    });
  }, []);

  return (
    <Card sx={{ width: "100%", maxWidth: "390px", margin: "0 auto" }}>
      <Box
        sx={{
          displpay: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            background: CardColor,
            minHeight: 550,
            borderRadius: "25px",
          }}
        >
          <Stack
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#000",
              borderRadius: "25px 25px 0 0",
            }}
          >
            <Avatar
              src={operatorInfo.photo}
              sx={{ mt: "2rem", width: 56, height: 56 }}
            />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Bruno Ace",
                fontWeight: 500,
                textTransform: "uppercase",
                color: "#fff",
              }}
            >
              The{" "}
              <strong style={{ color: CardColor }}>{operatorInfo.name}</strong>{" "}
              Store
            </Typography>
            <Typography
              variant="p"
              sx={{
                textTransform: "uppercase",
                color: CardColor,
              }}
            >
              {cardDesc1}
            </Typography>
          </Stack>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#000"
              fill-opacity="1"
              d="M0,128L60,144C120,160,240,192,360,181.3C480,171,600,117,720,117.3C840,117,960,171,1080,186.7C1200,203,1320,181,1380,170.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
          </svg>

          <Box minHeight={250}>
            <Stamps
              stampsselected={NoofStamps}
              pickImg={PickImg}
              pickunImg={pickunImg}
              stampedBackground={StampColor}
              unstampedBackground={unStampColor}
              stampCount={stampExist}
            />
          </Box>
          <Box p="1rem">
            <Typography variant="p">{cardDesc2}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: CardColor,
            minHeight: 550,
            borderRadius: "25px",
          }}
        >
          <div className="decription-section">
            <p className="decription-section-h4">REWARD DETAILS</p>
            <p>{cardDesc4}</p>
          </div>
          <div className="decription-section">
            <p className="decription-section-h4">TERM & CONDITIONS</p>
            <p>{cardDesc5}</p>
          </div>
          <div className="decription-section">
            <p className="decription-section-h4">
              QUESTIONS ABOUT THIS CARD. PLEASE CONTACT
            </p>
            <p>{cardDesc3}</p>
          </div>
        </Box>
      </Box>
    </Card>
  );
}
