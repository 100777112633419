import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import ImageHeaderOperators from "../components/common/ImageHeaderOperators";
import Loading3 from "../components/Loading/Loading3";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import { styled } from "@mui/material/styles";

//SUPPORT
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";

//QRCODE
import { QRCode } from "react-qrcode-logo";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
//print PDF file
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint, PrintContextConsumer } from "react-to-print";
import Badge from "@mui/material/Badge";

//REDUX

import MenuItem from "../components/common/menuItem/MenuItem";

// show MODAL QR CODE FOR MOBILE
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { grey } from "@mui/material/colors";
import { setIsCartOpen, setContactInfo } from "../redux/features/cartState";

function MenuPage() {
  const { operatorId } = useParams();
  window.scrollTo(0, 0);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [operatorInfo, setOperatorInfo] = useState([]);
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const REACT_APP_CLIENT_URL = "https://lokalai.com";

  const Img = styled("img")(({ theme }) => ({
    margin: "auto",
    display: "block",
    width: "100%",
    height: 100,
    [theme.breakpoints.up("sm")]: {
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      height: 250,
    },
    [theme.breakpoints.up("lg")]: {
      height: 250,
    },
    [theme.breakpoints.up("xl")]: {
      height: 250,
    },
    objectFit: "cover",
  }));

  const dispatch = useDispatch();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const { user } = useSelector((state) => state.user);
  //   console.log(user);
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  useEffect(() => {
    let variable = { operator: operatorId };

    axios
      .post(`${baseURL}api/products/operatorInfo`, variable)
      .then((response) => {
        if (response.data.success) {
          setOperatorInfo(response.data.operator);
          console.log("operatorInfo :", operatorInfo);
          setData(true);
          dispatch(setContactInfo(response.data.operator));
        } else {
          toast.error("Failed to get operator info");
        }
      });

    //GET MENU CATEGORY
    axios
      .post(`${baseURL}api/menu/getMenuCategory`, variable)
      .then((response) => {
        if (response.data.success) {
          setCategories(response.data.category);
          // console.log({ categories });
        } else {
          toast.error("Failed to get operator Menu Category");
        }
      });

    //GET MENU ITEMS
    axios.post(`${baseURL}api/menu/getMenuItems`, variable).then((response) => {
      if (response.data.success) {
        setMenuItems(response.data.menuItem);
        console.log({ menuItems });
        // console.log("product:", products[0].operator)
      } else {
        toast.error("Failed to get Menu Item");
      }
    });
  }, []);

  const {
    images,
    title,
    desc,
    location,
    open_hours,
    videos,
    operator,
    logo,
    operatorname,
    updatedAt,
  } = categories;

  const downloadCode = () => {
    const canvas = document.getElementById("QR");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${operatorInfo[0].name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      "@page { size: auto;  margin: 20mm; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; } @media print { body { -webkit-print-color-adjust: exact; } @media all {.page-break {margin-top: 1rem; display: block; page-break-before: auto;} }}",
  });

  //show QR Code in Mobile version

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid primary.main",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const downloadCodeMobile = () => {
    const canvas = document.getElementById("QR-mobile");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${operatorInfo[0].name}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  // end of SHOW QR CODE for mobile version
  const isNonMobile = useMediaQuery("(min-width:600px)");

  if (data) {
    return (
      <>
        <ImageHeaderOperators imgPath={operatorInfo[0].coverPhoto} />
        <Box
          marginTop="00rem"
          sx={{ ...uiConfigs.style.mainContent }}
          ref={printRef}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            columnGap="00px"
            my="30px"
            sx={{ borderTop: 0, borderColor: "divider" }}
          >
            <Box
              flex="1 1 30%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: "pointer" }}
              marginTop="0rem"
              flexDirection={"column"}
            >
              <Box onClick={() => navigate(`/operator/${operatorInfo[0]._id}`)}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={operatorInfo[0].photo}
                    sx={{
                      margin: "0 0px",
                      width: { xs: 100, sm: 200 },
                      height: { xs: 100, sm: 200 },
                      border: "2px solid #fff",
                      objectFit: "cover",
                      backdropFilter: "blur(20px)",
                    }}
                  />
                </StyledBadge>
              </Box>

              {isNonMobile && (
                <>
                  <Typography
                  variant="h3"
                  fontWeight={700}
                  color="secondary.black"
                  textTransform={"capitalize"}
                  pt={{ xs: 0, sm: 3 }}
                >
                  FOOD MENU
                </Typography>
                  <Typography
                    variant="subtitle1"
                    color="seconday.black"
                    textTransform={"uppercase"}
                    p={{ xs: 0, sm: 0 }}
                    textAlign={"center"}
                  >
                    {operatorInfo[0].name}
                  </Typography>
                </>
              )}
            </Box>
            <Box
              flex="1 1 65%"
              mb="0px"
              border={0}
              display={"flex"}
              flexDirection={"column"}
              alignItems={{ xs: "start", md: "end" }}
            >
              {!isNonMobile && (<>
                <Typography
                  variant="h4"
                  color="secondary.black"
                  textTransform={"capitalize"}
                  fontWeight={700}
                  p={{ xs: 0, sm: 3 }}
                >
                  MENU
                </Typography>
                <Typography
                  variant="caption"
                  color="secondary.black"
                  textTransform={"uppercase"}
                  p={{ xs: 1, sm: 3 }}
                >
                  {operatorInfo[0].name}
                </Typography>
                <Divider />              
              </>

              )}
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"end"}
                border={0}
              >
                <Box
                  display={{ xs: "none", sm: "flex" }}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Button
                    type="button"
                    onClick={downloadCode}
                    sx={{
                      margin: "20px",
                      display: "flex",
                      flexDirection: "column",
                      color: "secondary.black",
                      bgcolor: `${grey[300]}`,
                      borderRadius: "5px",
                      width: 100,
                      "&:hover": {
                        backgroundColor: `${grey[600]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    <QrCodeScannerIcon /> Download
                  </Button>
                  <Button
                    type="button"
                    onClick={handlePrint}
                    sx={{
                      margin: "20px",
                      display: "flex",
                      flexDirection: "column",
                      color: "secondary.black",
                      bgcolor: `${grey[300]}`,
                      borderRadius: "5px",
                      width: 100,
                      "&:hover": {
                        backgroundColor: `${grey[600]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    <PrintIcon /> Print
                  </Button>
                </Box>
                {/* QR code big size: display for labtop */}
                <Box display={{ xs: "none", sm: "block" }}>
                  <QRCode
                    value={`${REACT_APP_CLIENT_URL}/operator/menu/${operatorId}`}
                    logoImage={operatorInfo[0].photo}
                    logoHeight={50}
                    logoWidth={50}
                    logoOpacity={1}
                    enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
                    qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
                    eyeRadius={10} // radius of the promocode eye
                    id={"QR"}
                    size={300}
                  />
                </Box>

                {/* QR code SMALL size: display for MOBILE */}
                <Box display={{ xs: "block", sm: "none" }} mb={2}>
                  <Button
                    type="button"
                    onClick={handleExpandClick}
                    sx={{
                      margin: "0px",
                      display: "flex",
                      flexDirection: "column",
                      color: "secondary.black",
                      bgcolor: `${grey[300]}`,
                      borderRadius: "5px",
                      width: 150,
                      "&:hover": {
                        backgroundColor: `${grey[600]}`,
                        boxShadow: "none",
                      },
                    }}
                  >
                    <QrCodeScannerIcon /> QR Download
                  </Button>
                  {/* Show QR code by MODAL */}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={expanded}
                    onClose={handleExpandClick}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={expanded}>
                      <Box sx={style}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          marginTop="-5rem"
                        >
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              src={operatorInfo[0].photo}
                              sx={{
                                margin: "0 0px",
                                width: 100,
                                height: 100,
                                border: "2px solid black",
                                objectFit: "cover",
                                backdropFilter: "blur(20px)",
                              }}
                            />
                          </StyledBadge>
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="caption"
                            fontSize={{ xs: "1.5rem" }}
                            textTransform={"uppercase"}
                            fontWeight={700}
                            color={"secondary.black"}
                            textAlign={"center"}
                          >
                            {operatorInfo[0].name}
                          </Typography>
                        </Box>
                        <QRCode
                          value={`${REACT_APP_CLIENT_URL}/operator/menu/${operatorId}`}
                          logoImage={operatorInfo[0].photo}
                          logoHeight={100}
                          logoWidth={100}
                          logoOpacity={1}
                          enableCORS={true}
                          qrStyle="dots"
                          eyeRadius={10}
                          id={"QR-mobile"}
                          size={300}
                        />
                        <Button
                          type="button"
                          onClick={downloadCodeMobile}
                          sx={{
                            margin: "0px",
                            display: "flex",
                            flexDirection: "column",
                            color: "primary.black",
                            backgroundColor: `${grey[300]}`,
                            "&:hover": {
                              backgroundColor: `${grey[600]}`,
                              boxShadow: "none",
                            },
                          }}
                        >
                          QR Download
                        </Button>
                      </Box>
                    </Fade>
                  </Modal>
                </Box>
              </Box>
            </Box>
            {/* <Divider sx={{mt: "20px"}} /> */}
            <Box
              sx={{
                p: 0,
                margin: "auto",
                // maxWidth: 500,
                flexGrow: 1,
                borderTop: 0,
                borderColor: "divider",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "transparent",
              }}
            >
              <Grid container spacing={0}>
                {categories.map((cat) => (
                  <Grid container spacing={0} mt={{ xs: "1rem", sm: "none" }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={{ xs: "start", sm: "center" }}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? "#1A2027"
                            : "transparent",
                        borderTop: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        fontSize={{ xs: "2rem", sm: "3rem" }}
                        color="secondary.black"
                      >
                        {cat.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Img src={cat.image} />
                    </Grid>
                    <Grid
                      container
                      borderBottom={0}
                      my={{ xs: "1rem", sm: "none" }}
                    >
                      {menuItems.map((item, index) => (
                        <>
                          {item.category === cat._id ? (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              py={{ xs: "0rem", sm: "none" }}
                              border={0}
                            >
                              <MenuItem item={item} />
                            </Grid>
                          ) : null}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <div>
        <Loading3 />
      </div>
    );
  }
}

export default MenuPage;
