import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
} from "../redux/features/cartState";
import { useNavigate } from "react-router-dom";


const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  // console.log({ cart });
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  // const [isCartOpen,setIsCartOpen] = useState(true)

  // console.log({isCartOpen})

  const totalPrice = cart.reduce((total, item) => {
    return total + item.count * item.price;
  }, 0);

  return (
    <Box
      display={isCartOpen ? "block" : "none"}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="0"
      overflow="auto"
    >
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px)"
        height="100%"
        backgroundColor="white"
      >
        <Box p={3} overflow="auto" height="100%" paddingTop={13} border={1}>
          {/* HEADER */}
          <FlexBox mt={5}>
            <Typography variant="h4" fontWeight={700} color={"secondary.main"}>
              SHOPPING BAG ({cart.length})
            </Typography>
            <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
              <CloseIcon />
            </IconButton>
          </FlexBox>

          {/* CART LIST */}
          <Box my={2}>
            {cart.length ===0 ? <Typography variant="h4" textAlign={"center"}>Cart is Empty</Typography> : undefined}
            {cart.map((item) => (
              <Box key={`${item.title}-${item._id}`} border={0}>
                <FlexBox px={1} border={0} py={1}>
                  <Box flex="1 1 100%" border={0}>
                    <FlexBox mt="0px" border={0} alignItems={"start"}>
                      <Box display="flex" alignItems="start" gap={1}>
                        <Typography color={"primary.contrastText"}>
                          {item.count}
                        </Typography>
                        <Typography color={"primary.contrastText"}>
                          x
                        </Typography>
                        <Typography
                          color={"primary.contrastText"}
                          fontSize={"small"}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                      <FlexBox border={0} justifyContent={"end"} pl={1}>
                        <Typography
                          fontWeight="bold"
                          color={"primary.contrastText"}
                        >
                          ${item.price * item.count}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            dispatch(removeFromCart({ _id: item._id }))
                          }
                          color={"gray"}
                          sx={{border: 0}}
                        >
                          <CloseIcon color={"gray"} fontSize={"small"}/>
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  </Box>
                </FlexBox>
                {/* <Divider /> */}
              </Box>
            ))}
          </Box>

          {/* ACTIONS */}
          <Box m={0}>
            <FlexBox mx={2} border={0} my={1}>
              <Typography color={"gray"} fontSize={"small"}>
                Sub-Total
              </Typography>
              <Typography color={"gray"} fontSize={"small"}>
                ${Math.round(totalPrice * 100) / 100}
              </Typography>
            </FlexBox>
            <FlexBox mx={2} border={0} my={1}>
              <Typography color={"gray"} fontSize={"small"}>
                GST (15% included)
              </Typography>
              <Typography color={"gray"} fontSize={"small"}>
                ${Math.round(((totalPrice * 3) / 23) * 10) / 10}
              </Typography>
            </FlexBox>
            <FlexBox mx={2} border={0} my={1}>
              <Typography
                color={"primary.contrastText"}
                fontSize={"medium"}
                fontWeight={700}
              >
                TOTAL
              </Typography>
              <Typography
                color={"primary.contrastText"}
                fontSize={"medium"}
                fontWeight={700}
              >
                ${Math.round(totalPrice * 10) / 10}
              </Typography>
            </FlexBox>
            <FlexBox               sx={{
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: "5px",
              }}>
              <Box ml={3}>
                <Typography color={"white"}>TOTAL</Typography>
                <Typography color={"white"} fontWeight={"bold"}>${totalPrice}</Typography>
              </Box>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: 0,
                // minWidth: "100%",
                padding: "0px 40px",
                m: "20px 0",
                fontSize:'1.3rem'
              }}
              onClick={() => {
                navigate(`/checkout/${cart[0].operator}`);
                dispatch(setIsCartOpen({}));
              }}
            >
              CHECKOUT
            </Button>
            </FlexBox>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartMenu;
