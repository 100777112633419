const menu = [
  {
    id: 1,
    title: '1. HOMETOWN FRESH SPRINGS ROLLS - G.F',
    category: 'APPETIZERS',
    price: 9.50,
    img: './images/item-1.jpeg',
    desc: `Gỏi Cuốn Đặc Biệt 招牌鲜春卷 Egg, fish, grilled meat balls, salad and rice vermicelli wrapped in rice paper with special fish sauce.
    9.50`,
  },
  {
    id: 2,
    title: '2. PRAWN & PORK FRESH SPRING ROLLS-G.F',
    category: 'APPETIZERS',
    price: 9.50,
    img: './images/item-2.jpeg',
    desc: `Gỏi Cuốn Tôm & Thịt 鲜虾猪肉鲜春卷 Prawn, pork, salad and rice vermicelli wrapped in rice paper with homemade peanut sauce. `,
  },
  {
    id: 3,
    title: '3. CHICKEN FRESH SPRING ROLLS - G.F',
    category: 'APPETIZERS',
    price: 9.50,
    img: './images/item-3.jpeg',
    desc: `Gỏi Cuốn Gà 鸡肉鲜春卷 Chicken, salad and rice vermicelli wrapped in rice paper with home-made peanut sauce.`,
  },
  {
    id: 4,
    title: '4. VEGETARIAN FRESH SPRING ROLLS-G.F',
    category: 'APPETIZERS',
    price: 9.50,
    img: './images/item-3.jpeg',
    desc: `Gỏi Cuốn Chay 素食鲜春卷下(豆腐) Tofu, salad and rice vermicelli served with homemade peanut sauce.`,
  },
    {
    id: 5,
    title: '5. ROYAL DEEP FRIED SPRING ROLLS G.F',
    category: 'APPETIZERS',
    price: 9.50,
    img: './images/item-3.jpeg',
    desc: `Chả Giò Chiên Giòn 越南炸春卷(猪肉) Pork mince, vegetables wrapped in rice paper with special fish sauce.`,
  },
  {
    id: 6,
    title: '6. CRISPY CHICKEN - G.F.O',
    category: 'APPETIZERS',
    price: 14.00,
    img: './images/item-3.jpeg',
    desc: `Gà Chiên Giòn 越式炸鸡皮`,
  },
  {
    id: 7,
    title: '7. CRISPY SQUID G.F.O',
    category: 'APPETIZERS',
    price: 14.00,
    img: './images/item-3.jpeg',
    desc: `Mực Chiên Giòn 炸鲜鱿鱼`,
  },
  {
    id: 8,
    title: '8. SALTED AND CHILLI CHICKEN KNUCKLE - G.F',
    category: 'APPETIZERS',
    price: 13.00,
    img: './images/item-3.jpeg',
    desc: `Sụn Gà Chiên Giòn 炸鸡脆骨 Deep fried chicken knuckles with salt and chilli and salad`,
  },
  {
    id: 9,
    title: '9. VIETNAMESE PANCAKE - G.F',
    category: 'APPETIZERS',
    price: 17.00,
    img: './images/item-3.jpeg',
    desc: `Bánh Xèo 越式煎薄饼 Options for prawn, pork chicken served with bean sprouts, onion, salad and special fish sauce.`,
  },
  {
    id: 10,
    title: '10. PRAWN SOUP',
    category: 'APPETIZERS',
    price: 14.00,
    img: './images/item-3.jpeg',
    desc: `Súp Tom`,
  },
  {
    id: 11,
    title: '11. CRISPY PORK WITH FINE VERMEI',
    category: 'APPETIZERS',
    price: 14.00,
    img: './images/item-3.jpeg',
    desc: `Bánh Hỏi Heo Quay`,
  },
  {
    id: 12,
    title: '12. CHICKEN CORN SOUP',
    category: 'APPETIZERS',
    price: 14.00,
    img: './images/item-3.jpeg',
    desc: `玉米鸡肉汤`,
  },
  {
    id: 13,
    title: '13. SPECIAL ROYAL PHO-G.F',
    category: 'PHO',
    price: 23.00,
    img: './images/item-13.jpeg',
    desc: `Phở Bò Đặc Biệt 越式牛杂汤米粉 Rice noodle soup served with ox tails, beef balls, medium rare beef and brisket beef, bean sprouts and onion.`,
  },
  {
    id: 14,
    title: '14. BEEF PHO-G.F',
    category: 'PHO',
    price: 18.50,
    img: './images/item-13.jpeg',
    desc: `Phở Bò 越式牛肉汤米粉 Rice noodle soup served your option, bean sprouts and onion.`,
  },
  {
    id: 15,
    title: '15. SEAFOOD PHO-G.F',
    category: 'PHO',
    price: 19.50,
    img: './images/item-13.jpeg',
    desc: `Phở Hải Sản 越式海鲜汤米粉 Rice noodle soup served with prawn, scallop, fish balls, mussel, squid.`,
  },
  {
    id: 16,
    title: '16. CHICKEN PHO-G.F',
    category: 'PHO',
    price: 17.50,
    img: './images/item-13.jpeg',
    desc: `Phở Gà 越式鸡肉汤米粉 Rice noodle soup served with chicken, bean sprouts and onion`,
  },
  {
    id: 17,
    title: '17. VEGETARIAN PHO-G.F',
    category: 'PHO',
    price: 17.50,
    img: './images/item-13.jpeg',
    desc: `Phở Chay 越式蔬菜汤米粉 Rice noodle soup served with tofu, carrot, mushroom, onion, broc-coli.`,
  },
  {
    id: 18,
    title: '18. SPECIAL FISH CAKE THICK NOODLE SOUP - G.F',
    category: 'NOODLE SOUP',
    price: 17.50,
    img: './images/item-18.jpeg',
    desc: `Bánh Canh Chả Cá 越式鱼饼乌冬汤粉 Thick noodle soup served with fish cake, spring onion, coriander.`,
  },
  {
    id: 19,
    title: '19. VIETNAMESE CRAB NOODLE SOUP - G.F',
    category: 'NOODLE SOUP',
    price: 17.50,
    img: './images/item-18.jpeg',
    desc: `Bún Riêu Cua 越式虾蟹米线汤 Rice vermicelli soup served with prawn, crab chowder, tomato spring onion and prawn paste.`,
  },
  {
    id: 20,
    title: '20. SPICY VIETNAMESE BEEF NOODLE SOUP - G.F',
    category: 'NOODLE SOUP',
    price: 17.50,
    img: './images/item-18.jpeg',
    desc: `Bún Bò Huế 越式辣牛肉米线汤 Rice vermicelli soup served with beef shank, pork shank, bean sprout`,
  },
  {
    id: 21,
    title: '21. BEEF STEW NOODLES SOUP - G.F.O',
    category: 'NOODLE SOUP',
    price: 17.50,
    img: './images/item-18.jpeg',
    desc: `Bún Bò Kho 越式牛腩米粉汤 Rice noodle soup served with beef rib, carrot, onion, bean sprout`,
  },
  {
    id: 22,
    title: '22. BBQ PORK EGG NOODLES (SOUP)',
    category: 'NOODLE SOUP',
    price: 16.00,
    img: './images/item-18.jpeg',
    desc: `Mì Xá Xíu (Soup) 越式叉烧面 Egg noddle soup with BBQ pork, bean sprout and spring onion.`,
  },
  {
    id: 23,
    title: '23. ROASTED QUARTER CHICKEN LEG EGG NOODLE SOUP',
    category: 'NOODLE SOUP',
    price: 16.50,
    img: './images/item-18.jpeg',
    desc: `Mì Gà Quay (Soup)`,
  },
  {
    id: 24,
    title: '24. GRILLED PORK RICE VERMICELLI - G.F',
    category: 'RICE VERMICELLI',
    price: 17.50,
    img: './images/item-24.jpeg',
    desc: `Bún Thịt Nướng 越式烤猪肉干捞米线 Rice vermicelli, grilled pork, deep fried spring rolls and salad.`,
  },
  {
    id: 25,
    title: '25. SPECIAL RICE VERMICELLI - G.F',
    category: 'RICE VERMICELLI',
    price: 19.50,
    img: './images/item-24.jpeg',
    desc: `Bún Đặc Biệt 越南特色干捞米线 Rice vermicelli, grilled pork, grilled meat balls, fried spring rolls, salad, pickle.`,
  },
  {
    id: 26,
    title: '26. STIR FRIED MEAT ON RICE VERMICELLI - G.F',
    category: 'RICE VERMICELLI',
    price: 17.50,
    img: './images/item-24.jpeg',
    desc: `Bún Xào 越南特色炒米线(可选:鸡肉,牛肉,猪肉 或 虾) CHOOSE: CHICKEN, BEEF, PORK,TOFU`,
  },
  {
    id: 27,
    title: '27. FRIED EGG NOODLE WITH MEATS',
    category: 'EGG NOODLE',
    price: 17.50,
    img: './images/item-27.jpeg',
    desc: `Mì Xào Thịt Các Loại 越南特色炒面(可选:鸡肉,牛肉,猪肉 或 豆腐) Stir fried noodle served with your meat option, onion and mix vegetable. CHOOSE: CHICKEN, BEEF, PORK,TOFU`,
  },
  {
    id: 28,
    title: '28. SEAFOOD STIR FRIED EGG NOODLE',
    category: 'EGG NOODLE',
    price: 19.50,
    img: './images/item-27.jpeg',
    desc: `Mì Xào Hải Sản 越南特色海鲜炒面 Stir fried with prawn, mussel, crab stick, squid, fish ball and mix vegetable.`,
  }, 
  {
    id: 29,
    title: '29. COMBINATION FRIED EGG NOODLES',
    category: 'EGG NOODLE',
    price: 19.50,
    img: './images/item-27.jpeg',
    desc: `Mì Xào Thập Cẩm 越南特色炒面(牛肉、鸡肉和猪肉) Stir fried noodle with beef, chicken and pork`,
  },
  {
    id: 30,
    title: '30. FRIED RICE NOODLE WITH MEATS - G.F',
    category: 'RICE NOODLES',
    price: 18.50,
    img: './images/item-30.jpeg',
    desc: `Hủ Tiếu Xào Thịt Các Loại 越南炒米粉(可选鸡肉,牛肉,猪肉 或豆腐) Stir fried noodle served with your protein option, onion and mix vegetable. CHOOSE: CHICKEN, BEEF, PORK,TOFU`,
  },
  {
    id: 31,
    title: '31. COMBINATION FRIED RICE NOODLES - G.F',
    category: 'RICE NOODLES',
    price: 19.50,
    img: './images/item-30.jpeg',
    desc: `Hủ Tiếu Xào Thập Cẩm 越南什锦炒米粉(有鸡肉,牛肉和 猪肉) Stir fried noodle served with beef, chicken and pork.`,
  },
  {
    id: 32,
    title: '32. SATAY CHICKEN FRIED RICE NOODLE - G.F',
    category: 'RICE NOODLES',
    price: 18.00,
    img: './images/item-30.jpeg',
    desc: `Hủ Tiếu Xào Satay 沙爹鸡炒米粉 Stir fried chicken satay with rice noodle, onion, mix vegetable.`,
  }, 
  {
    id: 33,
    title: '33. SEAFOOD STIR FRIED RICE NOODLE - G.F',
    category: 'RICE NOODLES',
    price: 19.50,
    img: './images/item-30.jpeg',
    desc: `Hủ Tiếu Xào Hải Sản 海鲜炒米粉 Stir fried with prawn, mussel, crab stick, squid,f ish ball and mix vegetable.`,
  }, 
  {
    id: 34,
    title: '34. STIR FIRED RICE NOODLE WITH LEMONGRASS-G.F',
    category: 'RICE NOODLES',
    price: 18.00,
    img: './images/item-30.jpeg',
    desc: `Hủ Tiếu Xào Thịt Các Loại Với Sả Ớt 越式香茅炒米粉(可选:鸡肉,牛肉,猪肉或豆腐) Stir fried your meat choice with lemongrass, noodle, onion, mix vegetable. CHOOSE: CHICKEN, BEEF, PORK, TOFU`,
  },
  {
    id: 35,
    title: '35. FRIED RICE',
    category: 'FRIED RICE',
    price: 17.50,
    img: './images/item-35.jpeg',
    desc: `Cơm Chiên 越式炒饭(可选:豆腐,鸡肉,猪肉 或 牛肉) CHOOSE: TOFU, CHICKEN, PORK OR BEEF`,
  },
  {
    id: 36,
    title: '36. SPECIAL ROYAL FRIED RICE',
    category: 'FRIED RICE',
    price: 19.50,
    img: './images/item-35.jpeg',
    desc: `Cơm Chiên Đặc Biệt 特色越南什锦炒饭 Fried rice with Vietnamese sausage, BBQ pork, chicken and prawn with mix vegetable.`,
  },
  {
    id: 37,
    title: '37. SEAFOOD FIRED RICE',
    category: 'FRIED RICE',
    price: 19.50,
    img: './images/item-35.jpeg',
    desc: `Cơm Chiên Hải Sản 海鲜炒饭 Fried rice with crabstick, prawn, squid, fish ball, egg and mix veg-etables.`,
  },
  {
    id: 38,
    title: '38. COMBINATION FRIED RICE',
    category: 'FRIED RICE',
    price: 19.50,
    img: './images/item-35.jpeg',
    desc: `Cơm Chiên Thập Cẩm 什锦炒饭(可选:豆腐·鸡肉,猪肉 或 牛肉)`,
  },
  {
    id: 39,
    title: '39. GRILLED PORK AND EGG ON RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `Cơm Thịt Nướng Với Trứng 越南烤肉煎蛋饭 Grill pork and eggs served with salad, cucumber on rice.`,
  },
  {
    id: 40,
    title: '40. CHICKEN HAI NAM ON RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `海南鸡饭 Cơm Gà Hải Nam Hai nam style chicken served with ginger sauce, cucumber and special rice.`,
  },
  {
    id: 41,
    title: '41. ROASTED PORK ON RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `Cơm Heo Quay 烧肉饭 Roasted pork with cucumber, Special sauce on rice.`,
  },
  {
    id: 42,
    title: '42. ROASTED DUCK ON RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `Cơm Vịt Quay 烤鸭饭 Roasted duck served with cucumber, special sauce on rice.`,
  },
  {
    id: 43,
    title: '43. ROASTED QUARTER CHICKEN LEG ON SPECIAL RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `Cơm Đùi Gà Quay 越式烤鸡腿饭 Roasted chicken leg served with special tomato rice, salad.`,
  },
  {
    id: 44,
    title: '44. CHICKEN/BEEF/PORK LEMONGRASS & CHILLI ON RICE',
    category: 'STEAM RICE DISH',
    price: 18.50,
    img: './images/item-40.jpeg',
    desc: `Cơm Thịt Sả Ớt 越式碳烤鸡饭 Chicken/Beef/Pork lemongrass, chilli served with rice & salad.`,
  },
  {
    id: 45,
    title: '45. TOFU SALAD - G.F',
    category: 'SALAD',
    price: 22.00,
    img: './images/item-45.jpeg',
    desc: `Cơm Thịt Sả Ớt 越式碳烤鸡饭 Chicken/Beef/Pork lemongrass, chilli served with rice & salad.`,
  },
  {
    id: 46,
    title: '46. SPICY BEEF SALAD - G.F',
    category: 'SALAD',
    price: 23.50,
    img: './images/item-45.jpeg',
    desc: `Gỏi Bò Cay 辣牛肉沙拉 Stir fried spicy beef come with pickled salad: capsicum, tomato, ginger, onion served with peanuts and special sauce.`,
  },
  {
    id: 47,
    title: '47. CHICKEN SALAD - G.F',
    category: 'SALAD',
    price: 22.00,
    img: './images/item-45.jpeg',
    desc: `Gỏi Gà 鸡肉沙拉 Shredded chicken with pickled salad: cabbage, capsicum, cucumber, ginger, onion served with peanuts and special sauce`,
  },
  {
    id: 48,
    title: '48. SEAFOOD SALAD - G.F',
    category: 'SALAD',
    price: 25.00,
    img: './images/item-45.jpeg',
    desc: `Gỏi Hải Sản 海鲜沙拉 Stir fried prawn, squid, fish cake with pickled salad: cabbage, capsicum, toma-to, ginger, onion served with special sauce.`,
  },
  {
    id: 49,
    title: '49. TOFU LEMONGRASS AND CHILLI - G.F',
    category: 'VEGETARIAN',
    price: 22.00,
    img: './images/item-50.jpeg',
    desc: `Dậu Hũ Xào Xả Ớt 柠檬草炒豆腐`,
  },
  {
    id: 50,
    title: '50. TOFU VEGETABLE CASHEW NUT - G.F',
    category: 'VEGETARIAN',
    price: 23.50,
    img: './images/item-50.jpeg',
    desc: `Dậu Hũ Xào Hạt Điều 腰果素菜炒豆腐`,
  },
  {
    id: 51,
    title: '51. STIR FRIED MIXED VEGETABLE - G.F',
    category: 'VEGETARIAN',
    price: 18.00,
    img: './images/item-50.jpeg',
    desc: `Rau Xào 炒什锦素菜 Stir fried mixed vegetable of broccoli, onion, capsicum, cauliflower, carrot and garlic sauce.`,
  },
  {
    id: 52,
    title: '52. STIR FRIED GREEN BEAN WITH GARLIC - G.F',
    category: 'VEGETARIAN',
    price: 18.00,
    img: './images/item-50.jpeg',
    desc: `Đậu Que Xào Với Tỏi 炒什锦素菜 Stir fried green bean come with garlic sauce and onion.`,
  },
  {
    id: 53,
    title: '53. STIR FRIED BOK CHOY WITH GARLIC - G.F',
    category: 'VEGETARIAN',
    price: 18.00,
    img: './images/item-50.jpeg',
    desc: `Cải Xào Với Tỏi 蒜炒白菜 Stir fried Bok choy with garlic sauce, onion.`,
  },
  {
    id: 54,
    title: '54. CHICKEN LEMONGRASS & CHILLI - G.F',
    category: 'CHICKEN',
    price: 22.50,
    img: './images/item-55.jpeg',
    desc: `Gà Xào Sả Ớt 辣炒香茅鸡 Stir fried of chilli chicken with lemongrass, onion and garlic.`,
  },
  {
    id: 55,
    title: '55. CHICKEN CURRY - G.F',
    category: 'CHICKEN',
    price: 22.50,
    img: './images/item-55.jpeg',
    desc: `Cà Ri Gà. 咖喱鸡 Chicken curry with coconut cream, onion, carrot, capsicum.`,
  },
  {
    id: 56,
    title: '56. SWEET & SOUR CHICKEN - G.F',
    category: 'CHICKEN',
    price: 22.50,
    img: './images/item-55.jpeg',
    desc: `Gà Xào Chua Ngọt 咕咾鸡(糖醋鸡) Stir fried sweet and sour chicken with capsicum, onion and mix vegeta-ble.`,
  },
  {
    id: 57,
    title: '57. SIZZLING CHICKEN SATAY - G.F',
    category: 'CHICKEN',
    price: 22.50,
    img: './images/item-55.jpeg',
    desc: `Gà Xào Satay Trên Dĩa Nóng 铁板沙爹鸡 Chicken Satay served on hot plate come with onion, mix vegetable.`,
  },  
  {
    id: 58,
    title: '58. CHICKEN CASHEW NUT - G.F',
    category: 'CHICKEN',
    price: 22.50,
    img: './images/item-55.jpeg',
    desc: `Gà Xào Hạt Điều 腰果鸡丁 Stir fried chicken with cashew nuts and mix vegetable.`,
  },  
  {
    id: 59,
    title: '59. FIVE-SPICE CHICKEN - G.F',
    category: 'CHICKEN',
    price: 25.00,
    img: './images/item-55.jpeg',
    desc: `Gà Xào Ngũ Vị Hương 八角风味炒鸡 Stir fried chicken with five spice, garlic sauce with capsicum, onion, car-rot, spring onion and egg plant.`,
  },
  {
    id: 60,
    title: '60. BEEF WITH LEMONGRASS & CHILLI - G.F',
    category: 'BEEF',
    price: 22.00,
    img: './images/item-60.jpeg',
    desc: `Bò Xào Sả Ớt 香茅辣炒牛肉 Stir fried beef with lemongrass, chilli, onion.`,
  },
  {
    id: 61,
    title: '61. BEEF CURRY - G.F',
    category: 'BEEF',
    price: 22.50,
    img: './images/item-60.jpeg',
    desc: `Cà Ri Bò 咖喱牛肉 Beef curry with coconut cream, onion, carrot, capsicum.`,
  },
  {
    id: 62,
    title: '62. SIZZLING BEEF SATAY - G.F',
    category: 'BEEF',
    price: 25.00,
    img: './images/item-60.jpeg',
    desc: `Bò Xào Satay Trên Dĩa Nóng 铁板沙爹牛肉 Stir fried beef satay with capsicum, onion, carrot served on hot plate.`,
  },
  {
    id: 63,
    title: '63. SHAKING BEEF ON HOT PLATE - G.F',
    category: 'BEEF',
    price: 25.00,
    img: './images/item-60.jpeg',
    desc: `Bò Lúc Lắc 越式铁板牛肉粒 Stir fried beef with onion, capsicum, cabbage on hot plate.`,
  },
  {
    id: 64,
    title: '64. FIVE-SPICE BEEF - G.F',
    category: 'BEEF',
    price: 25.00,
    img: './images/item-60.jpeg',
    desc: `Bò Xào Ngũ Vị Hương 八角风味牛肉 Stir fried beef with five spice, garlic sauce with capsicum, onion, carrot, spring onion and egg plant.`,
  },
  {
    id: 65,
    title: '65. BEEF WITH MIX VEGETABLES',
    category: 'BEEF',
    price: 21.50,
    img: './images/item-60.jpeg',
    desc: `Bò Xào Bông Cải 时蔬炒牛肉`,
  },
  {
    id: 66,
    title: '66. SWEET AND SOUR PORK - G.F',
    category: 'PORK',
    price: 22.50,
    img: './images/item-65.jpeg',
    desc: `Heo Xào Chua Ngọt 红烧咕咾肉 Stir fried sweet and sour pork with capsicum, onion, tomato.`,
  },
  {
    id: 67,
    title: '67. BBQ PORK WITH STIR-FRIED GREEN BEAN - G.F',
    category: 'PORK',
    price: 22.50,
    img: './images/item-65.jpeg',
    desc: `Thịt Xá Xíu Xào Với Đậu Que 叉烧炒四季豆 Stir fried BBQ pork with green bean, spring onion.`,
  },
  {
    id: 68,
    title: '68. CRISPY SKIN ROAST PORK - G.F',
    category: 'PORK',
    price: 27.00,
    img: './images/item-65.jpeg',
    desc: `Heo Quay 烧肉 Special Vietnamese Style roasted pork with special sauce.`,
  },
  {
    id: 69,
    title: '69. FIVE-SPICE PORK - G.F',
    category: 'PORK',
    price: 25.00,
    img: './images/item-65.jpeg',
    desc: `Heo Xào Ngũ Vị Hương 八角风味炒猪肉 Stir fried pork with five spice, garlic sauce with capsicum, onion, carrot, spring onion and egg plant.`,
  },
  {
    id: 70,
    title: '70. BBQ PORK RIBS - G.F',
    category: 'PORK',
    price: 25.00,
    img: './images/item-65.jpeg',
    desc: `Sườn Nướng 碳烤酱排骨 Special Vietnamese Style BBQ pork ribs.`,
  },
  {
    id: 71,
    title: '71. PORK LEMONGRASS AND CHILLI - G.F',
    category: 'PORK',
    price: 22.50,
    img: './images/item-65.jpeg',
    desc: `Heo Xào Xả Ớt 香茅炒猪肉 Stir fried pork with lemongrass, chilli, onion & mix vegetable.`,
  },
  {
    id: 72,
    title: '72. STIR FRIED GREEN BEAN AND CHINESE SAUSAGE',
    category: 'PORK',
    price: 24.50,
    img: './images/item-65.jpeg',
    desc: `Đậu Que Lạp Xưởng 腊肠炒四季豆`,
  },
  {
    id: 73,
    title: '73. ROASTED DUCK - G.F',
    category: 'BBQ PLATE',
    price: 32.00,
    img: './images/item-70.jpeg',
    desc: `Vịt Quay Đặc Biệt 烤鸭 半只/整只 Special Vietnamese Style Roasted Duck`,
  },
  {
    id: 74,
    title: '74. ROYAL COMBINATION - G.F',
    category: 'BBQ PLATE',
    price: 65.00,
    img: './images/item-70.jpeg',
    desc: `Thịt Quay Thập Cẩm 烧腊拼盘 叉烧·烤鸭·烧肉 BBQ pork, roasted duck, roasted pork.`,
  },
  {
    id: 75,
    title: '75. STIR FRIED KING PRAWN WITH CASHEW NUT - G.F',
    category: 'SEAFOOD',
    price: 26.50,
    img: './images/item-75.jpeg',
    desc: `Tôm Xào Với Hạt Điều 腰果炒大虾 Stir fried king prawn with mix vegetable, cashew nut and garlic.`,
  },                                                                 
  {
    id: 76,
    title: '76. DEEP FRIED SALTLED & CHILLI KING PRAWN-G.F',
    category: 'SEAFOOD',
    price: 26.50,
    img: './images/item-75.jpeg',
    desc: `Tôm Sóc Muối Ớt 避风塘炸大虾 Deep fried king prawn with salt, chilli, garlic.`,
  },
  {
    id: 77,
    title: '77. STIR FRIED KING PRAWN WITH GARLIC AND CHILLI - G.F',
    category: 'SEAFOOD',
    price: 26.00,
    img: './images/item-75.jpeg',
    desc: `Tôm Xào Tỏi Ớt 香辣蒜蓉大虾 Stir fried king prawn with garlic.`,
  },
  {
    id: 78,
    title: '78. KING PRAWN CURRY',
    category: 'SEAFOOD',
    price: 26.00,
    img: './images/item-75.jpeg',
    desc: `Tôm Cà Ri 咖喱大虾 King prawn with, onion, tomatoes, capsicum and curry coconut cream.`,
  },
  {
    id: 79,
    title: '79. SALTED & GARLIC CRISPY SQUID G.F',
    category: 'SQUID',
    price: 26.00,
    img: './images/item-80.jpeg',
    desc: `Mực Xóc Muối Ớt 蒜蓉炸鱿鱼 Stir fried squid with salt, garlic and onion.`,
  },
  {
    id: 80,
    title: '80. SQUID LEMONGRASS',
    category: 'SQUID',
    price: 26.00,
    img: './images/item-80.jpeg',
    desc: `Mực Xào Sả Ớt 越式香茅鱿鱼 Stir fried squid with lemongrass, chilli, onion`,
  },
  {
    id: 81,
    title: '81. VIETNAMESE SWEET AND SOUR FISH SOUP - G.F',
    category: 'FISH',
    price: 26.00,
    img: './images/item-85.jpeg',
    desc: `Canh Chua Cá 越式酸辣鱼汤 Vietnamese style stir fried fish with sweet and sour sauce, onion, capsicum, onion.`,
  },
  {
    id: 82,
    title: '82. FISH BALL SOUP WITH BOK CHOY',
    category: 'FISH',
    price: 26.00,
    img: './images/item-85.jpeg',
    desc: `Canh Cải Cá Viên 鱼蛋汤 Fish ball with bok choy.`,
  },
  {
    id: 83,
    title: '83. FRIED FISH FILLET ON SPECIAL SAUCE - G.F',
    category: 'FISH',
    price: 26.00,
    img: './images/item-85.jpeg',
    desc: `Cá Chiên Với Sốt Đặc Biệt 越式甜辣酱炸鱼片`,
  }, 
  {
    id: 84,
    title: '84. FISH CURRY FILLET',
    category: 'FISH',
    price: 26.00,
    img: './images/item-85.jpeg',
    desc: `Cà Ri Cá 越式咖喱鱼片 Fish with, onion, tomatoes, capsicum and curry coconut cream.`,
  }, 
  {
    id: 85,
    title: '85. FISH LEMONGRASS AND CHILLI',
    category: 'FISH',
    price: 25.00,
    img: './images/item-85.jpeg',
    desc: `Cá Sȧ Ot 越式香茅鱼片 Fish with lemongrass and chilli.`,
  },
  {
    id: 86,
    title: '86. FRIED FISH WITH TAMARIND SAUCE - G.F (WHOLE) - POA',
    category: 'FISH',
    price: 0.00,
    img: './images/item-85.jpeg',
    desc: `Cá Chiên Nguyên Con Với Sốt Me 越式罗望子酱炒鱼片 Fried Fish with gralic, chilli and tamarind sauce`,
  },
  {
    id: 87,
    title: '87. SEASONAL STEAM WHOLE FISH WITH OYSTER SAUCE - G.F - POA',
    category: 'FISH',
    price: 0.00,
    img: './images/item-85.jpeg',
    desc: `Cá Hấp Với Dầu Hào 耗油蒸时鱼 Steamed seasonal whole fish oyster sauce, spring onion and ginger.`,
  },
  {
    id: 88,
    title: '88. SEASONAL FRIED FISH WITH SPECIAL SAUCE - G.F - POA',
    category: 'FISH',
    price: 0.00,
    img: './images/item-85.jpeg',
    desc: `Cá Chiên Giòn Với Sốt Đặc Biệt 越式家乡炸时鱼`,
  },
  {
    id: 89,
    title: 'SET MENU 8-10 PEOPLE ($38/PERSON)',
    category: 'SET MENU',
    price: 38.00,
    img: './images/item-86.jpeg',
    desc: `1. Chicken corn soup 2. Hometown fresh spring roll 3. Beef salad 4. Crispy chicken 5. Combination fried rice 6. Sweet and sour pork 7. Chicken five spices 8. Fish fillet with special sauce 9. Mixed vegetable with garlic 10. Pudding caramel with ice cream`,
  },
  {
    id: 90,
    title: 'SET MENU 4-6 PEOPLE ($28/PERSON)',
    category: 'SET MENU',
    price: 28.00,
    img: './images/item-86.jpeg',
    desc: `1. Chicken corn soup 2. Hometown fresh spring roll 3. Beef salad 4. Chicken fried rice 5. Sweet and sour pork 6. Chicken satay 7. Mixed vegetable with garlic`,
  },
  {
    id: 91,
    title: 'Vietnamese Hot Coffee',
    category: 'BEVERAGE',
    price: 6.50,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 92,
    title: 'Vietnamese Iced Coffee',
    category: 'BEVERAGE',
    price: 7.00,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 93,
    title: 'Green Tea',
    category: 'BEVERAGE',
    price: 1.00,
    img: './images/item-90.jpeg',
    desc: `$1.00/person`,
  },
  {
    id: 94,
    title: 'Soft Drinks',
    category: 'BEVERAGE',
    price: 3.00,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 95,
    title: 'Lemon Squeeze',
    category: 'BEVERAGE',
    price: 5.00,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 96,
    title: 'Orange Juice',
    category: 'BEVERAGE',
    price: 5.00,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 97,
    title: 'Ginger beer',
    category: 'BEVERAGE',
    price: 5.50,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 98,
    title: 'Lemon',
    category: 'BEVERAGE',
    price: 5.50,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 99,
    title: 'Lime bitter',
    category: 'BEVERAGE',
    price: 5.50,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 100,
    title: 'Peach ice tea',
    category: 'BEVERAGE',
    price: 7.00,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 101,
    title: 'Peach lemongrass ice tea',
    category: 'BEVERAGE',
    price: 8.50,
    img: './images/item-90.jpeg',
    desc: ``,
  },
  {
    id: 102,
    title: 'Kem Chuối',
    category: 'DESSERT',
    price: 8.00,
    img: './images/item-100.jpeg',
    desc: `Deep Fried Banana With Ice Cream`,
  },
  {
    id: 103,
    title: 'Bánh Flan',
    category: 'DESSERT',
    price: 8.00,
    img: './images/item-100.jpeg',
    desc: `Bánh Flan`,
  },  
  {
    id: 104,
    title: 'Chè Ba Màu',
    category: 'DESSERT',
    price: 7.00,
    img: './images/item-100.jpeg',
    desc: `Combination Dessert With Coconut Milk And Ice`,
  },  
  {
    id: 105,
    title: 'Chè Đậu Đỏ',
    category: 'DESSERT',
    price: 7.00,
    img: './images/item-100.jpeg',
    desc: `Red Bean With Coconut Milk And Ice`,
  },  
  {
    id: 106,
    title: 'Chè Đậu Xanh',
    category: 'DESSERT',
    price: 8.00,
    img: './images/item-100.jpeg',
    desc: `Mung Bean With Coconut Milk And Ice`,
  },             
];

const MenuCategory = [
  {
    id: 1,
    category: 'APPETIZERS',
    img: './images/item-1.jpeg',
  },
  {
    id: 2,
    category: 'PHO',
    img: './images/item-13.jpeg',
  },
  {
    id: 3,
    category: 'NOODLE SOUP',
    img: './images/item-18.jpeg',
  },
  {
    id: 4,
    category: 'RICE VERMICELLI',
    img: './images/item-24.jpeg',
  },
  {
    id: 5,
    category: 'EGG NOODLE',
    img: './images/item-27.jpeg',
  },
  {
    id: 6,
    category: 'RICE NOODLES',
    img: './images/item-30.jpeg',
  },
  {
    id: 7,
    category: 'FRIED RICE',
    img: './images/item-35.jpeg',
  },
  {
    id: 8,
    category: 'STEAM RICE DISH',
    img: './images/item-40.jpeg',
  },
  {
    id: 9,
    category: 'SALAD',
    img: './images/item-45.jpeg',
  },
  {
    id: 10,
    category: 'VEGETARIAN',
    img: './images/item-50.jpeg',
  },
  {
    id: 11,
    category: 'CHICKEN',
    img: './images/item-55.jpeg',
  },
  {
    id: 12,
    category: 'BEEF',
    img: './images/item-60.jpeg',
  },
  {
    id: 13,
    category: 'PORK',
    img: './images/item-65.jpeg',
  },
  {
    id: 14,
    category: 'BBQ PLATE',
    img: './images/item-70.jpeg',
  },
  {
    id: 15,
    category: 'SEAFOOD',
    img: './images/item-75.jpeg',
  },
  {
    id: 16,
    category: 'SQUID',
    img: './images/item-80.jpeg',
  },
  {
    id: 17,
    category: 'FISH',
    img: './images/item-85.jpeg',
  },
  {
    id: 18,
    category: 'SET MENU',
    img: './images/item-86.jpeg',
  },
  {
    id: 19,
    category: 'BEVERAGE',
    img: './images/item-90.jpeg',
  },
  {
    id: 20,
    category: 'DESSERT',
    img: './images/item-100.jpeg',
  },
]
export {menu, MenuCategory};
