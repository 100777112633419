import React from "react";
import Home from "./scenes/home/Home";
import NavbarMenu from "./scenes/global/Navbar";
import FooterMenu from "./scenes/global/Footer";
import CardMenu from "./scenes/global/CartMenu";
import CartMenu from "./scenes/global/CartMenu";
import { Badge, Box, IconButton } from "@mui/material";

const menu = () => {
  return (
    <Box
      width={{ xs: "100%", md: "40%" }}
      margin="0 auto"
      style={{ color: "hsl(210, 22%, 49%)", background: "lightyellow" }}
    >
      <NavbarMenu />
      <Home />
      <CartMenu />
      <FooterMenu />
    </Box>
  );
};

export default menu;
