import { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import mediaApi from "../../api/modules/media.api";
import AutoSwiper from "./AutoSwiper";
import { toast } from "react-toastify";
import EndingItem from "./EndingItem";

const EndingSlide = ({medias}) => {
  // console.log(medias)
  // const [medias, setMedias] = useState([]);

  
  return (
    <AutoSwiper>
      {medias.map((media, index) => (
        <SwiperSlide key={index}>
          <EndingItem media={media}  />
        </SwiperSlide>
      ))}
    </AutoSwiper>
  );
};

export default EndingSlide;