import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uiConfigs from "../../configs/ui.configs";
import ImageSquareBestDeal from "./ImageSquare/ImageSquareBestDeal";

import { Box, Button, Chip, Divider, Stack } from "@mui/material";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SpaRounded } from "@mui/icons-material";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function RoyalItem({ royalItem }) {
  const navigate = useNavigate();
  // console.log({ royalItem });
  const { title, desc, images, location, _id, deal, views, category } =
    royalItem;
  // console.log({ _id });
  // console.log({images})

   const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card onClick={() => navigate(`/royal/${_id}`)}>
      {/* <CardMedia
        component="img"
        height="250"
        image={images[0]}
        alt="Paella dish"
      /> */}

      <ImageSquareBestDeal imagesBestDeal={images} />
      <CardHeader
        action={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m="8px"
            p="5px"
            sx={{
              backgroundColor: "primary.main",
              inlineSize: "90px",
              color: "primary.contrastText",
            }}
            borderRadius="3px"
          >
            <span style={{ fontSize: "0.6rem" }}>Best deal</span>
            <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>
              {deal}
            </span>
          </Box>
        }
        title={
          <Typography
            sx={{
              ...uiConfigs.style.typoLines(1, "left"),
              color: "secondary.contrastText",
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
        }
        subheader={
          <Stack gap={0}>
            <Button
              variant="text"
              sx={{ width: "max-content", color: "primary.contrastText" }}
              size="small"
              startIcon={<VisibilityTwoToneIcon />}
            >
              {views}
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "max-content", color: "primary.contrastText" }}
              size="small"
              // startIcon={<VisibilityTwoToneIcon />}
            >
              {category}
            </Button>
          </Stack>
        }
      />
    </Card>
  );
}

export default RoyalItem;
