import React, { useState } from "react";
import HeroSlide from "../components/common/HeroSlide";
import tmdbConfigs from "../api/configs/tmdb.configs";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import MediaSlide from "../components/common/MediaSlide";
import LandingPage from "./LandingPage/LandingPage";
import MediaGrid from "../components/common/MediaGrid";
import {
  endingdata,
  cannotmissdata,
  bestdeal,
  trendingdata,
} from "../components/common/data/datahero";
import { Box, Button, Stack, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import TextList from "./TextList";
import CitySlide from "../components/common/CitySlide";
import DealGrid from "../components/common/DealsGrid";
import EndingSlide from "../components/common/EndingSlide";
import HotBackdropSlide from "../components/common/HotBackdropSlide";
// import BestDealSlide from "../components/common/BestDealSlide";
import RoyalSlide from "../components/common/RoyalSlide";
import OperatorSlide from "../components/common/operator/OperatorSlide";
import Marqueetop from "../components/utils/marquee/marqueetop";
import Highlight from "../components/common/highlights/Highlight";
import MainQuotes from "../components/utils/quotes/MainQuotes";
import ContainerNegative from "../components/common/ContainerNegative";
import ImageHeaderSpecial from "../components/common/ImageHeaderSpecial";

import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import Tab from "@mui/material/Tab";
import Now from "../components/common/whaton/Now";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Whaton = () => {
  const ongoingevent = [
    {
      id: 1,
      title: "garara sale",
      img: "https://images.pexels.com/photos/4923440/pexels-photo-4923440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 2,
      title: "garara sale",
      img: "https://images.pexels.com/photos/1087727/pexels-photo-1087727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 3,
      title: "garara sale",
      img: "https://images.pexels.com/photos/10681880/pexels-photo-10681880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 4,
      title: "garara sale",
      img: "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 5,
      title: "car wash",
      img: "https://images.pexels.com/photos/3813551/pexels-photo-3813551.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 6,
      title: "garara sale",
      img: "https://images.pexels.com/photos/3307879/pexels-photo-3307879.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 7,
      title: "garara sale",
      img: "https://images.pexels.com/photos/14805547/pexels-photo-14805547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 8,
      title: "car wash",
      img: "https://images.pexels.com/photos/1557228/pexels-photo-1557228.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
  ];

  const upCommingevent = [
    {
      id: 1,
      title: "garara sale",
      img: "https://images.pexels.com/photos/4923440/pexels-photo-4923440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
    {
      id: 1,
      title: "garara sale",
      img: "https://images.pexels.com/photos/4923440/pexels-photo-4923440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      price: "$1 - $100",
      address: "23 swift street, belfast",
    },
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ImageHeaderSpecial
        imgPath={"/hero/lokalai24-25.jpg"}
        text="On-going Events"
      />
      <Box sx={{ ...uiConfigs.style.mainContent }} border={0}>
        <ContainerNegative>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                allowScrollButtonsMobile
                TabIndicatorProps={{
                  sx: { backgroundColor: "secondary.white" },
                }}
                sx={{
                  "& button": { borderRadius: "5px", color: "lightgrey", fontWeight: 100, textTransform:"capitalize" },
                  "& button:hover": {
                    backgroundColor: "transparent",
                    border: "1px solid primary.white",
                    fontWeight: 700,
                  },
                  "& button:focus": {
                    color: "secondary.white",
                    fontWeight: 700,
                  },
                  "& button.Mui-selected": { color: "secondary.white",fontWeight: 900,textTransform:"uppercase", border: "1px solid white" },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { display: "none" },
                  },
                }}
              >
                <Tab label="Now" {...a11yProps(0)} />
                <Tab label="Up comming" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Now data={ongoingevent} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              Up comming
            </CustomTabPanel>
          </Box>
        </ContainerNegative>
      </Box>
    </>
  );
};

export default Whaton;
