const listtext = [
  {
    id: 1,
    title: "Activities, Events & Outdoors",
    category: "Today",
    price: 9.5,
    img: "./images/item-1.jpeg",
    desc: `257 products and services`,
  },
  {
    id: 2,
    title: "Automotive Parts & Accessories",
    category: "Today",
    price: 9.5,
    img: "./images/item-2.jpeg",
    desc: `400 products and services`,
  },
  {
    id: 3,
    title: "Baby, Kids & Toys",
    category: "Today",
    price: 9.5,
    img: "./images/item-3.jpeg",
    desc: `115 products and services`,
  },
  {
    id: 4,
    title: "Beauty, Massage & Spa",
    category: "Today",
    price: 9.5,
    img: "./images/item-3.jpeg",
    desc: `345 products and services`,
  },
  {
    id: 5,
    title: "Books & Magazines",
    category: "Today",
    price: 9.5,
    img: "./images/item-3.jpeg",
    desc: `659 products and services`,
  },
  {
    id: 6,
    title: "Clothing & Fashion - Men",
    category: "50% off",
    price: 14.0,
    img: "./images/item-3.jpeg",
    desc: `215 products and services`,
  },
  {
    id: 7,
    title: "Electronics & Computers",
    category: "50% off",
    price: 14.0,
    img: "./images/item-3.jpeg",
    desc: `369 products and services`,
  },
  {
    id: 8,
    title: "Fitness & Sports",
    category: "50% off",
    price: 13.0,
    img: "./images/item-3.jpeg",
    desc: `124 products and services`,
  },
  {
    id: 9,
    title: "Grocery & Food",
    category: "50% off",
    price: 17.0,
    img: "./images/item-3.jpeg",
    desc: `569 products and services`,
  },
  {
    id: 10,
    title: "Health, Nutrition & Dental",
    category: "50% off",
    price: 14.0,
    img: "./images/item-3.jpeg",
    desc: `401 products and services`,
  },
  {
    id: 11,
    title: "Holidays, Hotels & Travel",
    category: "50% off",
    price: 14.0,
    img: "./images/item-3.jpeg",
    desc: `896 products and services`,
  },
  {
    id: 12,
    title: "House & Garden",
    category: "50% off",
    price: 14.0,
    img: "./images/item-3.jpeg",
    desc: `563 products and services`,
  },
  {
    id: 13,
    title: "Cats Supplies & Services",
    category: "Most views",
    price: 23.0,
    img: "./images/item-13.jpeg",
    desc: `533 products and services`,
  },
  {
    id: 14,
    title: "Fish, Birds & Small Animals",
    category: "Most views",
    price: 18.5,
    img: "./images/item-13.jpeg",
    desc: `772 products and services`,
  },
  {
    id: 15,
    title: "Travel Accessories & Luggage",
    category: "Most views",
    price: 19.5,
    img: "./images/item-13.jpeg",
    desc: `445 products and services`,
  },
  {
    id: 16,
    title: "Flights, Travel Packages & Cruises",
    category: "Most views",
    price: 17.5,
    img: "./images/item-13.jpeg",
    desc: `556 products and services`,
  },
  {
    id: 17,
    title: "Home Appliances",
    category: "Most views",
    price: 17.5,
    img: "./images/item-13.jpeg",
    desc: `789 products and services`,
  },
  {
    id: 18,
    title: "Outdoor Furniture",
    category: "Most views",
    price: 17.5,
    img: "./images/item-18.jpeg",
    desc: `215 products and services`,
  },
  {
    id: 19,
    title: "Pools & Accessories",
    category: "Most views",
    price: 17.5,
    img: "./images/item-18.jpeg",
    desc: `563 products and services`,
  },
  {
    id: 20,
    title: "Storage & Organisation",
    category: "Most views",
    price: 17.5,
    img: "./images/item-18.jpeg",
    desc: `478 products and services`,
  },
  {
    id: 21,
    title: "Restaurants, Bars, Cafes",
    category: "Most views",
    price: 17.5,
    img: "./images/item-18.jpeg",
    desc: `598 products and services`,
  },
  {
    id: 22,
    title: "Cafes, Deli & Fast Food",
    category: "Popular",
    price: 16.0,
    img: "./images/item-18.jpeg",
    desc: `563 products and services`,
  },
  {
    id: 23,
    title: "Art Supplies",
    category: "Popular",
    price: 16.5,
    img: "./images/item-18.jpeg",
    desc: `112 products and services`,
  },
  {
    id: 24,
    title: "Colouring & Scrapbooking",
    category: "Popular",
    price: 17.5,
    img: "./images/item-24.jpeg",
    desc: `225 products and services`,
  },
  {
    id: 25,
    title: "General Stationery",
    category: "Popular",
    price: 19.5,
    img: "./images/item-24.jpeg",
    desc: `441 products and services`,
  },
  {
    id: 26,
    title: "School Supplies",
    category: "Popular",
    price: 17.5,
    img: "./images/item-24.jpeg",
    desc: `25 products and services`,
  },
  {
    id: 27,
    title: "Art & Design Classes Classes",
    category: "Popular",
    price: 17.5,
    img: "./images/item-27.jpeg",
    desc: `23 products and services`,
  },
  {
    id: 28,
    title: "Lifestyle & Cooking Classes",
    category: "Popular",
    price: 19.5,
    img: "./images/item-27.jpeg",
    desc: `56 products and services`,
  },
  {
    id: 29,
    title: "Event Packages",
    category: "Popular",
    price: 19.5,
    img: "./images/item-27.jpeg",
    desc: `896 products and services`,
  },
  {
    id: 30,
    title: "Flowers & Gift Baskets",
    category: "Popular",
    price: 18.5,
    img: "./images/item-30.jpeg",
    desc: `234 products and services`,
  },
  {
    id: 31,
    title: "Party Supplies",
    category: "Popular",
    price: 19.5,
    img: "./images/item-30.jpeg",
    desc: `563 products and services`,
  },
  {
    id: 32,
    title: "Venue & Limo Hire",
    category: "Latest",
    price: 18.0,
    img: "./images/item-30.jpeg",
    desc: `15 products and services`,
  },
  {
    id: 33,
    title: "Beer & Cider",
    category: "Latest",
    price: 19.5,
    img: "./images/item-30.jpeg",
    desc: `124 products and services`,
  },
  {
    id: 34,
    title: "Wine",
    category: "Latest",
    price: 18.0,
    img: "./images/item-30.jpeg",
    desc: `65 products and services`,
  },
  {
    id: 35,
    title: "Special",
    category: "Latest",
    price: 17.5,
    img: "./images/item-35.jpeg",
    desc: `12 products and services`,
  },
  {
    id: 36,
    title: "Kids Activities",
    category: "Latest",
    price: 19.5,
    img: "./images/item-35.jpeg",
    desc: `89 products and services`,
  },
  {
    id: 37,
    title: "Fun & Leisure",
    category: "Latest",
    price: 19.5,
    img: "./images/item-35.jpeg",
    desc: `214 products and services`,
  },
  {
    id: 38,
    title: "Wash, Repairs & Maintenance",
    category: "Latest",
    price: 19.5,
    img: "./images/item-35.jpeg",
    desc: `635 products and services`,
  },
  {
    id: 39,
    title: "Hair Salons & Products",
    category: "Latest",
    price: 18.5,
    img: "./images/item-40.jpeg",
    desc: `265 products and services`,
  },
  {
    id: 40,
    title: "Tattoos & Piercing",
    category: "Latest",
    price: 18.5,
    img: "./images/item-40.jpeg",
    desc: `69 products and services`,
  },
];

const newzealand = [
  {
    id: 1,
    title: "Auckland",
    category: "newzealand",
    price: 9.5,
    img: "https://images.pexels.com/photos/7250790/pexels-photo-7250790.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "",
  },
  {
    id: 2,
    title: "Christchuch",
    category: "newzealand",
    price: 9.5,
    img: "https://images.pexels.com/photos/5363175/pexels-photo-5363175.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "",
  },
  {
    id: 3,
    title: "Wellington",
    category: "newzealand",
    price: 9.5,
    img: "https://images.pexels.com/photos/8379417/pexels-photo-8379417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    desc: "",
  },
  {
    id: 4,
    title: "Hamilton",
    category: "newzealand",
    price: 9.5,
    img: "https://pyt-blogs.imgix.net/2020/06/mike-benna-WHHY-iBp3aI-unsplash-1-scaled.jpg?auto=format&fit=crop&h=400&ixlib=php-3.3.0&w=1500&wpsize=gridlove-cover",
    desc: "",
  },
  {
    id: 5,
    title: "Tauranga",
    category: "newzealand",
    price: 9.5,
    img: "https://wallpapers.com/images/file/tauranga-city-new-zealand-nirkje22giumza4e.jpg",
    desc: "",
  },
  {
    id: 6,
    title: "Lower Hutt",
    category: "newzealand",
    price: 9.5,
    img: "https://c0.wallpaperflare.com/preview/355/249/259/new-zealand-lower-hutt-petone-beach.jpg",
    desc: "",
  },
  {
    id: 7,
    title: "Dunedin",
    category: "newzealand",
    price: 9.5,
    img: "https://c.wallhere.com/photos/3c/5a/landscape_building_city_dunedin-1559027.jpg!d",
    desc: "",
  },
  {
    id: 8,
    title: "Palmerston North",
    category: "newzealand",
    price: 9.5,
    img: "https://cdn.britannica.com/83/143883-050-8DA50FAF/Palmerston-North-Island-NZ.jpg",
    desc: "",
  },
  {
    id: 9,
    title: "Napier",
    category: "newzealand",
    price: 9.5,
    img: "https://content.r9cdn.net/rimg/dimg/f8/a7/c5520467-city-9510-16d90986e41.jpg?crop=true&width=1366&height=768&xhint=1565&yhint=2189",
    desc: "",
  },
  {
    id: 10,
    title: "Porirua",
    category: "newzealand",
    price: 9.5,
    img: "https://itbrief.co.nz/uploads/story/2019/05/15/compatible_GettyImages-470429662.jpg",
    desc: "",
  },
  {
    id: 11,
    title: "Hibiscus Coast",
    category: "newzealand",
    price: 9.5,
    img: "https://mediaim.expedia.com/destination/1/e2689df5f22749685d2efd2d31bf7d80.jpg",
    desc: "",
  },
  {
    id: 12,
    title: "New Plymouth",
    category: "newzealand",
    price: 9.5,
    img: "https://www.neverendingvoyage.com/wp-content/uploads/2020/10/main-mt-taranaki-reflection-pouakai-tarn.jpg",
    desc: "",
  },
  {
    id: 13,
    title: "Rotorua",
    category: "newzealand",
    price: 9.5,
    img: "https://cdn.britannica.com/47/154247-050-72010A09/Geysers-area-Wai-O-Tapu-New-Zealand-Rotorua.jpg",
    desc: "",
  },
  {
    id: 14,
    title: "Whangārei",
    category: "newzealand",
    price: 9.5,
    img: "https://needabreak.com/cms/wp-content/uploads/2021/03/Why-Whang%C4%81rei-should-be-on-your-North-Island-itinerary.-Image-Bigstock.jpg",
    desc: "",
  },
  {
    id: 15,
    title: "Nelson",
    category: "newzealand",
    price: 9.5,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS384knwmpKd9UfvhE00MtM6Od6ywcFR0HAo6ISXplvAAWh0O163HBo4QwA4lZMUXMmMF0&usqp=CAU",
    desc: "",
  },
  {
    id: 16,
    title: "Hastings",
    category: "newzealand",
    price: 9.5,
    img: "https://cdn.britannica.com/52/156752-050-9DE35947/Clock-tower-city-centre-Hastings-New-Zealand.jpg",
    desc: "",
  },
  {
    id: 17,
    title: "Invercargill",
    category: "newzealand",
    price: 9.5,
    img: "https://api.whatsoninvers.nz/wp-content/uploads/2021/08/Feldwick-gates-at-Queens-Park.jpg",
    desc: "",
  },
  {
    id: 18,
    title: "Upper Hutt",
    category: "newzealand",
    price: 9.5,
    img: "https://resources.stuff.co.nz/content/dam/images/2/4/j/q/f/f/image.related.StuffLandscapeSixteenByNine.1420x800.24jqg0.png/1661901162481.jpg",
    desc: "",
  },
  {
    id: 19,
    title: "Whanganui",
    category: "newzealand",
    price: 9.5,
    img: "https://discoverwhanganui.nz/wp-content/uploads/2021/04/Waimarie-Paddle-Steamer-on-Whanganui-River-scaled.jpeg",
    desc: "",
  },
  {
    id: 20,
    title: "	Gisborne",
    category: "newzealand",
    price: 9.5,
    img: "https://resources.stuff.co.nz/content/dam/images/1/t/8/0/f/b/image.related.StuffLandscapeSixteenByNine.1420x800.1t7pzl.png/1545951853882.jpg",
    desc: "",
  },
];

export { listtext, newzealand };
