import React from "react";
import "./Loading3.css";

const Loading3 = () => {
  return (
    <section class="sec-loading">
      <div class="one"></div>
      <h1 style={{marginTop:"1rem"}}>Lokalai loading ... </h1>
    </section>
  );
};

export default Loading3;
