import React from "react";
import { FiCoffee } from "react-icons/fi";
import { GiCoffeeCup } from "react-icons/gi";
import "./stamps.css";
import Box from "@mui/material/Box";

import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";

const stamps = (props) => {
  const numberOfStamps = props.stampsselected;
  const pickImg = props.pickImg;
  const pickunImg = props.pickunImg;
  const colorStamps = props.stampedBackground;
  const colorunStamps = props.unstampedBackground;
  const stampCount = props.stampCount;
  const bonus1="10% off"
  const bonus2="1 free"
  const bonus3="20% off"
  const bonus4="2 free"
  return (
    <div>
      {/* ----------------5 stamps------------------- */}
      {numberOfStamps === 5 ? (
        <>
          <div className="stamped5U">
            {stampCount < 3 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped5B">
            {stampCount < 5 ? (
              <>
                {[0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 3)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(1)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 1) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus2}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      ) : null}
      {/* ----------------6 stamps------------------- */}
      {numberOfStamps === 6 ? (
        <Box>
          <div className="stamped3">
            {stampCount < 3 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped3">
            {stampCount < 6 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 3)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">1 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />1 free
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ----------------7 stamps------------------- */}
      {numberOfStamps === 7 ? (
        <Box>
          <div className="stamped7U">
            {stampCount < 4 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">10% off</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 3) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            10% off
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped7B">
            {stampCount < 7 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 4)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">1 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 5)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />1 free
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ----------------8 stamps------------------- */}
      {numberOfStamps === 8 ? (
        <Box>
          <div className="stamped4">
            {stampCount < 4 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">10% off</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 3) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            10% off
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped4">
            {stampCount < 8 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 4)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">1 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                <>
                  {[0, 0, 0, 0].map((el, i) => (
                    <div>
                      {(() => {
                        if (i < Math.floor(stampCount - 5)) {
                          return (
                            <div
                              className="icon-loyal-fill-3"
                              style={{ background: colorStamps }}
                            >
                              <img src={pickImg} width={40} />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="icon-loyal-3"
                              style={{
                                background: colorStamps,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img src={pickImg} width={35} />1 free
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ))}
                </>
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ----------------9 stamps type 1------------------- */}
      {numberOfStamps === 91 ? (
        <Box>
          <div className="stamped4">
            {[0, 0, 0, 0, 0].map((el, i) => (
              <div>
                {(() => {
                  if (i < Math.floor(stampCount)) {
                    return (
                      <div
                        className="icon-loyal-fill-sm"
                        style={{ background: colorStamps }}
                      >
                        <img src={pickImg} width={40} />
                      </div>
                    );
                  } else if (i < Math.floor(4)) {
                    return (
                      <div
                        className="icon-loyal"
                        style={{ background: colorunStamps }}
                      >
                        {/* <img src={pickunImg} /> */}
                      </div>
                    );
                  } else {
                    return <div className="bonus_sm">10% off</div>;
                  }
                })()}
              </div>
            ))}
          </div>
          <div className="stamped3">
            {[0, 0, 0, 0].map((el, i) => (
              <div>
                {(() => {
                  if (i < Math.floor(stampCount)) {
                    return (
                      <div
                        className="icon-loyal-fill-sm"
                        style={{ background: colorStamps }}
                      >
                        <img src={pickImg} width={40} />
                      </div>
                    );
                  } else if (i < Math.floor(3)) {
                    return (
                      <div
                        className="icon-loyal"
                        style={{ background: colorunStamps }}
                      >
                        {/* <img src={pickunImg} /> */}
                      </div>
                    );
                  } else {
                    return <div className="bonus_sm">1 free</div>;
                  }
                })()}
              </div>
            ))}
          </div>
        </Box>
      ) : null}
      {/* ----------------9 stamps type 2------------------- */}
      {numberOfStamps === 9 ? (
        <Box>
          <div className="stamped92">
            {stampCount < 3 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">10% off</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            10% off
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped92">
            {stampCount < 6 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 3)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">1 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />1 free
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped92">
            {stampCount < 9 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 6)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">2 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />2 free
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ------------------------------------------- */}
      {numberOfStamps === 10 ? (
        <>
          <div className="stamped4">
            {stampCount < 5 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">10% off</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            10% off
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped4">
          {stampCount < 10 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 5)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">1 free</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            1 free
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      ) : null}
      {/* ----------------12 stamps------------------- */}
      {numberOfStamps === 12 ? (
        <Box>
          <div className="stamped12">
          {stampCount < 3 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped12">
          {stampCount < 6 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 3)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />{bonus2}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped12">
          {stampCount < 9 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 6)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus3}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />{bonus3}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped12">
          {stampCount < 12 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 9)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus">{bonus4}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />{bonus4}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ----------------14 stamps------------------- */}
      {numberOfStamps === 14 ? (
        <Box>
          <div className="stamped14U">
          {stampCount < 4 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 3) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped14B">
          {stampCount < 7 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 4)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />{bonus2}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped14U">
          {stampCount < 11 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount-7)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus3}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 3) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus3}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped14B">
          {stampCount < 14 ? (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 11)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(2)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus4}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 2) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />{bonus4}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* -----------------15 stamps------------------------ */}
      {numberOfStamps === 15 ? (
        <>
          <div className="stamped15">
          {stampCount < 5 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped15">
          {stampCount < 10 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 5)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus2}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped15">
          {stampCount < 15 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 10)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus3}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus3}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      ) : null}
      {/* ----------------16 stamps------------------- */}
      {numberOfStamps === 16 ? (
        <Box>
          <div className="stamped16">
          {stampCount < 4 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 3) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped16">
          {stampCount < 8 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 4)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                <>
                  {[0, 0, 0, 0].map((el, i) => (
                    <div>
                      {(() => {
                        if (i < 3) {
                          return (
                            <div
                              className="icon-loyal-fill-3"
                              style={{ background: colorStamps }}
                            >
                              <img src={pickImg} width={40} />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="icon-loyal-3"
                              style={{
                                background: colorStamps,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img src={pickImg} width={35} />{bonus2}
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ))}
                </>
              </>
            )}
          </div>
          <div className="stamped16">
          {stampCount < 12 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 8)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus3}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                <>
                  {[0, 0, 0, 0].map((el, i) => (
                    <div>
                      {(() => {
                        if (i < 3) {
                          return (
                            <div
                              className="icon-loyal-fill-3"
                              style={{ background: colorStamps }}
                            >
                              <img src={pickImg} width={40} />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="icon-loyal-3"
                              style={{
                                background: colorStamps,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img src={pickImg} width={35} />{bonus3}
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ))}
                </>
              </>
            )}
          </div>
          <div className="stamped16">
          {stampCount < 16 ? (
              <>
                {[0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 12)) {
                        return (
                          <div
                            className="icon-loyal-fill-3"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(3)) {
                        return (
                          <div
                            className="icon-loyal-3"
                            style={{ background: colorunStamps }}
                          >
                            {/* <img src={pickunImg} /> */}
                          </div>
                        );
                      } else {
                        return <div className="bonus">{bonus4}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                <>
                  {[0, 0, 0, 0].map((el, i) => (
                    <div>
                      {(() => {
                        if (i < 3) {
                          return (
                            <div
                              className="icon-loyal-fill-3"
                              style={{ background: colorStamps }}
                            >
                              <img src={pickImg} width={40} />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="icon-loyal-3"
                              style={{
                                background: colorStamps,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img src={pickImg} width={35} />{bonus4}
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ))}
                </>
              </>
            )}
          </div>
        </Box>
      ) : null}
      {/* ------------------------------------------- */}
      {numberOfStamps === 20 ? (
        <>
          <div className="stamped20">
          {stampCount < 5 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus1}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus1}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped20">
          {stampCount < 10 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 5)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus2}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus2}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped20">
          {stampCount < 15 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 10)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus3}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus3}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="stamped20">
          {stampCount < 20 ? (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < Math.floor(stampCount - 15)) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else if (i < Math.floor(4)) {
                        return (
                          <div
                            className="icon-loyal-unStamp"
                            style={{ background: colorunStamps }}
                          ></div>
                        );
                      } else {
                        return <div className="bonus_sm">{bonus4}</div>;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <>
                {[0, 0, 0, 0, 0].map((el, i) => (
                  <div>
                    {(() => {
                      if (i < 4) {
                        return (
                          <div
                            className="icon-loyal-fill-sm"
                            style={{ background: colorStamps }}
                          >
                            <img src={pickImg} width={40} />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="icon-loyal-3-sm"
                            style={{
                              background: colorStamps,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img src={pickImg} width={35} />
                            {bonus4}
                          </div>
                        );
                      }
                    })()}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default stamps;
