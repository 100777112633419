import React, { useState } from "react";
import HeroSlide from "../components/common/HeroSlide";
import tmdbConfigs from "../api/configs/tmdb.configs";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import MediaSlide from "../components/common/MediaSlide";
import LandingPage from "./LandingPage/LandingPage";
import MediaGrid from "../components/common/MediaGrid";
import {
  endingdata,
  cannotmissdata,
  bestdeal,
  trendingdata,
} from "../components/common/data/datahero";
import { Box, Button, Stack, Typography } from "@mui/material";
import { borderBottom } from "@mui/system";
import TextList from "./TextList";
import CitySlide from "../components/common/CitySlide";
import DealGrid from "../components/common/DealsGrid";
import EndingSlide from "../components/common/EndingSlide";
import HotBackdropSlide from "../components/common/HotBackdropSlide";
// import BestDealSlide from "../components/common/BestDealSlide";
import RoyalSlide from "../components/common/RoyalSlide";
import OperatorSlide from "../components/common/operator/OperatorSlide";
import Marqueetop from "../components/utils/marquee/marqueetop";
import Highlight from "../components/common/highlights/Highlight";
import MainQuotes from "../components/utils/quotes/MainQuotes";
import ContainerNegative from "../components/common/ContainerNegative";
import ImageHeaderSpecial from "../components/common/ImageHeaderSpecial";

const Discover = () => {
   return (
<>
<ImageHeaderSpecial imgPath={"/hero/red1.jpg"} text="Discover" />
      <Box sx={{ ...uiConfigs.style.mainContent }} border={0}>
      </Box>

</>
  );
};

export default Discover;
