import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Divider, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import userApi from "../../api/modules/user.api";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setUser } from "../../redux/features/userSlice";
import { RxAvatar } from "react-icons/rx";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { grey } from "@mui/material/colors";

const SignupForm = ({ switchAuthState }) => {
  const dispatch = useDispatch();

  // const cloud_name = process.env.REACT_APP_CLOUD_NAME;
  const cloud_name = "dg89sorey";
  const upload_preset = "x4mfna2o";

  // const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;

  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [avatar, setAvatar] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const uploadCloudImage = async (props) => {
    // console.log({props})
    const profileImage = props;
    // console.log({profileImage})
    let imageURL;
    try {
      if (
        profileImage !== null &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", cloud_name);
        image.append("upload_preset", upload_preset);

        console.log({ image });

        // Save image to Cloudinary
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dg89sorey/image/upload",
          { method: "post", body: image }
        );
        const imgData = await response.json();
        toast.success("upload image to cloudinary successfully");
        console.log(imgData);
        imageURL = imgData.url.toString();
        console.log({ imageURL });
        signinForm.values.photo = imageURL;
        setImagePreview(imageURL);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
    // signinForm.values.photo = URL.createObjectURL(avatar);
    console.log({ file });
    console.log({ avatar });
    setProfileImage(e.target.files[0]);
    uploadCloudImage(e.target.files[0]);
  };

  const signinForm = useFormik({
    initialValues: {
      password: "",
      username: "",
      displayName: "",
      confirmPassword: "",
      photo: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "username minimum 3 characters")
        .required("username is required"),
      password: Yup.string()
        .min(3, "password minimum 3 characters")
        .required("password is required"),
      displayName: Yup.string()
        .min(3, "displayName minimum 3 characters")
        .required("displayName is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "confirmPassword not match")
        .min(3, "confirmPassword minimum 3 characters")
        .required("confirmPassword is required"),
      photo: Yup.string(),
    }),

    onSubmit: async (values) => {
      if (!values.photo) return toast.error("please wait for uploading avatar");
      console.log({ values });
      setErrorMessage(undefined);
      setIsLoginRequest(true);
      console.log("asdasdasdasd");
      const { response, err } = await userApi.signup(values);
      setIsLoginRequest(false);

      if (response) {
        signinForm.resetForm();
        dispatch(setUser(response));
        dispatch(setAuthModalOpen(false));
        toast.success("Sign in success");
      }

      if (err) setErrorMessage(err.message);
    },
  });

  return (
    <Box component="form" onSubmit={signinForm.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          type="text"
          placeholder="e-mail"
          name="username"
          fullWidth
          value={signinForm.values.username}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.username &&
            signinForm.errors.username !== undefined
          }
          helperText={signinForm.touched.username && signinForm.errors.username}
        />
        <TextField
          type="text"
          placeholder="display name"
          name="displayName"
          fullWidth
          value={signinForm.values.displayName}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.displayName &&
            signinForm.errors.displayName !== undefined
          }
          helperText={
            signinForm.touched.displayName && signinForm.errors.displayName
          }
        />
        <TextField
          type="password"
          placeholder="password"
          name="password"
          fullWidth
          value={signinForm.values.password}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.password &&
            signinForm.errors.password !== undefined
          }
          helperText={signinForm.touched.password && signinForm.errors.password}
        />
        <TextField
          type="password"
          placeholder="confirm password"
          name="confirmPassword"
          fullWidth
          value={signinForm.values.confirmPassword}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.confirmPassword &&
            signinForm.errors.confirmPassword !== undefined
          }
          helperText={
            signinForm.touched.confirmPassword &&
            signinForm.errors.confirmPassword
          }
        />
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 2 }}>          
          <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
            {avatar ? (
              <img
                src={URL.createObjectURL(avatar)}
                alt="avatar"
                className="h-full w-full object-cover rounded-full"
                width={80}
              />
            ) : (
              <AccountBoxOutlinedIcon />
            )}
          </span>
          <label
            htmlFor="file-input"
            style={{ display: "flex" }}
            className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <input
              type="file"
              name="avatar"
              id="file-input"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileInputChange}
              className="sr-only"
            />
          </label>
        </Box>
      </Stack>

      <LoadingButton
        type="submit"
        fullWidth
        size="large"
        variant="contained"
        sx={{
          marginTop: 4,
          color: "primary.black",
          borderRadius: "5px",
          bgcolor: `${grey[300]}`,
          "&:hover": {
            backgroundColor: `${grey[500]}`,
            boxShadow: "none",
          },
        }}
        loading={isLoginRequest}
      >
        sign up
      </LoadingButton>
      <Button
        fullWidth
        sx={{
          marginTop: 2,
          color: "inherit",
          borderTop: 1,
          borderColor:"divider",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: `${grey[300]}`,
            boxShadow: "none",
          },
        }}
        onClick={() => switchAuthState()}
      >
        sign in
      </Button>

      {errorMessage && (
        <Box sx={{ marginTop: 2 }}>
          <Alert severity="error" variant="outlined">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default SignupForm;
