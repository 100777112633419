import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import Container from "../components/common/Container";
import { format } from "timeago.js";
import axios from "axios";
import uiConfigs from "../configs/ui.configs";
import { baseURL } from "../api/client/URL";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SendIcon from "@mui/icons-material/Send";
import CollectionsIcon from "@mui/icons-material/Collections";
import "./chat.css";

import useTyping from "../components/common/messageTyping/hooks/useTyping";
import useOutsideClick from "../components/common/messageTyping/hooks/useOutsideClick";
import TypingMessage from "../components/common/messageTyping/TypingMessage";
import emojiIcon from "../components/common/messageTyping/emojiIcon.png";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const UserInbox = ({ socketId }) => {
  // window.scrollTo(0, 0);
  const { user } = useSelector((state) => state.user);
  // console.log({ user });
  // console.log(socketId)

  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [images, setImages] = useState();
  const [activeStatus, setActiveStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);
  const [typingUsers, setTypingUsers] = useState([]);
  const { isTyping, startTyping, stopTyping, cancelTyping } = useTyping();
  const [receiver, setReceiver] = useState();

  useEffect(() => {
    socketId.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });

    socketId.on("startTypingMessage", (typingInfo) => {
      // console.log("startTypingMessage", typingInfo)
      const user = typingInfo;
      setTypingUsers((users) => [...users, user]);
      // }
    });

    socketId.on("stopTypingMessage", (typingInfo) => {
      // console.log("stopTypingMessage", typingInfo)
      const user = typingInfo;
      setTypingUsers((users) => users.filter((u) => u !== user));
    });
  }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const response = await axios.get(
          `${baseURL}api/chat/get-all-conversation-user/${user.id}`
        );

        setConversations(response.data.conversations);
        // console.log({ conversations });
      } catch (error) {
        console.log(error);
      }
    };
    getConversation();
  }, [user, messages]);

  useEffect(() => {
    if (user) {
      const sellerId = user?.id;
      socketId.emit("addUser", sellerId);
      socketId.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [user]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== user?.id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  // get messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axios.get(
          `${baseURL}api/message/get-all-messages/${currentChat?._id}`
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, [currentChat]);

  const sendMessageHandler = async (e) => {
    e.preventDefault();
    cancelTyping();
    // console.log("user in new message",user)

    const message = {
      sender: user.id,
      text: newMessage,
      conversationId: currentChat._id,
    };
    const receiverId = currentChat.members.find(
      (member) => member !== user?.id
    );

    socketId.emit("sendMessage", {
      senderId: user?.id,
      receiverId,
      text: newMessage,
    });

    //send notification from dashboard to socket server
    socketId.emit("sendNotification", {
      senderId: user?.id,
      senderName: user?.displayName,
      receiverId,
      type: 3,
    });

    try {
      if (newMessage !== "") {
        await axios
          .post(`${baseURL}api/message/create-new-message`, message)
          .then((res) => {
            setMessages([...messages, res.data.message]);
            updateLastMessage();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessage = async () => {
    socketId.emit("updateLastMessage", {
      lastMessage: newMessage,
      lastMessageId: user.id,
    });

    await axios
      .put(`${baseURL}api/chat/update-last-message/${currentChat._id}`, {
        lastMessage: newMessage,
        lastMessageId: user._id,
      })
      .then((res) => {
        setNewMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImages(file);
    imageSendingHandler(file);
  };

  const imageSendingHandler = async (e) => {
    const formData = new FormData();

    formData.append("images", e);
    formData.append("sender", user._id);
    formData.append("text", newMessage);
    formData.append("conversationId", currentChat._id);

    const receiverId = currentChat.members.find((member) => member !== user.id);

    socketId.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      images: e,
    });

    // try {
    //   await axios
    //     .post(`${server}/message/create-new-message`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((res) => {
    //       setImages();
    //       setMessages([...messages, res.data.message]);
    //       updateLastMessageForImage();
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const updateLastMessageForImage = async () => {
    // await axios.put(
    //   `${server}/conversation/update-last-message/${currentChat._id}`,
    //   {
    //     lastMessage: "Photo",
    //     lastMessageId: user._id,
    //   }
    // );
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ beahaviour: "smooth" });
  }, [messages]);

  // listenning an event
  const startTypingMessage = () => {
    console.log("startTypingMessage work");
    if (!currentChat) return;
    const receiverId = currentChat.members.find((member) => member !== user.id);
    console.log({ receiverId });
    // if (!socketId.current) return;
    socketId.emit("startTypingMessage", {
      senderId: user?.id,
      sendername: user.displayName,
      receiverId,
    });
  };

  const stopTypingMessage = () => {
    console.log("stopTypingMessage work");
    if (!currentChat) return;
    const receiverId = currentChat.members.find((member) => member !== user.id);

    console.log({ receiverId });
    // if (!socketId) return;
    socketId.emit("stopTypingMessage", {
      senderId: user?.id,
      sendername: user.displayName,
      receiverId,
    });
  };

  useEffect(() => {
    console.log("istyping status", isTyping);
    if (isTyping) startTypingMessage();
    else stopTypingMessage();
  }, [isTyping]);

  return (
    <Box
      sx={{
        ...uiConfigs.style.mainContent,
        padding: "1rem",
        marginTop: "6rem",
      }}
    >
      <Container marginTop="4rem" header="all messages">
        {!open && (
          <>
            {/* All messages list */}
            {conversations &&
              conversations.map((item, index) => (
                <MessageList
                  data={item}
                  key={index}
                  index={index}
                  setOpen={setOpen}
                  setCurrentChat={setCurrentChat}
                  me={user?.id}
                  setUserData={setUserData}
                  userData={userData}
                  online={onlineCheck(item)}
                  setActiveStatus={setActiveStatus}
                />
              ))}
          </>
        )}

        {open && (
          <SellerInbox
            setOpen={setOpen}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            sendMessageHandler={sendMessageHandler}
            messages={messages}
            sellerId={user.id}
            userData={userData}
            activeStatus={activeStatus}
            scrollRef={scrollRef}
            handleImageUpload={handleImageUpload}
            typingUsers={typingUsers}
            handleStartTyping={startTyping}
            handleStopTyping={stopTyping}
          />
        )}
      </Container>
    </Box>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  userData,
  online,
  setActiveStatus,
}) => {
  const [active, setActive] = useState(0);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const handleClick = (id) => {
    // navigate(`/inbox?${id}`);
    setOpen(true);
  };

  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user != me);
    // console.log({userId})
    let variable = { operator: userId };

    const getUser = async () => {
      try {
        const res = await axios.post(
          `${baseURL}api/products/operatorInfo`,
          variable
        );

        setUser(res.data.operator[0]);
        // console.log({user})
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  return (
    <Box
      display="flex"
      margin={3}
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
      sx={{
        bgcolor: `${active === index ? "lightgray" : "transparent"}`,
        cursor: "pointer",
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Box position="relative">
        {/* {console.log("operator :", user)} */}
        <Avatar src={user.photo}></Avatar>
        {online ? (
          <Box
            position="absolute"
            width={12}
            height={12}
            top={-2}
            right={-2}
            sx={{ bgcolor: "#66BB6A", borderRadius: "50%" }}
          ></Box>
        ) : (
          <Box
            position="absolute"
            width={12}
            height={12}
            top={-2}
            right={-2}
            sx={{ bgcolor: "#808080", borderRadius: "50%" }}
          ></Box>
        )}
      </Box>
      <Box marginLeft={2}>
        <Typography fontSize={16} variant={"h6"}>
          {user.name}
        </Typography>
        <Typography fontSize={12} color={"#000c"}>
          {data?.lastMessageId !== user[0]?.id ? `${user.name} : ` : "You: "}
          {data?.lastMessage}
        </Typography>
      </Box>
    </Box>
  );
};

const SellerInbox = ({
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  scrollRef,
  handleImageUpload,
  typingUsers,
  handleStartTyping,
  handleStopTyping,
}) => {
  //   const handleStartTyping = ()=> {
  //   console.log("Keyboard has pressed")
  // }

  const { showEmoji, setShowEmoji, ref } = useOutsideClick(false);
  const handleEmojiShow = () => {
    setShowEmoji((v) => !v);
  };
  const handleEmojiSelect = (e) => {
    setNewMessage((newMessage) => (newMessage += e.native));
  };
  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ minHeight: "50vh" }}
    >
      {/* Header Conversation */}
      {/* {console.log({ userData })} */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "rgb(226,232,240)",
        }}
      >
        <Box display="flex">
          <Avatar src="https://images.pexels.com/photos/16971540/pexels-photo-16971540/free-photo-of-woman-standing-with-gray-horse.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"></Avatar>
          <Box marginLeft={2}>
            <Typography variant="h6" fontSize={14}>
              {userData.name}
            </Typography>
            <Typography variant="p" fontSize={12}>
              {activeStatus ? "Active Now" : ""}
            </Typography>
          </Box>
        </Box>
        <ArrowForwardIcon
          onClick={() => setOpen(false)}
          sx={{ cursor: "pointer" }}
        />
      </Box>
      {/* Chat window */}

      <div
        className="message-area"
        style={{ background: "rgb(248, 250, 252)" }}
      >
        {/* {console.log({ messages })} */}
        {messages &&
          messages.map((item, index) => {
            return (
              <Box
                display={"flex"}
                m={0}
                sx={{
                  justifyContent: `${
                    item.sender === sellerId ? "end" : "start"
                  }`,
                }}
                ref={scrollRef}
              >
                {item.sender !== sellerId && (
                  <Avatar src={`${userData?.photo}`} alt="" />
                )}
                {/* {
            item.images && (
              <img
                 src={`${backend_url}${item.images}`}
                 className="w-[300px] h-[300px] object-cover rounded-[10px] mr-2"
              />
            )
          } */}
                {item.text !== "" && (
                  <div>
                    <Box
                      p={1}
                      sx={{
                        backgroundColor: `${
                          item.sender === sellerId ? "#38c776" : "#000"
                        }`,
                        borderRadius: "5px",
                        color: "#fff",
                      }}
                    >
                      <Typography variant="p">{item.text}</Typography>
                    </Box>

                    <Typography
                      variant="small"
                      fontSize={12}
                      color="#000000d3"
                      paddingTop={2}
                    >
                      {format(item.createdAt)}
                    </Typography>
                  </div>
                )}
              </Box>
            );
          })}
        {/* {console.log({typingUsers})} */}
        {typingUsers.map((user, i) => (
          <div key={messages.length + i}>
            <TypingMessage user={user}></TypingMessage>
          </div>
        ))}
      </div>

      {/* send message */}
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "rgb(226,232,240)",
        }}
      >
        <form className="message-control" onSubmit={sendMessageHandler}>
          <CollectionsIcon size="large" />
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type something...!"
            onKeyPress={handleStartTyping}
            onKeyUp={handleStopTyping}
          />
          <div className="file-input-container">
            <label htmlFor="hidden-file">
              <img
                width="20"
                src="https://images.pexels.com/photos/17202950/pexels-photo-17202950/free-photo-of-city-people-street-building.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt={""}
              />
            </label>
          </div>
          <button
            className="sendButton"
            type="button"
            onClick={handleEmojiShow}
          >
            <img className="uploadImage" src={emojiIcon} alt="uploadImage" />
          </button>

          <button>
            <SendIcon />
            <span style={{ display: "inline-block" }}>Send</span>
          </button>
        </form>
        <div>
            {showEmoji && (
              <div ref={ref}>
                <Picker onSelect={handleEmojiSelect} emojiSize={20} />
              </div>
            )}
          </div>

      </Box>

    </Box>
  );
};

export default UserInbox;
