import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading3 from "../components/Loading/Loading3";
import ImageHeaderOperators from "../components/common/ImageHeaderOperators";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import { toast } from "react-toastify";
import uiConfigs from "../configs/ui.configs";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  IconButton,
  easing,
  useTheme,
  useMediaQuery,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepButton,
} from "@mui/material";
import Quotes from "../components/common/quotes/Quotes";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import BookingSteps from "../components/common/booking/BookingSteps";

function Booking() {
  const { operatorId } = useParams();

  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const [operatorInfo, setOperatorInfo] = useState([]);
  console.log({ operatorInfo });
  const { user } = useSelector((state) => state.user);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    let variable = { operator: operatorId };

    axios
      .post(`${baseURL}api/products/operatorInfo`, variable)
      .then((response) => {
        if (response.data.success) {
          // toast.info("Successfull to get Operator Info");
          setOperatorInfo(response.data.operator);
          //   console.log("operatorInfo :", operatorInfo);
          setData(true);
        } else {
          toast.error("Failed to get operator info");
        }
      });
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  if (data) {
    return (
      <>
        <ImageHeaderOperators
          imgPath={operatorInfo[0].coverPhoto}
          height={""}
        />
        <Box sx={{ ...uiConfigs.style.mainContent }} border={0}>
          {/* //Top banner */}
          <Box position={"relative"} mt={2}>
            <img
              src={operatorInfo[0].coverPhoto}
              style={{
                height: "15rem",
                filter: `brightness(50%)`,
                width: "100%",
                objectFit: "cover",
              }}
            />
            <Typography
              variant="h1"
              fontSize={{ xs: "1.5rem", sm: "4rem" }}
              fontFamily={"Inknut Antiqua, serif"}
              sx={{
                letterSpacing: 10,
                position: "absolute",
                top: "50%",
                left: "50%",
                color: "secondary.white",
                transform: `translate(-50%, -50%)`,
              }}
            >
              RESERVATION
            </Typography>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            columnGap="00px"
            my="30px"
            sx={{ borderTop: 0, borderColor: "divider" }}
          >
            <Box
              flex="1 1 20%"
              mb="0px"
              border={0}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                onClick={() => navigate(`/operator/${operatorId}`)}
                sx={{ cursor: "pointer" }}
                border={0}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={operatorInfo[0].photo}
                    sx={{
                      width: { xs: 40, sm: 80 },
                      height: { xs: 40, sm: 80 },
                      border: "2px solid #fff",
                    }}
                  />
                </StyledBadge>
              </Box>
            </Box>
            <Box flex="1 1 75%" mb="0px" border={0}>
              <Typography
                variant="h4"
                textTransform={"uppercase"}
                fontSize={{ xs: "2rem", sm: "4rem" }}
              >
                {operatorInfo[0].name}
              </Typography>
            </Box>
          </Box>
          <Box border={0} p={0}>
            <Box
              display="flex"
              flexWrap="wrap"
              columnGap="00px"
              my="0px"
              sx={{ borderTop: 0, borderColor: "divider" }}
            >
              <Box flex="1 1 70%" bgcolor={"secondary.white"}>
                <Box m={1} border={1} borderColor={"divider"} minHeight={"30rem"}>
                  <BookingSteps operatorId={operatorId}/>
                </Box>
              </Box>

              <Box flex="1 1 25%" mb="0px" border={0}>
                <Quotes />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <div>
        <Loading3 />
      </div>
    );
  }
}

export default Booking;
