import { Box, Checkbox, Divider, FormControlLabel, Typography, Button } from "@mui/material";
import AddressForm from "./AddressForm";
import { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import PaymentIcon from '@mui/icons-material/Payment';

const PaymentMethod = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  // console.log({values})
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const [submitted, setSubmitted] = useState(false);
  const handleEdited = async () => {
    setSubmitted(!submitted);
    await sleep(5000);
  };


  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    return values.paymentMethod.length;
  };

  useEffect(() => {
    const isValid = validate();
    setIsValid(!isValid);
  });

  
  return (
    <Box m="30px auto">
      {/* BILLING FORM */}
      <Box>
        <Typography
          sx={{ mb: "15px" }}
          fontSize="18px"
          fontWeight={700}
          textTransform={"uppercase"}
          color={"primary.contrastText"}
          display={"flex"}
        >
          <PaymentIcon sx={{mr: 1}} />PAYMENT METHOD
        </Typography>
        {!submitted && (
          <>
            <Box
                mb="20px"
                p={1}
                display={"flex"}
                alignItems={"center"}
                sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
              >
                <Field type="radio" name="paymentMethod" value="Cash" />
                <Typography ml={1} color={"primary.contrastText"}>
                  Cash
                </Typography>
              </Box>
              <Box
                mb="20px"
                p={1}
                display={"flex"}
                alignItems={"center"}
                sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
              >
                <Field type="radio" name="paymentMethod" value="Card at pickup counter" />
                <Typography ml={1} color={"primary.contrastText"}>
                  Card at pickup counter
                </Typography>
              </Box>
          </>
        )}
      </Box>
      {submitted && (
        <Typography color={"primary.contrastText"}>
          {values.paymentMethod}
        </Typography>
      )}
            <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isValid}
          sx={{
            backgroundColor: "primary.main",
            boxShadow: "none",
            color: "white",
            borderRadius: 0,
            padding: "15px 40px",
            mt: "20px",
          }}
          onClick={handleEdited}
        >
          {submitted ? (
            <Typography display={"flex"} color={"#fff"}>
              <PaymentIcon sx={{ mr: 1 }} /> EDIT PAYMENT METHOD
            </Typography>
          ) : (
            "Save"
          )}
      </Button>
    </Box>
  );
};

export default PaymentMethod;
