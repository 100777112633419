import { createSlice } from "@reduxjs/toolkit";

export const authOperatorModalSlice = createSlice({
  name: "AuthOperatorModal",
  initialState: {
    authOperatorModalOpen: false
  },
  reducers: {
    setAuthOperatorModalOpen: (state, action) => {
      state.authOperatorModalOpen = action.payload;
    }
  }
});


export const {
  setAutOperatorhModalOpen
} = authOperatorModalSlice.actions;


export default authOperatorModalSlice.reducer;



