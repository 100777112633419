import * as React from "react";
import { useEffect, useState, useRef } from "react";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Stack } from "@mui/system";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Chip, Divider } from "@mui/material";
import LocalCafeTwoToneIcon from "@mui/icons-material/LocalCafeTwoTone";
import LunchDiningTwoToneIcon from "@mui/icons-material/LunchDiningTwoTone";
import RestaurantMenuTwoToneIcon from "@mui/icons-material/RestaurantMenuTwoTone";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { LoadingButton } from "@mui/lab";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BookingHotDeal() {
  const [expanded, setExpanded] = React.useState(false);

  const [expanded1, setExpanded1] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const [value, setValue] = useState("breakfast");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card sx={{ margin: "0rem",}} variant="outlined">
      <CardActions disableSpacing style={{ margin: "0rem", display:"flex", justifyContent:"space-between" }}>
        <IconButton aria-label="add to favorites">
          <FavoriteBorderOutlinedIcon sx={{ color: "primary.main" }} />
        </IconButton>
        <div>
          <Button variant="text">GET NOW</Button>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandCircleDownOutlinedIcon fontSize="small" />
          </ExpandMore>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: { xs: 400, sm: 800 },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              centered
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                label="BREAKFAST"
                value="breakfast"
                minWidth="10%"
                icon={<LocalCafeTwoToneIcon />}
              />
              <Tab
                label="LUNCH"
                value="lunch"
                icon={<LunchDiningTwoToneIcon />}
              />
              <Tab
                label="DINNER"
                value="dinner"
                icon={<RestaurantMenuTwoToneIcon />}
              />
            </Tabs>
          </Box>
          <Box display="flex">
            {value === "breakfast" && (
              <Box mt="1rem">
                <Typography variant="h6">Breakfast</Typography>

                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "1fr 1fr 1fr",
                    sm: "1fr 1fr 1fr 1fr",
                  }}
                  gap="0.5rem"
                >
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Wed
                      </Typography>
                      <Typography variant="h6" component="div">
                        15 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Thu
                      </Typography>
                      <Typography variant="h6" component="div">
                        16 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Fri
                      </Typography>
                      <Typography variant="h6" component="div">
                        17 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        9:00am
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sat
                      </Typography>
                      <Typography variant="h6" component="div">
                        18 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        9:00am
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sun
                      </Typography>
                      <Typography variant="h6" component="div">
                        19 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        closed
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Mon
                      </Typography>
                      <Typography variant="h6" component="div">
                        20 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Tue
                      </Typography>
                      <Typography variant="h6" component="div">
                        21 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            )}
            {value === "lunch" && (
              <Box mt="1rem">
                <Typography variant="h6">Lunch</Typography>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "1fr 1fr 1fr",
                    sm: "1fr 1fr 1fr 1fr",
                  }}
                  gap="0.5rem"
                >
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Wed
                      </Typography>
                      <Typography variant="h6" component="div">
                        15 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Thu
                      </Typography>
                      <Typography variant="h6" component="div">
                        16 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Fri
                      </Typography>
                      <Typography variant="h6" component="div">
                        17 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        12:00pm
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sat
                      </Typography>
                      <Typography variant="h6" component="div">
                        18 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        12:00pm
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sun
                      </Typography>
                      <Typography variant="h6" component="div">
                        19 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        closed
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Mon
                      </Typography>
                      <Typography variant="h6" component="div">
                        20 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Tue
                      </Typography>
                      <Typography variant="h6" component="div">
                        21 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            )}
            {value === "dinner" && (
              <Box mt="1rem">
                <Typography variant="h6">Dinner</Typography>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "1fr 1fr 1fr",
                    sm: "1fr 1fr 1fr 1fr",
                  }}
                  gap="0.5rem"
                >
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Wed
                      </Typography>
                      <Typography variant="h6" component="div">
                        15 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        6:00pm
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Thu
                      </Typography>
                      <Typography variant="h6" component="div">
                        16 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        6:00pm
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Fri
                      </Typography>
                      <Typography variant="h6" component="div">
                        17 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sat
                      </Typography>
                      <Typography variant="h6" component="div">
                        18 Mar
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Sun
                      </Typography>
                      <Typography variant="h6" component="div">
                        19 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        closed
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ maxWidth: 100, m: "0.0rem" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Mon
                      </Typography>
                      <Typography variant="h6" component="div">
                        20 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        -
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      maxWidth: 100,
                      m: "0.0rem",
                      backgroundColor: "primary.main",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Tue
                      </Typography>
                      <Typography variant="h6" component="div">
                        21 Mar
                      </Typography>
                      <Typography sx={{ mb: 0 }} color="text.secondary">
                        5:30pm
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            )}
          </Box>

          {/* Special condition section */}
          <CardActions disableSpacing style={{ margin: "0rem" }}>
            <Stack spacing={0}>
              <Typography variant="h6" component="div" fontWeight={500}>
                Special condition
              </Typography>
            </Stack>
            <ExpandMore
              expand={expanded1}
              onClick={handleExpandClick1}
              aria-expanded={expanded1}
              aria-label="show more"
              // orientation="horizontal"
            >
              <ExpandCircleDownOutlinedIcon fontSize="large" />
            </ExpandMore>
          </CardActions>

          <Collapse in={expanded1} timeout="auto" unmountOnExit>
            <Box m=".5rem">
              <Typography paragraph>
                This restaurant has the following special conditions for Lokalai
                reservations:
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="6" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ display: "inline" }} paragraph>
                        Minimum purchase of one beverage per diner
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="6" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ display: "inline" }} paragraph>
                        The Lokalai offer cannot be used in conjunction with any
                        other specials, promotions, drink specials, vouchers or
                        gift cards
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="6" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ display: "inline" }} paragraph>
                      Reservations are limited to 2 hours from the time of
                      booking
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="6" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ display: "inline" }} paragraph>
                      Please note that Specialty Seafood and Venison are
                      excluded from the Lokalai offer (all exclusions will be
                      iterated at the table)
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="6" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ display: "inline" }} paragraph>
                      All guests need to be seated on time
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          </Collapse>
        </CardContent>
      </Collapse>
    </Card>
  );
}
