import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
  IconButton,
  Badge,
  Button,
} from "@mui/material";
import {
  PersonOutline,
  ShoppingBagOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menuConfigs from "../../configs/menu.configs";
import { setUser } from "../../redux/features/userSlice";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IoIosNotifications } from "react-icons/io";
import { setThemeMode } from "../../redux/features/themeModeSlice";
import { themeModes } from "../../configs/theme.configs";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { setIsCartOpen } from "../../redux/features/cartState";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import { Box } from "@mui/system";

const UserMenu = ({ socketId }) => {
  const { user } = useSelector((state) => state.user);
  // console.log({user})
  const { themeMode } = useSelector((state) => state.themeMode);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  // console.log({cart})

  useEffect(() => {
    socketId.on("getNotification", (data) => {
      // console.log({ data });
      setNotifications((prev) => [...prev, data]);
      // console.log({ notifications });
    });
  }, [socketId]);

  const displayNotification = ({ senderName, type }) => {
    let action;

    if (type === 1) {
      action = "liked";
    } else if (type === 2) {
      action = "commented";
    } else {
      action = "message";
    }
    return (
      <span className="notification">{`${senderName} ${action} you a notification.`}</span>
    );
  };

  const handleRead = () => {
    setNotifications([]);
    setOpen(false);
  };

  // console.log({ user });

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = (e) => setAnchorEl(e.currentTarget);

  const onSwithTheme = () => {
    const theme =
      themeMode === themeModes.dark ? themeModes.light : themeModes.dark;
    dispatch(setThemeMode(theme));
  };

  const onSignout = () => {
    dispatch(setUser(null));
    navigate("/");
  };

  return (
    <>
      {user && (
        <>
          <Box
            gap={{ xs: "5px", md: "20px" }}
            sx={{
              // cursor: "pointer",
              // userSelect: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Box>
              <IconButton onClick={() => setOpen(!open)}>
                <NotificationsNoneIcon />
                {notifications.length > 0 && (
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      padding: "5px",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                    }}
                  >
                    {notifications.length}
                  </div>
                )}
              </IconButton>
              {open && (
                <div className="notifications">
                  {notifications.map((n) => displayNotification(n))}
                  <button className="nButton" onClick={handleRead}>
                    Mark as read
                  </button>
                </div>
              )}
              <IconButton sx={{ color: "inherit" }} onClick={onSwithTheme}>
                {themeMode === themeModes.dark && <DarkModeOutlinedIcon />}
                {themeMode === themeModes.light && <WbSunnyOutlinedIcon />}
              </IconButton>
            </Box> */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              borderRight={0}
              p={1}
            >
              <IconButton
                onClick={() => navigate(`/qrcode`)}
                sx={{
                  color: "inherit",
                }}
              >
                <QrCodeIcon />
              </IconButton>
              <Box display={{ xs: "none", md: "flex" }}>
                <span
                  sx={{
                    color: "inherit",
                  }}
                >
                  QR
                </span>
              </Box>
            </Box>
            {/* <Box display="flex" alignItems="center">
              <IconButton component={Link} to="/favorites">
                <FavoriteBorderOutlinedIcon />
              </IconButton>
              <Box display={{ xs: "none", md: "flex" }}>
                <Typography
                  alignItems="center"
                  sx={{
                    color: "primary.contrastText",
                  }}
                >
                  Wishlist
                </Typography>
              </Box>
            </Box> */}
            {/* <Box display="flex" alignItems="center">
              <Badge
                badgeContent={cart.length}
                color="secondary"
                // invisible={cart.length === 0}
                sx={{
                  "& .MuiBadge-badge": {
                    right: 5,
                    top: 5,
                    padding: "0 4px",
                    height: "14px",
                    minWidth: "13px",
                  },
                }}
              >
                <IconButton
                  onClick={() => dispatch(setIsCartOpen({}))}
                  // sx={{ color: "black" }}
                >
                  <ShoppingBagOutlined />
                </IconButton>
              </Badge> 
              <Box display={{ xs: "none", md: "flex" }}>
                <Typography
                  alignItems="center"
                  sx={{
                    color: "primary.contrastText",
                  }}
                >
                  Cart
                </Typography>
              </Box>
            </Box>*/}

            <Box display="flex" alignItems="center" borderRight={0} pr={1}>
              <IconButton onClick={toggleMenu}>
                <img
                  width={32}
                  height={32}
                  src={
                    user.photo !== ""
                      ? user.photo
                      : "https://globalmira.s3.ap-southeast-2.amazonaws.com/man23.jpg"
                  }
                  style={{ borderRadius: "50%", border: "1px solid #fff" }}
                  alt=""
                />
              </IconButton>
              <Box
                display={{ xs: "none", md: "flex" }}
                sx={{
                  height: "40px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <small style={{ textTransform: "uppercase" }}>
                  <strong>{user.displayName}</strong>
                </small>
                <small>{user.username}</small>
              </Box>
            </Box>
          </Box>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            PaperProps={{ sx: { padding: 0 } }}
          >
            {menuConfigs.user.map((item, index) => (
              <ListItemButton
                component={Link}
                to={item.path}
                key={index}
                onClick={() => setAnchorEl(null)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography textTransform="uppercase">
                      {item.display}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
            <ListItemButton sx={{ borderRadius: "10px" }} onClick={onSignout}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography textTransform="uppercase">sign out</Typography>
                }
              />
            </ListItemButton>
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;
