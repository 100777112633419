import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Box,
  Typography,
  useTheme,
  Button,
  Zoom,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  addToCart,
  setIsAddtoCartOpen,
} from "../../../redux/features/cartState";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { grey } from "@mui/material/colors";

const MenuItem = ({ item, width }) => {
  // console.log({item})
  // console.log({width})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid primary.main",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const { price } = item;
  const totalPrice = count * price;

  return (
    <Box>
      <Box
        sx={{ width: "100%" }}
        display={"flex"}
        py={{ xs: 1, sm: 2 }}
        px={{ xs: 1, sm: 1 }}
        alignItems={"start"}
        border={0}
      >
        <Box sx={{ width: "90%" }} onClick={handleExpandClick}>
          <Typography variant="caption" fontSize={{ xs: "1rem", sm: "1.2rem" }}>
            {item.title}
          </Typography>
          <Typography variant="body1" fontSize={{ xs: "0.8rem", sm: "1rem" }} color={"secondary.black"}>
            {item.desc}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            flexDirection: "column",
          }}
          border={0}
        >
          <Typography variant="h6" pr={{ xs: 0, sm: 3 }}>
            ${item.price}
          </Typography>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={expanded}
        onClose={handleExpandClick}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={expanded}>
          <Box sx={style}>
            <Box>
              <Typography
                variant="caption"
                fontSize={{ xs: "1rem", sm: "1.2rem" }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "0.8rem", sm: "1rem" }}
                color={"primary.black"}
              >
                {item.desc}
              </Typography>
            </Box>
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                backgroundColor="#fff"
                borderRadius="3px"
                gap={1}
                sx={{ border: "1px solid gray" }}
                component="fieldset"
                mt={3}
              >
                <legend>Quantity</legend>
                <Typography color="primary.contrastText" ml={2}>
                  {count}
                </Typography>
                <Box display={"flex"} gap={1}>
                  <Box border={1} bgcolor={"#dadada"}>
                    <IconButton
                      onClick={() => setCount(Math.max(count - 1, 1))}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                  <Box border={1} bgcolor={"#dadada"}>
                    <IconButton onClick={() => setCount(count + 1)}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
            p={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: '5px',
              }}
              component="fieldset"
              mt={1}
            >
              <legend>Total</legend>
              <Typography ml={1} color={"primary.contrastText"}>${totalPrice}</Typography>
              <Button
                onClick={() => {
                  dispatch(
                    addToCart({
                      item: { ...item, count },
                    })
                  );
                }}
                sx={{
                  paddingX:'1rem',
                  bgcolor:`${grey[300]}`,
                  borderRadius: "5px",
                  color:"primary.black",
                  "&:hover": {
                    backgroundColor: `${grey[600]}`,
                    boxShadow: "none",
                  },
                }}
              >
                Add to Cart
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* <Collapse in={expanded} timeout={500} unmountOnExit >
          <Box
            display={isHovered ? "block" : "none"}
            padding="5%"
            border={0}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                alignItems="center"
                backgroundColor="lightgray"
                borderRadius="3px"
              >
                <IconButton onClick={() => setCount(Math.max(count - 1, 1))}>
                  <RemoveIcon />
                </IconButton>
                <Typography color="primary.main">{count}</Typography>
                <IconButton onClick={() => setCount(count + 1)}>
                  <AddIcon />
                </IconButton>
              </Box>
              <Button
                onClick={() => {
                  dispatch(
                    addToCart({
                      item: { ...item, count },
                    })
                  );
                }}
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                }}
              >
                Add to Cart
              </Button>
            </Box>
          </Box>
        </Collapse> */}
    </Box>
  );
};

export default MenuItem;
