import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageHeader from "../components/common/ImageHeader";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import OperatorGrid from "../components/common/operator/OperatorGrid";
import JoinRoyaltyGrid from "../components/common/JoinRoyaltyGrid";
import CardDesignGrid from "../components/common/CardDesignGrid";

const Royalty = ({socketId}) => {
  // console.log({socketId})
  window.scrollTo(100, 500);
  const { user } = useSelector((state) => state.user);
  // console.log({ user });



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [userInfo, setUserInfo] = useState([]);
  const [joinedOperators, setJoinedOperators] = useState([]);
  const [cardDesign, setCardDesign] = useState([]);


  let userInforVariable = { userId: user.id };
  let variable = { userFrom: user.id };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(true));

    axios
      .post(`${baseURL}api/v1/user/userInfo`, userInforVariable)
      .then((response) => {
        if (response.data.success) {
          // console.log(response.data.user);
          setUserInfo(response.data.user);
        } else {
          alert("Failed to get subscription products");
        }
      });

    //GET LIST OPERATORS JOINED ROYALTY PROGRAM
    axios.post(`${baseURL}api/royalprogram/getListOperators`, variable).then((response) => {
      if (response.data.success) {
        // console.log(response.data.operators);
        setJoinedOperators(response.data.operators);
      } else {
        alert("Failed to get list of operators ");
      }
    });


    //GET LIST OPERATORS JOINED ROYALTY PROGRAM
    axios.post(`${baseURL}api/royalprogram/getListCardDesign`, variable).then((response) => {
        if (response.data.success) {
            // console.log("card design:", response.data.stamps);
            setCardDesign(response.data.stamps);
            } else {
              alert("Failed to get list of operators ");
            }
          });

    dispatch(setGlobalLoading(false));
  }, []);
  return (
    <>
      <ImageHeader
        imgPath={
          "https://images.pexels.com/photos/11733557/pexels-photo-11733557.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
      />
      <Box marginTop="-4rem" sx={{ ...uiConfigs.style.mainContent }}>
        <Stack spacing={1}>
          <Avatar
            src={
              "https://images.pexels.com/photos/13921044/pexels-photo-13921044.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            }
            sx={{ margin: "10px auto", width: 56, height: 56 }}
          />
          <Item>
            <Typography variant="h4" color="primary.main" fontWeight={700}>
              {userInfo.displayName}
            </Typography>
          </Item>
          <Item>
            <p>Address:{userInfo.username}</p>
            <p>Email: {userInfo.username}</p>
            <p>Phone: {userInfo.phone}</p>
            <p>Introduction: {userInfo.bio}</p>
          </Item>
        </Stack>
        <Container header="List Business register Royalty Programs">
          <Stack>
            <Item>
                <JoinRoyaltyGrid medias={joinedOperators} />
            </Item>
          </Stack>
        </Container>
        <Container header="Royalty Cards">
          <Item></Item>
          <CardDesignGrid medias={cardDesign} socket={socketId}/>
        </Container>
      </Box>
    </>
  );
};

export default Royalty;
