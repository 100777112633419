import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { SwiperSlide } from "swiper/react";
import tmdbConfigs from "../../api/configs/tmdb.configs";
import NavigationSwiper from "./NavigationSwiper";
import './BestDealVideoSlide.css'

const BestDealVideo = ({ embedId }) => {
  return (
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
  );
};

const BestDealVideoSlide = ({ videos }) => {
  // console.log({ videos });
  return (
    <NavigationSwiper>
      {videos.map((video, index) => (
        <SwiperSlide key={index}>
          <BestDealVideo embedId={video} />
        </SwiperSlide>
      ))}
    </NavigationSwiper>
  );
};

export default BestDealVideoSlide;
