import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import { yellow } from "@mui/material/colors";

export const themeModes = {
  dark: "dark",
  light: "light"
};

const themeConfigs = {
  custom: ({ mode }) => {
    const customPalette = mode === themeModes.dark ? {
      primary: {
        // main: "#F5E653",//"#f44336", yellow
        // main: "#FFC70B",//"#f44336", yellow

        // main: "#ff0000",
        // contrastText: colors.grey["700"],
        contrastText: '#fff',
        main: "#f44336",//"red"
        black:"#000",
        white:'#fff',
        lightgray: '#dadada'
      },
      secondary: {
        // main: "#F5E653",// yellow
        main: "#f44336",//"red"
        contrastText: "#ffffff",
        white:'#fff'
      },
      background: {
        // default: colors.grey["100"],
        default: "#000",
        paper: "#131313"
      }
    } : {
      primary: {
        // main: "#FFC70B",//"#f44336", yellow
        main: "#f44336",//"red"
        // main: "#ff0000"
        contrastText: colors.grey["700"],
        black:"#000",
        white:'#fff',
        red: "#f44336"
      },
      secondary: {
        // main: "#F5E653",// yellow
        main: "#f44336",//"red"
        contrastText: "#000",
        white:'#fff',
        black:"#000",
        red: "#f44336"
      },
      background: {
        default: colors.grey["100"],
      }
    };

    return createTheme({
      palette: {
        mode,
        ...customPalette
      },
      components: {
        MuiButton: {
          defaultProps: { disableElevation: true }
        }
      },
      overrides: {
        MuiAvatar: {
          img: {
            // handle correctly non-square images
            objectFit: 'cover',
            height: '100%',
          },
        },
      },
    });
  }
};

export default themeConfigs;