import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import AddressForm from "./AddressForm";

import { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

const OrderingMethod = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  // console.log({values})
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const [submitted, setSubmitted] = useState(false);
  const handleEdited = async (values, action) => {
    setSubmitted(!submitted);
    await sleep(5000);
  };

  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    return values.orderingMethod.length;
  };

  useEffect(() => {
    const isValid = validate();
    setIsValid(!isValid);
  });

  return (
    <Box m="30px auto" border={0}>
      {/* BILLING FORM */}

      <Box border={0}>
        <Typography
          sx={{ mb: "15px" }}
          fontSize="18px"
          fontWeight={700}
          textTransform={"uppercase"}
          color={"primary.contrastText"}
          display={"flex"}
        >
          <ImportantDevicesOutlinedIcon sx={{ mr: 1 }} />
          ORDERING METHOD
        </Typography>
        {!submitted && (
          <>
            <div role="group" aria-labelledby="my-radio-group">
              <Box
                mb="20px"
                p={1}
                display={"flex"}
                alignItems={"center"}
                sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
              >
                <Field type="radio" name="orderingMethod" value="Delivery" />
                <Typography ml={1} color={"primary.contrastText"}>
                  Delivery
                </Typography>
              </Box>
              {values.orderingMethod === "Delivery" ? (
                <>
                  <Box mb="20px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          value={values.shippingAddress.isSameAddress}
                          onChange={() =>
                            setFieldValue(
                              "shippingAddress.isSameAddress",
                              !values.shippingAddress.isSameAddress
                            )
                          }
                        />
                      }
                      label="Same for Shipping Address"
                    />
                  </Box>

                  {!values.shippingAddress.isSameAddress && (
                    <Box>
                      <Typography sx={{ mb: "0px" }} color={"primary.contrastText"} textTransform={"uppercase"}>
                        Shipping Information
                      </Typography>
                      <Box p={3} border={`1px solid lightgray`} mb={2} >
                        <AddressForm
                          type="shippingAddress"
                          values={values.shippingAddress}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              ) : undefined}

              <Box
                mb="20px"
                p={1}
                display={"flex"}
                alignItems={"center"}
                sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
              >
                <Field type="radio" name="orderingMethod" value="Pick up" />
                <Typography ml={1} color={"primary.contrastText"}>
                  Pick up
                </Typography>
              </Box>
            </div>
          </>
        )}
        {submitted && (
          <Box>
            <Typography color={"primary.contrastText"} textTransform={"uppercase"}><small>ORDERING METHOD:</small> {values.orderingMethod}</Typography>
          </Box>
        )}
      </Box>
      <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={isValid}
          sx={{
            backgroundColor: "primary.main",
            boxShadow: "none",
            color: "white",
            borderRadius: 0,
            padding: "15px 40px",
            mt: "20px",
          }}
          onClick={handleEdited}
        >
          {submitted ? (
            <Typography display={"flex"} color={"#fff"}>
              <BorderColorOutlinedIcon sx={{ mr: 1 }} /> EDIT ORDERING METHOD
            </Typography>
          ) : (
            "Save"
          )}
      </Button>

    </Box>
  );
};

export default OrderingMethod;
