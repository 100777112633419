import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
const main = [
  {
    display: "home",
    path: "/",
    icon: <HomeOutlinedIcon />,
    state: "home"
  },
  {
    display: "what's on",
    path: "/whaton",
    icon: <SlideshowOutlinedIcon />,
    state: "movie"
  },
  {
    display: "discover",
    path: "/tv",
    icon: <LiveTvOutlinedIcon />,
    state: "tv"
  },
  // {
  //   display: "search",
  //   path: "/search",
  //   icon: <SearchOutlinedIcon />,
  //   state: "search"
  // }
];

const user = [
  {
    display: "favorites",
    path: "/favorites",
    icon: <FavoriteBorderOutlinedIcon />,
    state: "favorite"
  },
  {
    display: "favorites2",
    path: "/favorites2",
    icon: <FavoriteBorderOutlinedIcon />,
    state: "favorite2"
  },
  {
    display: "reviews",
    path: "/reviews",
    icon: <RateReviewOutlinedIcon />,
    state: "reviews"
  },
  {
    display: "password update",
    path: "/password-update",
    icon: <LockResetOutlinedIcon />,
    state: "password.update"
  },
  // {
  //   display: "qr code",
  //   path: "/qrcode",
  //   icon: <QrCode2OutlinedIcon />,
  //   state: "qrcode"
  // },
  // {
  //   display: "admin dashboard",
  //   path: "/admin-dashboard",
  //   icon: <DashboardIcon />,
  //   state: "admin.dashboard"
  // },
  {
    display: "profile",
    path: "/user-dashboard",
    icon: <PortraitOutlinedIcon />,
    state: "user.dashboard"
  },
  {
    display: "following",
    path: "/following",
    icon: <FilePresentOutlinedIcon />,
    state: "password.update"
  },
  {
    display: "update profile",
    path: "/profileUpdate",
    icon: <ManageAccountsOutlinedIcon />,
    state: "user.update"
  },
  {
    display: "royalty program",
    path: "/royalty",
    icon: <CardMembershipOutlinedIcon />,
    state: "password.update"
  },
  {
    display: "user inbox",
    path: "/userInbox",
    icon: <CardMembershipOutlinedIcon />,
    state: "user.inbox"
  },

];

const menuConfigs = { main, user };

export default menuConfigs;