import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { Tooltip, Icon } from 'antd';
import Axios from "axios";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { Box, Divider, IconButton } from "@mui/material";
// import { setAuthModalOpen } from "../redux/features/authModalSlice";
import { setAuthModalOpen } from "../../../redux/features/authModalSlice";
import { addFavorite, removeFavorite } from "../../../redux/features/userSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import favoriteApi from "../../../api/modules/favorite.api";
import { baseURL } from "../../../api/client/URL";

function LikeDislikes(props) {
  // console.log({ props });

  const { user } = useSelector((state) => state.user);
  // console.log({ user });

  const [Likes, setLikes] = useState(0);
  const [Dislikes, setDislikes] = useState(0);
  const [LikeAction, setLikeAction] = useState(null);
  const [DislikeAction, setDislikeAction] = useState(null);

  const [isFavorite, setIsFavorite] = useState(false);
  const [onRequest, setOnRequest] = useState(false);


  const dispatch = useDispatch();
  let variable = {};

  if (props.video) {
    variable = { videoId: props.product, userId: user.id };
  } else {
    variable = { commentId: props.commentId, userId: user.id };
  }
  // console.log({ variable });

  useEffect(() => {
    Axios.post(`${baseURL}api/like/getLikes`, variable).then((response) => {
      // console.log("getLikes", response.data);

      if (response.data.success) {
        //How many likes does this video or comment have
        setLikes(response.data.likes.length);          //if I already click this like button or not
        response.data.likes.map((like) => {
          if (like.userId === props.userId) {
            setLikeAction("liked");
            // setIsLike(true)
          }
        });
      } else {
        alert("Failed to get likes");
      }
    });

    Axios.post(`${baseURL}api/like/getDislikes`, variable).then((response) => {
      // console.log("getDislike", response.data);
      if (response.data.success) {
        //How many likes does this video or comment have
        setDislikes(response.data.dislikes.length);
        //if I already click this like button or not
        response.data.dislikes.map((dislike) => {
          if (dislike.userId === props.userId) {
            setDislikeAction("disliked");
          }
        });
      } else {
        alert("Failed to get dislikes");
      }
    });
  }, []);

  const onLike = () => {
    if (!user) return dispatch(setAuthModalOpen(true));

    if (LikeAction === null) {
      Axios.post(`${baseURL}api/like/upLike`, variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes + 1);
          setLikeAction("liked");


          //If dislike button is already clicked

          if (DislikeAction !== null) {
            setDislikeAction(null);
            setDislikes(Dislikes - 1);
          }
        } else {
          alert("Failed to increase the like");
        }
      });
    } else {
      Axios.post(`${baseURL}api/like/unLike`, variable).then((response) => {
        if (response.data.success) {
          setLikes(Likes - 1);
          setLikeAction(null);
        } else {
          alert("Failed to decrease the like");
        }
      });
    }
  };

  const onDisLike = () => {
    if (!user) return dispatch(setAuthModalOpen(true));

    if (props.video) {
      variable = { videoId: props.product, userId: user.id };
    } else {
      variable = { commentId: props.commentId, userId: user.id };
    }
    console.log({ variable });

    if (DislikeAction !== null) {
      Axios.post(`${baseURL}api/like/unDisLike`, variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes - 1);
          setDislikeAction(null);
        } else {
          alert("Failed to decrease dislike");
        }
      });
    } else {
      Axios.post(`${baseURL}api/like/upDisLike`, variable).then((response) => {
        if (response.data.success) {
          setDislikes(Dislikes + 1);
          setDislikeAction("disliked");

          //If dislike button is already clicked
          if (LikeAction !== null) {
            setLikeAction(null);
            setLikes(Likes - 1);
          }
        } else {
          alert("Failed to increase dislike");
        }
      });
    }
  };

  const onFavoriteClick = async () => {
    if (!user) return dispatch(setAuthModalOpen(true));

    if (onRequest) return;

    if (isFavorite) {
      onRemoveFavorite();
      return;
    }

    setOnRequest(true);

    const body = {
      mediaId: props.product,
      mediaTitle: props.title,
      mediaType: "movie",
      mediaPoster: "/t6HIqrRAclMCA60NsSmeqe9RmNV.jpg",
      mediaRate: 7.726

    };

    const { response, err } = await favoriteApi.add(body);

    setOnRequest(false);

    if (err) toast.error(err.message);
    if (response) {
      dispatch(addFavorite(response));
      setIsFavorite(true);
      toast.success("Add favorite success");
    }
  };

  const onRemoveFavorite = async () => {
    if (onRequest) return;
    setOnRequest(true);

    // const favorite = listFavorites.find(e => e.mediaId.toString() === media.id.toString());
    const favorite = {};

    const { response, err } = await favoriteApi.remove({
      favoriteId: favorite.id,
    });

    setOnRequest(false);

    if (err) toast.error(err.message);
    if (response) {
      dispatch(removeFavorite(favorite));
      setIsFavorite(false);
      toast.success("Remove favorite success");
    }
  };

  return (
    <Box
      display="flex"
      // sx={{ backgroundColor: "#fff", padding: "0px", borderRadius: "10px" }}
    >
      <LoadingButton
        variant="text"
        sx={{
          minWidth: "80px",
          "& .MuiButon-starIcon": { marginRight: "0" },
          color: "primary.contrastText",
          // backgroundColor: `${isLike ? "primary.main" : "transparent"}`,
        }}
        size="small"
        startIcon={LikeAction === 'liked' ? <ThumbUpIcon /> : <ThumbUpAltOutlinedIcon />}
        loadingPosition="start"
        loading={onRequest}
        onClick={onLike}
      >
        <span style={{ fontSize: "0.8rem" }}>{Likes}</span>
      </LoadingButton>
      <Divider orientation="vertical" flexItem />
      <LoadingButton
        variant="text"
        sx={{
          width: "max-content",
          minWidth: "80px",
          "& .MuiButon-starIcon": { marginRight: "0" },
          color: "primary.contrastText",
        }}
        size="small"
        startIcon={DislikeAction === 'disliked' ? <ThumbDownAltIcon /> : <ThumbDownOutlinedIcon />}
        loadingPosition="start"
        loading={onRequest}
        onClick={onDisLike}
      >
        <span style={{ fontSize: "0.8rem" }}>{Dislikes}</span>
      </LoadingButton>

      <Divider orientation="vertical" flexItem />

      <LoadingButton
        variant="text"
        sx={{
          width: "max-content",
          minWidth: "80px",
          "& .MuiButon-starIcon": { marginRight: "0" },
          color: "primary.contrastText",
        }}
        size="small"
        startIcon={
          isFavorite ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />
        }
        loadingPosition="start"
        loading={onRequest}
        onClick={onFavoriteClick}
      >
        20
      </LoadingButton>
    </Box>
      );
}

export default LikeDislikes;
