import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import userApi from "../../api/modules/user.api";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setUser } from "../../redux/features/userSlice";
import "./SignIn.css";

import Google from "./img/google.png";
import Facebook from "./img/facebook.png";
import Github from "./img/github.png";
import insta from "./img/insta50.png";
import { Client_Google_URL } from "../../api/client/URL";
import { grey } from "@mui/material/colors";
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

const SigninForm = ({ switchAuthState }) => {
  const google = () => {
    window.open(`${Client_Google_URL}auth/google`, "_self");
  };

  const github = () => {
    // window.open("http://localhost:7005/auth/github", "_self");
    console.log("github click");
  };

  const facebook = () => {
    // window.open("http://localhost:7005/auth/facebook", "_self");
    console.log("facebook click");
  };

  const dispatch = useDispatch();

  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const signinForm = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "username minimum 3 characters")
        .required("username is required"),
      password: Yup.string()
        .min(3, "password minimum 3 characters")
        .required("password is required"),
    }),
    onSubmit: async (values) => {
      setErrorMessage(undefined);
      setIsLoginRequest(true);
      console.log("asdasdasdasd....signinform.jsx component dr.man ha");
      const { response, err } = await userApi.signin(values);
      setIsLoginRequest(false);

      if (response) {
        signinForm.resetForm();
        dispatch(setUser(response));
        dispatch(setAuthModalOpen(false));
        toast.success("Sign in success");
      }

      if (err) setErrorMessage(err.message);
    },
  });

  return (
    <Box component="form" onSubmit={signinForm.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          type="text"
          placeholder="email"
          name="username"
          fullWidth
          value={signinForm.values.username}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.username &&
            signinForm.errors.username !== undefined
          }
          helperText={signinForm.touched.username && signinForm.errors.username}
        />
        <TextField
          type="password"
          placeholder="password"
          name="password"
          fullWidth
          value={signinForm.values.password}
          onChange={signinForm.handleChange}
          color="success"
          error={
            signinForm.touched.password &&
            signinForm.errors.password !== undefined
          }
          helperText={signinForm.touched.password && signinForm.errors.password}
        />
      </Stack>

      <LoadingButton
        type="submit"
        fullWidth
        size="large"
        variant="contained"
        sx={{
          marginTop: 4,
          color: "primary.black",
          borderRadius: "5px",
          bgcolor: `${grey[300]}`,
          "&:hover": {
            backgroundColor: `${grey[500]}`,
            boxShadow: "none",
          },
        }}
        loading={isLoginRequest}
      >
        sign in
      </LoadingButton>

      <div>
        <div className="center">
          {/* <div className="line" /> */}
          <div className="or">OR</div>
          {/* <div className="line" /> */}


        </div>

        <div className="left">
          <div className="loginButton google" onClick={google}>
            <img src={Google} alt="" className="icon" />
            {/* {" "}Google */}
          </div>
          <div className="loginButton facebook" onClick={facebook}>
            <img src={Facebook} alt="" className="icon" />
            {/* Facebook */}
          </div>
          <div className="loginButton instagram" onClick={github}>
            <img src={insta} alt="" className="icon" />
            {/* Github */}
          </div>
        </div>
      </div>

      <Button fullWidth sx={{ marginTop: 1, color:"inherit" }} onClick={() => switchAuthState()}>
        sign up
      </Button>

      {errorMessage && (
        <Box sx={{ marginTop: 2 }}>
          <Alert severity="error" variant="outlined">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default SigninForm;
