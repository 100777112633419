import React from "react";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import { Box, Button, Stack, Typography } from "@mui/material";
import Checkout from "../components/common/menuItem/checkout/Checkout";
import { useParams } from "react-router-dom";

const CheckPOutPage = () => {
  const { operatorId } = useParams();
  // console.log({operatorId})
  return (
    <Box marginTop="0rem" sx={{ ...uiConfigs.style.mainContent }} >
      <Container header="CHECK OUT" >              
        <Checkout operatorId={operatorId}/>
      </Container>
    </Box>
  );
};

export default CheckPOutPage;
