import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Carousel } from "antd";

import { FaCode } from "react-icons/fa";
import {
  Card,
  Icon,
  Avatar,
  Col,
  Typography,
  Row,
  Button,
  Badge,
  Popover,
  List,
  Tooltip,
  Input,
} from "antd";
import axios from "axios";
// import { format } from "timeago.js";
import "./style.css";

import ImageSlider from "./ImageSlider";
import ImageSquare from "./ImageSquare";
import { base_url } from "../../adminDashboard/utils/baseUrl";

import {
  GiTrophyCup,
  GiChampions,
  GiCutDiamond,
  GiBigDiamondRing,
} from "react-icons/gi";
import {
  FaStreetView,
  FaHandshake,
  FaHandPeace,
  FaCrown,
  FaSpaceShuttle,
  FaUserCheck,
  FaMapMarkedAlt,
  FaRegShareSquare,
} from "react-icons/fa";
import {
  RiVipCrownLine,
  RiUserFollowFill,
  RiKakaoTalkLine,
  RiMapPinLine,
} from "react-icons/ri";
import { BsChatDots, BsBoxArrowDown, BsBookmarkCheck } from "react-icons/bs";
// import Colors from "../../../constant/Colors";
import { auto } from "async";
// import ImageHeader from "../../common/ImageHeader";

const { Title } = Typography;
const { Meta } = Card;
const { Search } = Input;

const view_icon = require("./logo/view.png");
const chat_icon = require("./logo/chat.png");
const map_emoji = require("./logo/map.png");
const save_emoji = require("./logo/save.png");
const share_emoji = require("./logo/share.png");

const upload_image = require("./logo/uploadimage.png");
const upload_video = require("./logo/uploadvideo.png");

const userId = localStorage.getItem("userId");

function LandingPage() {
  //----- for image ------------------------
  const [Videos, setVideos] = useState([]);
  const [SkipVideo, setSkipVideo] = useState(0);
  const [LimitVideo, setLimitVideo] = useState(10);
  const [PostSizeVideo, setPostSizeVideo] = useState();

  //------Check box, radio box-------------
  const [Filters, setFilters] = useState({
    storecategories: [],
    price: [],
    continents: [],
    score: [],
  });

  //----- for image ------------------------
  const [Products, setProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [Skip, setSkip] = useState(0);
  const [Limit, setLimit] = useState(10);
  const [PostSize, setPostSize] = useState();

  const avatar = localStorage.getItem("avatar");
  const nameStore = localStorage.getItem("name");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const variablesVideo = {
      skip: SkipVideo,
      limit: LimitVideo,
    };

    getVideos(variablesVideo);
  }, []);

  useEffect(() => {
    const variables = {
      skip: Skip,
      limit: Limit,
    };
    getProducts(variables);
  }, []);

  //--------------------- connect with interest score produced by Mako

  // Tạo hàm Get Product to Fetch data tại điểm SKIP và LIMIT

  const getProducts = (variables) => {
    axios.post(`${base_url}product/getProducts`, variables).then((response) => {
      if (response.data.success) {
        console.log(response.data.products);
        if (variables.loadMore) {
          setProducts([...Products, ...response.data.products]);
        } else {
          setProducts(response.data.products);
        }
        setPostSize(response.data.postSize);
        // console.log({PostSize})
      } else {
        alert("Failed to fectch product datas");
      }
    });
  };

  const getVideos = (variablesVideo) => {
    // axios.post("/api/video/getVideos", variablesVideo).then((response) => {
    //   if (response.data.success) {
    //     // console.log(response.data.videos)
    //     if (variablesVideo.loadMoreV) {
    //       setVideos([...Videos, ...response.data.videos]);
    //     } else {
    //       setVideos(response.data.videos);
    //     }
    //     setPostSizeVideo(response.data.postSize);
    //     // console.log({PostSizeVideo})
    //   } else {
    //     alert("Failed to fectch product datas");
    //   }
    // });
  };

  //------------user Interest score +- 20% ---

  const onLoadMore = () => {
    let skip = Skip + Limit;

    const variables = {
      skip: skip,
      limit: Limit,
      loadMore: true,
      filters: Filters,
      // searchTerm: SearchTerms
    };
    getProducts(variables);
    setSkip(skip);
  };

  const onLoadMoreVideo = () => {
    let skipV = SkipVideo + LimitVideo;

    const variablesVideo = {
      skip: skipV,
      limit: LimitVideo,
      loadMoreV: true,
      filters: Filters,
      // searchTerm: SearchTerms
    };
    console.log({ variablesVideo });
    getVideos(variablesVideo);
    setSkipVideo(skipV);
  };

  const renderCards = Videos.map((video, index) => {
    var minutes = Math.floor(video.duration / 60);
    var seconds = Math.floor(video.duration - minutes * 60);

    return (
      <Col lg={6} md={8} xs={24}>
        <Card
          hoverable
          style={{
            width: 340,
            marginTop: 15,
            background: "white",
            borderRadius: 5,
            boxShadow: "0px 1px 2px #0c6157",
          }}
          // className='video__card'
          bordered={false}
          size={"small"}
          title={
            <Meta
              avatar={
                // <Avatar src={video.writer.image} />
                // <Avatar size={40}  style={{marginTop: 5, background: '#0c6157', color: 'e3d000'}}>{video.writer.name}</Avatar>
                <Avatar
                  size={40}
                  src={video.writer.images[0]}
                  style={{
                    marginTop: 5,
                    background: "#0c6157",
                    color: "e3d000",
                  }}
                ></Avatar>

                // <div>
                // <Avatar size={50} shape="square" style={{backgroundColor: '#ebecf0', color: '#0c6157'}}>{video.writer.name}</Avatar>
                // </div>
              }
              title={video.writer.name}
              // description={
              //   <div>
              //     <Subscriibernumber
              //       userTo={video.writer._id}
              //       userFrom={localStorage.getItem("userId")}
              //     />
              //   </div>
              // }
              style={{ width: 300, marginLeft: 0, maxWidth: 300 }}
            />
          }
          actions={
            [
              // <div ><FaStreetView style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12 }}>25M</p></div>,
              // <div  ><LikeDislikes video videoId={video._id} userId={localStorage.getItem('userId')}  /></div>,
              // <div ><FaHandPeace style={{fontSize: 18, fontWeight:'bold', marginTop:10  }}/><p style={{fontSize: 12 }}>10k</p></div>,
              // <div  ><BsChatDots style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12 }}>50</p></div>,
              // <div  ><BsBookmarkCheck style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12, fontStyle:'italic' }}>save</p></div>,
              // <div  ><FaRegShareSquare style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12, fontStyle:'italic' }}>share</p></div>,
            ]
          }
          extra={
            <div>
              <Button type="link">
                {/* <Prize
                  video
                  videoId={video._id}
                  userId={localStorage.getItem("userId")}
                /> */}
              </Button>
            </div>
          }
        >
          <div style={{ position: "relative" }}>
            <a href={`/video/${video._id}`}>
              {/* <img style={{ width: '100%', height: 200 }} alt="thumbnail" src={`http://localhost:5000/${video.thumbnail}`} /> */}
              {/* <video style={{ width: '100%' }}  autoPlay={true} muted> <source src={`http://localhost:5000/${video.filePath}`}></source> </video> */}
              {/* Amazon storage */}
              <video style={{ width: "100%" }} autoPlay={true} muted>
                {" "}
                <source src={`${video.filePath}`}></source>{" "}
              </video>
              {/* Amazon storage */}

              <div
                className=" duration"
                style={{
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  margin: "4px",
                  color: "#fff",
                  backgroundColor: "rgba(17, 17, 17, 0.8)",
                  opacity: 0.8,
                  padding: "2px 4px",
                  borderRadius: "2px",
                  letterSpacing: "0.5px",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "12px",
                }}
              >
                <span>
                  {minutes} : {seconds}
                </span>
              </div>
            </a>
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "UPPERCASE",
              marginTop: 10,
              color: "#0c6157",
            }}
          >
            {video.title}{" "}
          </div>
          {/* <span > {video.views}</span> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "bottom",
            }}
          >
            <p> Interest Score: {video.score} </p>
            <span style={{ fontSize: 12, fontStyle: "Italic" }}>
              {" "}
              {/* {format(video.createdAt)}{" "} */}
            </span>
          </div>
          {/* <p>Store's Latitute: {video.latitute}</p>
                <p>Store's Longitute: {video.longitute}</p> */}

          {/* <hr style={{marginTop: '15px'}}/> */}
          <Meta
            title={
              <Row span={6}>
                <Tooltip title="Views" style={{ justifyContent: "center" }}>
                  <a
                    href="/"
                    style={{
                      marginLeft: "0px",
                      color: "#0c6157",
                      marginRight: 10,
                    }}
                  >
                    10M Views
                  </a>
                </Tooltip>
                {/* <LikeDislikes video videoId={video._id} userId={localStorage.getItem('userId')}  /> */}
                <Tooltip title="Chat with stores">
                  <a
                    href="/chat"
                    style={{ marginLeft: "10px", color: "#0c6157" }}
                  >
                    <RiKakaoTalkLine fontSize={16} /> Chat
                  </a>
                </Tooltip>
                <Tooltip title="Store location">
                  <a
                    href="/map"
                    style={{ marginLeft: "20px", color: "#0c6157" }}
                  >
                    <FaMapMarkedAlt fontSize={16} /> Location
                  </a>
                </Tooltip>
                <Tooltip title="Save">
                  <a href="/" style={{ marginLeft: "20px", color: "#0c6157" }}>
                    <BsBookmarkCheck style={{ fontSize: 16 }} /> Save
                  </a>
                </Tooltip>
                <Tooltip title="Share">
                  <a
                    href="/"
                    style={{
                      marginLeft: "20px",
                      color: "#0c6157",
                      marginRight: "20px",
                    }}
                  >
                    {" "}
                    <FaRegShareSquare style={{ fontSize: 16 }} />
                  </a>
                </Tooltip>
              </Row>
            }
            style={{ marginTop: "10px" }}
          />
        </Card>
      </Col>
    );
  });

  const renderImage = Products.map((product, index) => {
    var minutes = Math.floor(product.duration / 60);
    var seconds = Math.floor(product.duration - minutes * 60);
    return (
      <Col lg={8} md={8} xs={24}>
        {/*  style={{border: '1px blue solid'}}*/}
        {/* <ImageHeader imgPath={product.images[0]} /> */}

        <Card
          hoverable
          style={{
            width: 390,
            marginTop: 30,
            background: "white",
            borderRadius: 10,
            boxShadow: "0px 0px 0px #0c6157",
            // marginLeft: "auto",
            // marginRight: "auto"
          }}
          // className='video__card'
          bordered={false}
          size={"small"}
          // hoverable={true}
          // cover={

          // }

          title={
            <Meta
              avatar={
                // <Avatar src={product.writer.name} />
                <Avatar src={""}></Avatar>
                // <div>
                // <Avatar size={50} shape="square" style={{backgroundColor: '#ebecf0', color: '#0c6157'}}>{video.writer.name}</Avatar>
                // </div>
              }
              title={product.writer.name}
              description={
                <div style={{ top: "25px", position: "absolute" }}>
                  {/* <Subscriibernumber
                    userTo={product.writer._id}
                    userFrom={localStorage.getItem("userId")}
                  /> */}
                </div>
              }
              style={{ width: 300, marginLeft: 0, maxWidth: 300 }}
            />
          }
          actions={
            [
              // <div ><FaStreetView style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12 }}>25M</p></div>,
              // <div  ><LikeDislikes video videoId={video._id} userId={localStorage.getItem('userId')}  /></div>,
              // <div ><FaHandPeace style={{fontSize: 18, fontWeight:'bold', marginTop:10  }}/><p style={{fontSize: 12 }}>10k</p></div>,
              // <div  ><BsChatDots style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12 }}>50</p></div>,
              // <div  ><BsBookmarkCheck style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12, fontStyle:'italic' }}>save</p></div>,
              // <div  ><FaRegShareSquare style={{fontSize: 18, fontWeight:'bold' }}/><p style={{fontSize: 12, fontStyle:'italic' }}>share</p></div>,
            ]
          }
          extra={
            <div>
              <Button type="link">
                {/* <div>  <Popover placement="topLeft" content="Verified"><VscVerified  style={{fontSize: 20, fontWeight:'bold', marginTop:10, color: 'green'  }}/></Popover></div>, */}
                <Tooltip title="Gold reward" color="green">
                  <GiTrophyCup
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      marginTop: 10,
                      color: "#e3d000",
                    }}
                  />
                </Tooltip>
                ,
                {/* // <div ><GiCutDiamond  style={{fontSize: 20, fontWeight:'bold', marginTop:10, color: 'black'  }}/><p style={{fontSize: 12, color: 'black', fontWeight:'bold',backgroundColor:'white' }}>DIA</p></div>,
                                        // <div ><FaCrown  style={{fontSize: 20, fontWeight:'bold', marginTop:10, color: 'black'  }}/><p style={{fontSize: 12, color: '#bbc2cc', fontWeight:'bold',backgroundColor:'black' }}>PLATINUM</p></div>,
                                        // <div ><GiBigDiamondRing  style={{fontSize: 20, fontWeight:'bold', marginTop:10, color: 'green',  }}/><p style={{fontSize: 12, color: 'green', fontWeight:'bold' }}>MIRA</p></div>, */}
              </Button>
            </div>
          }
        >
          {product.continents === 0 ? (
            <div style={{ position: "relative" }}>
              <a href={`/product/${product._id}`}>
                {/* Amazon storage */}
                <video style={{ width: "100%" }} autoPlay={true} muted>
                  {" "}
                  <source src={`${product.filePath}`}></source>{" "}
                </video>
                {/* Amazon storage */}

                <div
                  className=" duration"
                  style={{
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    margin: "4px",
                    color: "#fff",
                    backgroundColor: "rgba(17, 17, 17, 0.8)",
                    opacity: 0.8,
                    padding: "2px 4px",
                    borderRadius: "2px",
                    letterSpacing: "0.5px",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "12px",
                  }}
                >
                  <span>
                    {minutes} : {seconds}
                  </span>
                </div>
              </a>
            </div>
          ) : (
            <a href={`/product/${product._id}`}>
              {" "}
              <ImageSlider images={product.images} />
              {/* <ImageSquare image={product.images} /> */}
            </a>
          )}
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "capitalize",
              marginTop: 0,
              color: "black",
            }}
          >
            {product.title}{" "}
          </div>
          <span style={{ fontSize: 12, fontStyle: "Italic" }}>
            {" "}
            {/* {format(product.createdAt)}{" "} */}
          </span>
          {/* <p>Store's Latitute: {product.latitute}</p>
            <p>Store's Longitute: {product.longitute}</p> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "bottom",
            }}
          >
            {/* <p> Interest Score: {product.score} </p> */}
            <p className="description"> {product.description} </p>
          </div>
          <Meta
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <a href="/" style={{ padding: 5, color: "#0c6157" }}>
                  <img src={view_icon} />
                </a>
                {/* { userId !== "" ? 
                                <View videoId={product._id} userId={localStorage.getItem('userId')}/> : null} */}

                <a
                  href={`/chat/${product.writer.name}`}
                  style={{ padding: 5, color: "#0c6157" }}
                >
                  <img src={chat_icon} />
                </a>
                <a href="/map" style={{ padding: 5, color: "#0c6157" }}>
                  <img src={map_emoji} />
                </a>
                <a href="/chat" style={{ padding: 5, color: "#0c6157" }}>
                  <img src={save_emoji} />
                </a>
                <a href="/chat" style={{ padding: 5, color: "#0c6157" }}>
                  <img src={share_emoji} />
                </a>
                {/* <LikeDislikes product videoId={product._id} userId={localStorage.getItem('userId')}  /> */}
              </div>
            }
            style={{
              paddingBottom: "10px",
              borderBottom: "0.5px solid lightgrey",
            }}
          />
        </Card>
      </Col>
    );
  });

  // ----------- on Interest Score ---------------------

  const userScore = localStorage.getItem("interest score");
  const [InterestScore, setInterestScore] = useState([
    parseInt(userScore) - 0.8 * parseInt(userScore),
    parseInt(userScore) + 0.8 * parseInt(userScore),
  ]);
  // console.log(InterestScore)

  // ----------- end of on Interest Score --------------

  const handleScore = (value) => {
    let array = [];

    array = InterestScore;

    console.log("InterestScore", array);
    return array;
  };

  // const handlePrice = (value) => {
  //   const data = price;
  //   let array = [];

  //   for (let key in data) {
  //     console.log({ key });
  //     console.log({ value });

  //     if (data[key]._id === parseInt(value, 10)) {
  //       array = data[key].array;
  //     }
  //   }
  //   console.log("array", array);
  //   return array;
  // };

  // const showFilteredResults = (filters) => {
  //   const variables = {
  //     skip: 0,
  //     limit: Limit,
  //     filters: filters,
  //   };
  //   getProducts(variables);
  //   setSkip(0);
  // };

  // const handleFilters = (filters, category) => {
  //   console.log(filters);

  //   const newFilters = { ...Filters };

  //   newFilters[category] = filters;

  //   if (category === "price") {
  //     let priceValues = handlePrice(filters);
  //     newFilters[category] = priceValues;
  //   }

  //   if (category === "score") {
  //     let scoreValues = handleScore(filters);
  //     newFilters[category] = scoreValues;
  //   }

  //   console.log({ newFilters });

  //   showFilteredResults(newFilters);
  //   setFilters(newFilters);
  // };

  return (
    <div className="page__container">
      <Carousel autoplay dots>
        {Products.map((product, index) => (
          <div key={index}>
            <ImageSquare
              // image={product.images}
              title={product.title}
              desc={product.description}
              image={product.images}
            />
          </div>
        ))}
      </Carousel>
      <div className="sectionCenter">
        {role === "1" ? (
          <Card
            style={{ width: 390, borderRadius: 5 }}
            title={
              <div>
                <Avatar
                  size="large"
                  src={avatar}
                  style={{ border: "1px solid lightgray" }}
                />
                <span
                  style={{ marginLeft: 20 }}
                >{`Create your post ${nameStore} ?`}</span>
              </div>
            }
            size="small"
            bordered={false}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <a href="/product/upload" style={{ marginRight: 20 }}>
                <img src={upload_image} />
              </a>
              <a href="/video/upload">
                <img src={upload_video} />
              </a>
            </div>
          </Card>
        ) : null}

        <Row gutter={[16, 16]}>
          {/* <CheckBox
                                    list={storecategories}
                                    handleFilters={filters => handleFilters(filters, "continents")}
                                /> */}

          <Col lg={9} xs={24}>
            {/* <RadioBox
                        list={price}
                        handleFilters={filters => handleFilters(filters, "price")}
                    /> */}
          </Col>
        </Row>

        {/* <CheckBox 
                handleFilters={filters => handleFilters(filters, "storecategories")}
                handleFilters={filters => handleFilters(filters, "continents")}
            />

            <Radiobox /> */}

        <Row gutter={16}>
          {/* {renderCards} */}
          {renderImage}
        </Row>
        <br />
        <br />
        {PostSize >= Limit && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={onLoadMore}
              type="link"
              style={{
                color: "#0c6157",
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              Load More...
            </Button>
          </div>
        )}

        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={(filters) => handleFilters(filters, "score")}
            type="link"
            style={{ color: "#0c6157", fontStyle: "italic", fontSize: "16px" }}
          >
            interest Score...
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default LandingPage;
