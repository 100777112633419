import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import tmdbConfigs from "../api/configs/tmdb.configs";
import mediaApi from "../api/modules/media.api";
import uiConfigs from "../configs/ui.configs";
import HeroSlide from "../components/common/HeroSlide";
import MediaGrid from "../components/common/MediaGrid";
import { setAppState } from "../redux/features/appStateSlice";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import { toast } from "react-toastify";
import usePrevious from "../components/common/messageTyping/hooks/usePrevious";

import { listtext } from "../data/datatext";
import CategoryText from "../components/common/CategoryText";
import TextGrip from "../components/common/TextGrid";

const allCategoriesText = [
  "Recommended",
  ...new Set(listtext.map((item) => item.category)),
];

// console.log({ allCategoriesText });

const TextList = () => {
  const [textItems, setTextItems] = useState(listtext);
  const [activeCategoryText, setActiveCategoryText] = useState("Today");
  const [categories, setCategories] = useState(allCategoriesText);

  const filterItems = (category) => {
    setActiveCategoryText(category);
    if (category === "Recommended") {
      setTextItems(listtext);
      return;
    }
    const newItems = listtext.filter((item) => item.category === category);
    setTextItems(newItems);

  };

  // console.log({activeCategoryText})


  return (
    <Box>
        <CategoryText
          categories={categories}
          activeCategory={activeCategoryText}
          filterItems={filterItems}
        />

        <TextGrip medias={textItems} />
    </Box>
  );
};

export default TextList;
