import React from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import uiConfigs from "../../../configs/ui.configs";
import Container from "../../common/Container";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "center",
}));

const MainQuotes = () => {
  return (
    <Box bgcolor={"primary.black"}>
      <Box sx={{ ...uiConfigs.style.mainContent }}>
        <Item>
          <Typography
            variant="h6"
            color={"primary.white"}
            p={{ xs: 3, md: 5 }}
            fontWeight={500}
            fontSize={{xs: 18, md: 24}}
            sx={{
              letterSpacing: "2px",
              lineHeight: { xs: "2rem", md: "3rem" },
            }}
          >
            Lokalai is an innovative IT software service company that aims to
            revolutionise the way retail shops and their customers interact. We
            use advanced information technology such as digital artificial intelligent,
            blockchain  to supercharge local retail trade
            enterprises.
          </Typography>
        </Item>
      </Box>
    </Box>
  );
};

export default MainQuotes;
