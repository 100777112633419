import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {deepPurple, red, blue, blueGrey, purple, lightBlue, pink, green, amber, brown, orange, grey, deepOrange } from '@mui/material/colors';
import useMediaQuery from "@mui/material/useMediaQuery";
import uiConfigs from '../../../configs/ui.configs';
import { baseURL } from "../../../api/client/URL";
import Axios from "axios";
import { toast } from "react-toastify";
import Loading3 from "../../Loading/Loading3";
import { useNavigate } from "react-router-dom";

const GridEndSoon = ({data}) => {
    // console.log({data})
    const isNonMobile = useMediaQuery("(min-width:600px)");
    
  const [endSoon, setEndSoon] = useState([]);
  // console.log({ endSoon });
  const [dataSuccess, setDataSuccess] = useState(false);
  const navigate = useNavigate();

    useEffect(() => {
    const endsoon = {
      _id: data,
    };
    Axios.post(`${baseURL}api/coupons/getCouponItems`, endsoon).then(
      (response) => {
        if (response.data.success) {
          toast.success("get Ending soon coupon item");
          setEndSoon(response.data.couponItems);
          setDataSuccess(true);
        } else {
          alert("Failed to get coupon item");
        }
      }
    );

  }, []);


  var min = 0;
  var max = 5;
  var random = Math.round(min + Math.random() * (max - min));

  console.log({random})

  if (dataSuccess) {
     return (
    <>
    <Box
    mt={0}
    display="grid"
    gap="20px"
    gridTemplateColumns="repeat(3, minmax(0, 1fr))"
  >
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 2" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"}>
          <img src={endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
              <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length].category} </Typography>
                <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length].deal}</Button>
             </Box> 
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length].title}</Typography>
              <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}} > {endSoon[random + 0 < endSoon.length ? random + 0 : random + 0 - endSoon.length].desc}</Typography>
          </Box>
      </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"} >
          <img src={endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
              <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length].category} </Typography>
                <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length].deal}</Button>
             </Box> 
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"left"} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length].title}</Typography>
              <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}} > {endSoon[random + 1 < endSoon.length ? random + 1 : random + 1 - endSoon.length].desc}</Typography>
          </Box>
      </Box>
  </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length]._id}`)}

    >
      <Box display={"flex"} flexDirection={"column"} >
          <img src={endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
          <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
              <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length].category} </Typography>
                <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length].deal}</Button>
             </Box> 
              <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} textAlign={"left"} sx={{...uiConfigs.style.typoLines(2)}}>  {endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length].title}</Typography>
              <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}} > {endSoon[random + 2 < endSoon.length ? random + 2 : random + 2 - endSoon.length].desc}</Typography>
          </Box>
      </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1"  }}
      onClick={() => navigate(`/coupons/${endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length]._id}`)}

    >
    <Box display={"flex"} flexDirection={"column"}>
            <img src={endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
            <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                    <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length].category} </Typography>
                    <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length].deal}</Button>
                </Box> 
                <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length].title}</Typography>
                <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}}> {endSoon[random + 3 < endSoon.length ? random + 3 : random + 3 - endSoon.length].desc}</Typography>
            </Box>
        </Box>
    </Box>
    <Box
      height={400}      
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length]._id}`)}

    >
    <Box display={"flex"} flexDirection={"column"}>
            <img src={endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
            <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                    <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length].category} </Typography>
                    <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length].deal}</Button>
                </Box> 
                <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length].title}</Typography>
                <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}}> {endSoon[random + 4 < endSoon.length ? random + 4 : random + 4 - endSoon.length].desc}</Typography>
            </Box>
        </Box>
    </Box>
    <Box
      height={100}
       sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
       onClick={() => navigate(`/coupons/${endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length]._id}`)}

    >
    <Box display={"flex"} flexDirection={"column"}>
            <img src={endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
            <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                    <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length].category} </Typography>
                    <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length].deal}</Button>
                </Box> 
                <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length].title}</Typography>
                <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}} > {endSoon[random + 5 < endSoon.length ? random + 5 : random + 5 - endSoon.length].desc}</Typography>
            </Box>
        </Box>
    </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length]._id}`)}

    >
    <Box display={"flex"} flexDirection={"column"}>
            <img src={endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
            <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                    <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length].category} </Typography>
                    <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length].deal}</Button>
                </Box> 
                <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length].title}</Typography>
                <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}} > {endSoon[random + 6 < endSoon.length ? random + 6 : random + 6 - endSoon.length].desc}</Typography>
            </Box>
        </Box>
  </Box>
    <Box
      height={400}
      sx={{ gridColumn: !isNonMobile ? "span 3" : "span 1" }}
      onClick={() => navigate(`/coupons/${endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length]._id}`)}

    >
    <Box display={"flex"} flexDirection={"column"}>
            <img src={endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length].images[0]} height={200} style={{objectFit:"cover",filter: "brightness(80%)" }} />
            <Box bgcolor={grey[100]} height={200} display={"flex"} alignItems={"start"} justifyContent={"start"} flexDirection={"column"}>
                <Box display={"flex"} width={"100%"} justifyContent={"space-between"} p={1} mt={-7} zIndex={10} alignItems={"end"}>
                    <Typography color={"secondary.white"} p={0} sx={{ mt:1, fontWeight: 700, display:"flex", justifyContent:"space-between", textDecoration:"underline"}}>{endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length].category} </Typography>
                    <Button  variant="contained" sx={{color:"secondary.black", border:"1px solid gray", backgroundColor:"secondary.white"}}> {endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length].deal}</Button>
                </Box> 
                <Typography variant="h6" color={"secondary.black"} p={1} fontWeight={500} sx={{...uiConfigs.style.typoLines(2)}}> {endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length].title}</Typography>
                <Typography variant="body2" color={"secondary.black"} px={1} sx={{...uiConfigs.style.typoLines(3)}}> {endSoon[random + 7 < endSoon.length ? random + 7 : random + 7 - endSoon.length].desc}</Typography>
            </Box>
        </Box>
  </Box>
    
    </Box>
    <Typography variant="body2" color={"secondary.black"} px={1} mt={2} mb={5} display={"flex"} > Find more <a href='#' style={{textDecoration: "underline", fontWeight: 700, margin:"0 5px ", color:"#000"}}>{" "} Deals ending soon {" "}</a> at Lokalai</Typography>
    </>
  )
  } else {
    <Loading3 />
  }
 
}

export default GridEndSoon