import { Box, useTheme, Typography } from "@mui/material";
import uiConfigs from "../../configs/ui.configs";

const ImageHeaderSpecial = ({ imgPath, text, height }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative", textAlign: "center" }}>
      <Box
        sx={{
          zIndex: "-1",
          position: "relative",
          paddingTop: { xs: "50%", sm: "40%", md: "12%" },
          textAlign: "center",
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundImage: `url(${imgPath})`,
          backgroundAttachment: "fixed",
          "&::before": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            ...uiConfigs.style.darkerbgimage[theme.palette.mode],
          },
        }}
      ></Box>
      {text && (
        <Box
          sx={{
            position: "relative",
            paddingX: { xs: "20px", md: 0 },
            maxWidth: "1366px",
            marginX: "auto",
            width: "100%",
            "&::before": {
              content: '""',
              position: "absolute",
              left: "50%",
              top: {xs: "-70px", sm: "-70px"},
              height: "5px",
              width: {xs: "100px", sm: "150px"},
              backgroundColor: "secondary.main",
              transform: "translate(-50%, 100%)",
            },
          }}
        >
          <Typography
            variant="h1"
            color="#fff"
            fontWeight={{ xs: 500, sm: 700 }}
            border={0}
            width={{xs: 300, sm: 1366}}
            sx={{
              position: "absolute",
              top: {xs: "-150px", sm: "-220px"},
              left: "50%",
              textAlign: "center",
              transform: "translate(-50%, 100%)",
            }}
            fontSize={{ xs: "2rem", sm: "3.5rem" }}
          >
            {text}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ImageHeaderSpecial;
