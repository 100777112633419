import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import AddressForm from "./AddressForm";
import { useEffect, useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { Divider, Table } from "antd";
const Shipping = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  // console.log({values})
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const [submitted, setSubmitted] = useState(false);
  const handleEdited = async (values, action) => {
    await sleep(1000);
    setSubmitted(!submitted);
  };

  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    return (
      values.billingAddress.firstName.length &&
      values.billingAddress.lastName.length && 
      values.billingAddress.city.length && 
      values.billingAddress.country.length &&
      values.billingAddress.email.length &&
      values.billingAddress.phoneNumber.length &&
      values.billingAddress.street1.length &&
      values.billingAddress.street2.length &&
      values.billingAddress.zipCode.length 
    );
  };

  useEffect(() => {
    const isValid = validate();
    setIsValid(!isValid);
  });

  return (
    <Box m="30px auto" border={0}>
      {/* BILLING FORM */}
      <Box border={0}>
        <Typography
          sx={{ mb: "15px" }}
          fontSize="18px"
          fontWeight={700}
          textTransform={"uppercase"}
          color={"primary.contrastText"}
          display={"flex"}
        >
          <AccountBoxOutlinedIcon sx={{ mr: 1 }} /> CONTACT
        </Typography>

        {!submitted && (
          <AddressForm
            type="billingAddress"
            values={values.billingAddress}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        )}
      </Box>
      {submitted && (
        <Box>
          <Typography fontWeight={600}>
            {values.billingAddress.firstName}
            {"  "}
            {values.billingAddress.lastName}
          </Typography>
          <Typography>
            <small>E-mail:</small> {values.billingAddress.email}
          </Typography>
          <Typography>
            <small>Telephone:</small> {values.billingAddress.phoneNumber}
          </Typography>
          <Typography>
            <small>Address:</small> {values.billingAddress.street1}
            {","}
          </Typography>
          <Typography>
            {"           "}
            {values.billingAddress.city}
            {",  "}
            {values.billingAddress.country}
          </Typography>
        </Box>
      )}

      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={isValid}
        sx={{
          backgroundColor: "primary.main",
          boxShadow: "none",
          color: "white",
          borderRadius: 0,
          padding: "15px 40px",
          mt: "20px",
        }}
        onClick={handleEdited}
      >
        {submitted ? (
          <Typography display={"flex"} color={"#fff"}>
            {" "}
            <BorderColorOutlinedIcon sx={{ mr: 1 }} /> EDIT CONTACT INFO
          </Typography>
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
};

export default Shipping;
