import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { colors } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uiConfigs from "../../../configs/ui.configs";
import Button from "@mui/material/Button";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { Box, Chip, Divider, Stack } from "@mui/material";
import BookingHotDeal from "../BookingHotDeal";
import HighlightCastSlide from "../HighlightCastSlide";
import DealCastSlide from "../DealCastSlide";
import { useNavigate } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function OperatorItem({ media }) {
  // console.log({ media });
  const {
    name,
    img,
    desc,
    location,
    shop,
    shopImg,
    photo,
    images,
    id,
    follower,
    deal,
    views,
    _id,
  } = media;
  const navigate = useNavigate();

  // console.log(media)
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{ width: "100%", maxWidth: "390px", margin: "0 auto" }}
      onClick={() => navigate(`/operator/${_id}`)}
      sx={{ cursor: "pointer" }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: colors.grey["300"], fontWeight: "600" }}
            aria-label="recipe"
            src={photo}
          />
        }
        title={
          <Typography
            sx={{
              ...uiConfigs.style.typoLines(1, "left"),
              color: "secondary.contrastText",
              fontWeight: "700",
            }}
          >
            {name}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2" color="primary.contrastText">
            {follower} followers
          </Typography>
        }
      />
      {/* <DealCastSlide casts={media.images}/> */}
    </Card>
  );
}
