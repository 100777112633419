import { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Typography, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades } from "../theme";
import { addToCart } from "../state";
import { useNavigate } from "react-router-dom";

const Item = ({ item, width }) => {
  // console.log({item})
  // console.log({width})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const {
    palette: { neutral },
  } = useTheme();

  const { category, price, name, image, longDescription } = item.attributes;
  const {
    data: {
      attributes: {
        formats: {
          medium: { url },
        },
      },
    },
  } = image;

  return (
    <Box
      width={width}
      // border="1px solid green"
      display="grid"
      gridTemplateColumns="1fr 3fr"
      borderBottom="0.5px dotted hsl(210, 22%, 49%)"
    >
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <img
          alt={item.name}
          // width="auto"
          justifyContent="center"
          height="100px"
          src={`http://localhost:1337${url}`}
          onClick={() => navigate(`item/${item.id}`)}
          style={{ cursor: "pointer" }}
        />
        <Box
          display={isHovered ? "block" : "none"}
          position="absolute"
          bottom="10%"
          left="0"
          width="100%"
          padding="0 5%"
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              backgroundColor={shades.neutral[100]}
              borderRadius="3px"
            >
              <IconButton onClick={() => setCount(Math.max(count - 1, 1))}>
                <RemoveIcon />
              </IconButton>
              <Typography color={shades.primary[300]}>{count}</Typography>
              <IconButton onClick={() => setCount(count + 1)}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              onClick={() => {
                dispatch(addToCart({ item: { ...item, count } }));
              }}
              sx={{ backgroundColor: shades.primary[300], color: "white" }}
            >
              Add to Cart
            </Button>
          </Box>
        </Box>
      </Box>

      <Box ml="5px">
        <header
          style={{
            display: "flex",
            justifyContent: "space-between",

            alignItems: "center",
          }}
        >
          <Typography color="black">
            <h4>{name}</h4>
          </Typography>
          <Typography fontWeight="bold" color="#c59d5f">
            ${price}
          </Typography>
        </header>
        {/* <Typography variant="subtitle2" color={neutral.dark}> */}
        {/* <Typography variant="subtitle2" margin-bottom="0" >
          {category
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())}
        </Typography> */}
        <Typography
            className="limitedtext" //index.css global css
            fontSize="0.8rem"
            paddingTop="0.5rem"
            color="hsl(210, 22%, 49%)"
            mb="1.25rem"
        >
          {longDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default Item;
