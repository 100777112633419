import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../api/client/URL";
import { toast } from "react-toastify";
import Loading3 from "../components/Loading/Loading3";
import ImageHeader from "../components/common/ImageHeader";
import DetailGripImages from "../components/common/DetailGripImages";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import uiConfigs from "../configs/ui.configs";
import Container from "../components/common/Container";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { LoadingButton } from "@mui/lab";

//SUPPORT
import LikeDislikes from "../components/common/productsupport/LikeDislikes";
import Subscriber from "../components/common/productsupport/Subscriber";
import View from "../components/common/productsupport/View";

import Avatar from "@mui/material/Avatar";
//CARD DESCRIPTION
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useDispatch, useSelector } from "react-redux";

import BestDealVideoSlide from "../components/common/BestDealVideoSlide";
import BestDealBackdropSlide from "../components/common/BestDealBackdropSlide";
import HighlightCastSlide from "../components/common/HighlightCastSlide";
import Quotes from "../components/common/quotes/Quotes";

import {
  deepPurple,
  red,
  blue,
  blueGrey,
  purple,
  lightBlue,
  pink,
  green,
  amber,
  brown,
  orange,
  grey,
  deepOrange,
} from "@mui/material/colors";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CouponDetailItem() {
  // window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { couponId } = useParams();
  const [coupon, setCoupon] = useState([]);
  console.log({ coupon });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const getSingleCouponUrl = `${baseURL}api/coupons/${couponId}`;
    console.log({ getSingleCouponUrl });
    axios.get(getSingleCouponUrl).then((response) => {
      if (response.data.success) {
        setCoupon(response.data.coupon);
        toast.success("Successfull to get detail couon");
      } else {
        toast.error("Failed to get single product...Dr. Man Ha");
      }
    });
  }, []);
  console.log({ couponId });

  const {
    images,
    title,
    desc,
    location,
    phone,
    open_hours,
    videos,
    operator,
    _id,
    logo,
    operatorname,
    updatedAt,
  } = coupon;

  const [value, setValue] = React.useState(0);

  if (coupon.operator) {
    return (
      <>
        <>
          <ImageHeader imgPath={images[0]} />
          <div>
            <DetailGripImages images={images} title={title} />
          </div>
          <Box
            sx={{
              color: "primary.contrastText",
              ...uiConfigs.style.mainContent,
            }}
          >
            <Box display="flex" flexWrap="wrap" columnGap="40px">
              {/* ACTIONS */}
              <Box flex="1 1 70%" mb="40px">
                {/* <Box display="flex" justifyContent="space-between"> */}
                <Typography
                  variant="h4"
                  fontSize={{ xs: "1.5rem", md: "1.5rem", lg: "3rem" }}
                  fontWeight="700"
                  color="secondary.contrastText"
                  m="1.5rem 0"
                  sx={{ ...uiConfigs.style.typoLines(2, "left") }}
                >
                  {title}
                </Typography>
                {/* SUPORT SECTION */}

                <Box
                  display="flex"
                  flexDirection={{ xs: "column", md: "row", lg: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "start", md: "center", lg: "center" }}
                  marginBottom="1rem"
                  // border= "1px solid red"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="10px"
                      onClick={() => navigate(`/operator/${operator}`)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Avatar src={logo} sx={{ margin: "0 10px" }} />
                    </Box>
                    {user && (
                      <Subscriber
                        userTo={operator}
                        operatorname={operatorname}
                      />
                    )}
                  </Box>
                  {user && <LikeDislikes video product={_id} title={title} />}
                </Box>
                {/* DETAIL SECTION EXPANDED BY CLICKED */}
                <Card>
                  <CardContent>
                    <span style={{ fontSize: "0.8rem", fontWeight: "600" }}>
                      <View userTo={_id} date={updatedAt} />
                      {/* 1.8K views 4 hours ago */}
                    </span>
                    <Typography variant="body2" color={lightBlue[900]}>
                      #kaituna #Grade5 #cascadas
                    </Typography>
                    <LoadingButton
                      sx={{
                        minWidth: "80px",
                        "& .MuiButon-starIcon": { marginRight: "0" },
                        color: "primary.contrastText",
                        textTransform: "lowercase",
                        // backgroundColor: `${isLike ? "primary.main": "transparent"}`
                      }}
                      onClick={handleExpandClick}
                    >
                      {`${expanded ? "show less" : "show more"}`}
                    </LoadingButton>
                  </CardContent>

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box m="0 auto">
                      <Box
                        sx={{
                          flexGrow: 1,
                          maxWidth: { xs: 390, sm: 800 },
                          bgcolor: "background.paper",
                        }}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="scrollable"
                          //   centered
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          TabIndicatorProps={{
                            sx: { backgroundColor: "secondary.black" },
                          }}
                          sx={{
                            "& button": { borderRadius: "5px" },
                            "& button:hover": {
                              backgroundColor: "primary.white",
                              fontWeight: 700,
                            },
                            "& button:active": {
                              color: "secondary.black",
                              fontWeight: 900,
                            },
                            "& button:focus": {
                              color: "secondary.black",
                              fontWeight: 700,
                            },
                            "& button.Mui-selected": {
                              color: "secondary.black",
                            },
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { display: "none" },
                            },
                          }}
                        >
                          <Tab label="DESCRIPTION" {...a11yProps(0)} />
                          <Tab label="CHAT" {...a11yProps(1)} />
                          <Tab label="REVIEWS" {...a11yProps(4)} />
                          <Tab label="LOCATION" {...a11yProps(5)} />
                          <Tab label="ADDRESS" {...a11yProps(6)} />
                        </Tabs>
                      </Box>
                    </Box>
                    <Box display="flex">
                      {value === 0 && (
                        <Box>
                          <Box mt="1rem" sx={{ color: "inherit" }}>
                            <Stack spacing={5} padding="1rem">
                              <Typography
                                variant="body1"
                                sx={{ ...uiConfigs.style.typoLines(10), color:'primary.black' }}
                              >
                                {desc}
                              </Typography>
                              <Stack direction="row" spacing={1}>
                                <LoadingButton
                                  variant="text"
                                  sx={{
                                    width: "max-content",
                                    "& .MuiButon-starIcon": {
                                      marginRight: "0",
                                    },
                                    color: `${lightBlue[900]}`,
                                  }}
                                  size="large"
                                  startIcon={<FavoriteBorderOutlinedIcon />}
                                  loadingPosition="start"
                                  //   loading={onRequest}
                                  // onClick={onFavoriteClick}
                                />
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "max-content",
                                    bgcolor: `${grey[800]}`,
                                    color: "white",
                                    '&:hover':{
                                      backgroundColor: '#ffffff',
                                      boxShadow: 'none',
                                      color:`${grey[800]}`,
                                      border:`1px solid ${grey[800]}`
                                    }
                                  }}
                                  size="large"
                                  startIcon={<PlayArrowIcon />}
                                  // onClick={() => bookRef.current.scrollIntoView()}
                                >
                                  Add to cart
                                </Button>
                              </Stack>
                              <Container header="Hightlight">
                                <HighlightCastSlide casts={images} />
                              </Container>
                            </Stack>
                          </Box>
                        </Box>
                      )}
                      {value === 1 && (
                        <div>
                          <Box m="25px auto">
                            <Stack
                              spacing={2}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ForumTwoToneIcon
                                fontSize="large"
                                sx={{ color: "primary.main" }}
                              />
                              <Typography variant="h5">
                                Instant messages
                              </Typography>
                              <Typography variant="body2" textAlign="center">
                                Customers can instant chat to business owner and
                                receive respone at time. Expected to complete
                                May 2023
                              </Typography>
                              <img
                                src="https://cdn2.hubspot.net/hubfs/78084/images/Under%20construction.jpg"
                                width={300}
                              />
                            </Stack>
                          </Box>
                        </div>
                      )}
                      {value === 2 && (
                        <Box m="25px auto">
                          <Stack
                            spacing={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5">Reviews</Typography>
                            <img
                              src="https://jpauckland.org.nz/wp-content/uploads/2021/01/Screenshot-2021-01-11-at-7.00.00-PM.png"
                              width={300}
                            />
                            <Typography variant="body2" textAlign="center">
                              This module is under construction. Expected to
                              complete May 2023
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      {value === 3 && (
                        <a
                          target="_blank"
                          href={"https://maps.google.com/?q=" + location}
                          style={{
                            textDecoration: "none",
                            margin: "2rem auto",
                            color:`${grey[900]}`
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={`${grey[900]}`}
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                          </svg>
                          Click here
                        </a>
                      )}
                      {value === 4 && (
                        <Box>
                          <Box
                            display="flex"
                            // flexDirection="column"
                            flexDirection={{ xs: "column", md: "row" }}
                            width="100%"
                          >
                            <Box m="15px" flex="1 1 25%">
                              <Typography
                                variant="h5"
                                fontSize={{ xs: "1rem", md: "1.2rem" }}
                              >
                                Address
                              </Typography>
                              <Typography sx={{ mb: "0px", color:'primary.black' }}>
                                {location}
                              </Typography>
                            </Box>
                            <Box m="15px" flex="1 1 25%">
                              <Typography
                                variant="h5"
                                fontSize={{ xs: "1rem", md: "1.2rem" }}
                              >
                                Phone
                              </Typography>
                              <Typography sx={{ mb: "0px", color:'primary.black' }}>
                                {phone}
                              </Typography>
                            </Box>
                            <Box m="15px" flex="1 1 50%">
                              <Typography
                                variant="h5"
                                fontSize={{ xs: "1rem", md: "1.2rem" }}
                              >
                                Open hours
                              </Typography>
                              <Typography sx={{ mb: "0px", color:'primary.black' }}>
                                {open_hours}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Card>
              </Box>

              {/* BOOKING CARD */}
              {/* <div ref={bookRef} style={{ paddingTop: "4rem" }}> */}
              <Box
                flex="1 1 25%"
                mb="40px"
                // border="1px solid green"
                sx={{
                  marginTop: { xs: "0rem", lg: "10.5rem" },
                  zIndex: 2,
                }}
              >
                <Quotes />
              </Box>
              {/* </div> */}
            </Box>
            {/* media videos */}
            {videos.length > 0 && (
              <Container header="Videos">
                <BestDealVideoSlide videos={videos} />
              </Container>
            )}
            {/* media videos */}

            {/* media backdrop */}
            {images.length > 0 && (
              <Container header="pictures">
                <BestDealBackdropSlide backdrops={images} />
              </Container>
            )}
          </Box>
        </>
      </>
    );
  } else {
    return (
      <div>
        <Loading3 />
      </div>
    );
  }
}
export default CouponDetailItem;
