import { Box, useTheme, Typography } from "@mui/material";
import uiConfigs from "../../configs/ui.configs";

const ImageHeader = ({ imgPath, text }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative", textAlign:'center' }}>
      <Box
        sx={{
          zIndex: "-1",
          position: "relative",
          paddingTop: { xs: "80%", sm: "40%", md:"17.5%" },
          textAlign:'center',
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundImage: `url(${imgPath})`,
          backgroundAttachment: "fixed",
          "&::before": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            ...uiConfigs.style.darkerbgimage[theme.palette.mode],
          },
        }}
      ></Box>
      <Typography
        variant="h1"
        color="#fff"
        fontWeight={{xs:500, sm: 700}}
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          textAlign: "center",
          transform:'translate(-50%, 100%)'
        }}
        fontSize={{ xs: "2.5rem", sm: "5rem" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ImageHeader;
