import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  IconButton,
  easing,
  useTheme,
  useMediaQuery,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { grey } from "@mui/material/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import "./datepicker.css";

//Radio
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Badge from "@mui/material/Badge";

import Shipping from "./Shipping";
import { Formik } from "formik";
import * as yup from "yup";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useNavigate } from "react-router-dom";

const BookingSteps = ({ operatorId }) => {
  //Step action
  const steps = ["Booking", "Your Detail", "Summary"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // DatePicker function
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [startDate, setStartDate] = useState(new Date());
  // console.log("startDate :", startDate);

  const changeDate = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    setStartDate(date);
    console.log({ startDate });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="calendar" onClick={onClick}>
      <input type="text" className="form-item" value={value} />
      <i className="bi bi-calendar-week"></i>
    </div>
  );

  // convert selected date
  let date = String(startDate);
  date = date.substring(0, date.length - 45);

  //People Booking
  const [customer, setCustomer] = useState(0);

  //Purposebooking
  const [purpose, setPurpose] = useState("Evening");

  const bookingTime = [
    { key: 1, value: "5:00pm", discount: "20% off" },
    { key: 2, value: "5:15pm", discount: "10% off" },
    { key: 3, value: "5:30pm", discount: "10% off" },
    { key: 4, value: "5:45pm", discount: 0 },
    { key: 5, value: "6:15pm", discount: 0 },
    { key: 6, value: "6:30pm", discount: 0 },
    { key: 7, value: "6:45pm", discount: 0 },
    { key: 8, value: "7:00pm", discount: 0 },
    { key: 9, value: "7:15pm", discount: 0 },
    { key: 10, value: "7:30pm", discount: 0 },
    { key: 11, value: "7:45pm", discount: 0 },
    { key: 12, value: "8:00pm", discount: 0 },
  ];

  const [timeSelected, setTimeSelected] = useState();

  //Customer info section

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const handleFormSubmit = async (value, actions) => {
    await sleep(500);
    setActiveStep(activeStep + 1);

    // console.log(value);
  };

  const [valueTotal, setValueTotal] = useState([]);

  const [completedFill, setCompletedFill] = useState(false);

  const [discount, setDiscount] = useState("");
  console.log({discount})

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} sx={{ m: "20px" }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography m={{ xs: 2, sm: 1 }} variant="h6">
            All steps completed - you&apos;re finished. All information will
            send your email at {valueTotal.billingAddress.email} for
            confirmation. Please check your email.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
            <Button
              variant="outlined"
              onClick={() => navigate(`/operator/${operatorId}`)}
            >
              Return
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <Box
              border={0}
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Box
                width={{ xs: "100%", sm: "50%" }}
                border={0}
                m={{ xs: 0, sm: 1 }}
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box
                  bgcolor={grey[400]}
                  display={"flex"}
                  flexDirection={{ xs: "row", sm: "column" }}
                  justifyContent={{ xs: "space-between", sm: "start" }}
                >
                  <Typography variant="h6" p={1}>
                    {date}
                  </Typography>
                  <Typography variant="h6" p={1} fontWeight={100}>
                    Booking for {customer}
                  </Typography>
                  {isNonMobile && (
                    <Typography variant="h6" p={1} fontWeight={100}>
                      For {purpose}
                    </Typography>
                  )}
                  <Typography variant="h6" p={1} fontWeight={100}>
                    At {timeSelected}
                  </Typography>
                </Box>
                <Box m={"0rem auto"} mt={3}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={changeDate}
                    startDate={startDate}
                    minDate={new Date()}
                    customInput={<CustomInput />}
                    inline
                    wrapperClassName="datePicker"
                  />
                </Box>
              </Box>
              <Box border={0} m={1}>
                <Box
                  border={0}
                  borderColor={"divider"}
                  display={"flex"}
                  alignItems={"center"}
                  m={1}
                  boxShadow={1}
                  borderRadius={1}
                >
                  <Typography variant="h6" ml={2}>
                    Booking for {customer} people
                  </Typography>
                  <IconButton onClick={() => setCustomer(customer + 1)}>
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton onClick={() => setCustomer(customer - 1)}>
                    <RemoveCircleOutlineOutlinedIcon />
                  </IconButton>
                </Box>
                <Box
                  border={0}
                  borderColor={"divider"}
                  display={"flex"}
                  alignItems={"center"}
                  m={1}
                  p={1}
                  boxShadow={1}
                  borderRadius={1}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Breakfast"
                        onChange={() => setPurpose("Breakfast")}
                        control={<Radio />}
                        label="Breakfast"
                      />
                      <FormControlLabel
                        value="Lunch"
                        onChange={() => setPurpose("Lunch")}
                        control={<Radio />}
                        label="Lunch"
                      />
                      <FormControlLabel
                        value="Everning"
                        onChange={() => setPurpose("Evening")}
                        control={<Radio />}
                        label="Everning"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  border={0}
                  borderColor={"divider"}
                  display={"flex"}
                  alignItems={"start"}
                  flexDirection={"column"}
                  m={1}
                  p={1}
                  boxShadow={1}
                  borderRadius={1}
                >
                  <Typography variant="h6">Select Time </Typography>
                  <Box
                    border={0}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "400px",
                    }}
                  >
                    <RadioGroup defaultValue={bookingTime[0].value} row>
                      {bookingTime.map((booking, bookingindex) => (
                        <Badge
                          badgeContent={
                            booking.discount !== 0 ? booking.discount : 0
                          }
                          sx={{
                            "& .MuiBadge-badge": {
                              left: -20,
                              top: 6,
                              border: "none",
                              padding: "0px",
                              color: "secondary.main",
                              background: "transparent",
                              width: 80,
                            },
                          }}
                        >
                          <FormControlLabel
                            value={booking.value}
                            onChange={() =>
                              setTimeSelected(booking.value) &&
                              setDiscount(booking.discount)
                            }
                            control={<Radio />}
                            label={booking.value}
                          />
                        </Badge>
                      ))}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* //customer detail section */}
          {activeStep === 1 && (
            <Box flex={"1 1 60%"} p={3} bgcolor={"#fff"} boxShadow={2}>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema[activeStep]}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Shipping
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    {setValueTotal(values)}
                    {console.log({ valueTotal })}
                  </form>
                )}
              </Formik>
            </Box>
          )}
          {activeStep === 2 && (
            <Box
              flex={"1 1 60%"}
              p={3}
              bgcolor={"#fff"}
              boxShadow={2}
              m={{ xs: 1, sm: 3 }}
            >
              <Typography
                sx={{ mb: "15px" }}
                fontSize="18px"
                fontWeight={700}
                textTransform={"uppercase"}
                color={"primary.contrastText"}
                display={"flex"}
              >
                <SummarizeIcon sx={{ mr: 1 }} /> Confirm your detail
              </Typography>
              <TextField
                fullWidth
                type="text"
                label="First Name"
                value={valueTotal.billingAddress.firstName}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="Last Name"
                value={valueTotal.billingAddress.lastName}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="Phone"
                value={valueTotal.billingAddress.phoneNumber}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="Phone"
                value={valueTotal.billingAddress.email}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="Date"
                value={date}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="Customer"
                value={customer}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="For"
                value={purpose}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                type="text"
                label="At"
                value={timeSelected}
                variant="standard"
                sx={{ mb: 1 }}
              />
              {/* {valueTotal.billingAddress.firstName === "" ? (
                <Box
                  sx={{
                    mt: 1,
                    p: 1,
                    bgcolor: "secondary.main",
                    borderRadius: "5px",
                  }}
                >
                  <Typography sx={{ color: "secondary.white" }}>
                    You need to completed all fills (*)
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    mt: 1,
                    p: 1,
                    bgcolor: "secondary.main",
                    borderRadius: "5px",
                  }}
                >
                  <Typography sx={{ color: "secondary.white" }}>
                    you completed filled
                  </Typography>
                </Box>
              )} */}
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }} border={0}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {console.log({ completedFill })}
            <Button
              onClick={handleNext}
              // disabled={!completedFill && activeStep === 2}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

const initialValues = {
  billingAddress: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    zipCode: "",
  },
  shippingAddress: {
    isSameAddress: true,
    firstName: "",
    lastName: "",
    country: "",
    street1: "",
    street2: "",
    city: "",
    zipCode: "",
  },
  orderingMethod: "",
  availableDate: "",
  availableTime: "",
  paymentMethod: "",
};
const checkoutSchema = [
  yup.object().shape({
    billingAddress: yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      email: yup.string().required("required"),
      phoneNumber: yup.string().required("required"),
      country: yup.string().required("required"),
      street1: yup.string().required("required"),
      street2: yup.string(),
      city: yup.string().required("required"),
      zipCode: yup.string().required("required"),
    }),
    shippingAddress: yup.object().shape({
      isSameAddress: yup.boolean(),
      firstName: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      lastName: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      country: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      street1: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      street2: yup.string(),
      city: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      state: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
      zipCode: yup.string().when("isSameAddress", {
        is: false,
        then: yup.string().required("required"),
      }),
    }),
  }),
  yup.object().shape({
    orderingMethod: yup.string().required("required"),
    availableDate: yup.string().required("required"),
    availableTime: yup.string().required("required"),
    paymentMethod: yup.string().required("required"),
  }),
];

export default BookingSteps;
