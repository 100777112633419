import React, { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./menulist.css";
import AddIcon from "@mui/icons-material/Add";

const Categories = ({ categories, filterItems, activeCategory }) => {
  // console.log({categories})
  // console.log({filterItems})
  // console.log({activeCategory})
  const breakPoint = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState("all");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        width="100%"
        className="limitedtextinTab"
        display="grid"
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr 1fr 1fr " }}
      >
        {categories.map((category, index) => {
          return (
            <button
              type="button"
              className={`${
                activeCategory === category ? "filter-btn active" : "filter-btn"
              }`}
              key={index}
              onClick={() => filterItems(category)}
            >
              {category}
            </button>
          );
        })}
      </Box>
        <input className="expand-btn" type="checkbox" />
    </Box>
  );
};

export default Categories;
