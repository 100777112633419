import { getIn } from "formik";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "antd";
import { Formik, Form, Field } from 'formik';


const AddressForm = ({
  type,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  // console.log({values})

  // these functions allow for better code readability
  const formattedName = (field) => `${type}.${field}`;

  const formattedError = (field) =>
    Boolean(
      getIn(touched, formattedName(field)) &&
        getIn(errors, formattedName(field))
    );

  const formattedHelper = (field) =>
    getIn(touched, formattedName(field)) && getIn(errors, formattedName(field));

  return (
    <>
      <Box
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          type="text"
          label="First Name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstName}
          name={formattedName("firstName")}
          error={formattedError("firstName")}
          helperText={formattedHelper("firstName")}
          sx={{ gridColumn: "span 2", border:"none" }}
          variant="standard" 
        />
        <TextField
          fullWidth
          type="text"
          label="Last Name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
          name={formattedName("lastName")}
          error={formattedError("lastName")}
          helperText={formattedHelper("lastName")}
          sx={{ gridColumn: "span 2" }}
          variant="standard" 

        />
        <TextField
          fullWidth
          type="text"
          label="Email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          name={formattedName("email")}
          error={formattedError("email")}
          helperText={formattedHelper("email")}
          sx={{ gridColumn: "span 2" }}
          variant="standard" 

        />
        <TextField
          fullWidth
          type="text"
          label="Phone Number"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.phoneNumber}
          name={formattedName("phoneNumber")}
          error={formattedError("phoneNumber")}
          helperText={formattedHelper("phoneNumber")}
          sx={{ gridColumn: "span 2" }}
          variant="standard" 

        />
        <TextField
          fullWidth
          type="text"
          label="Country"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.country}
          name={formattedName("country")}
          error={formattedError("country")}
          helperText={formattedHelper("country")}
          sx={{ gridColumn: "span 2" }}
          variant="standard" 

        />
        <TextField
          fullWidth
          type="text"
          label="Note"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.street1}
          name={formattedName("street1")}
          error={formattedError("street1")}
          helperText={formattedHelper("street1")}
          sx={{ gridColumn: "span 2" }}
          variant="standard" 

        />
       

      </Box>
    </>
  );
};

export default AddressForm;
