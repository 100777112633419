import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import { menu, MenuCategory } from "../../data/dataRoyal";
import Categories from "./Menulist/Categories";
import Menu from "./Menulist/Menu";
import Menuall from "./Menulist/MenuAll"
import "./Menulist/menulist.css";

const allCategories = ["all", ...new Set(menu.map((item) => item.category))];
// console.log({allCategories})
// console.log({menu})

const Menulist = () => {
  const [menuItems, setMenuItems] = useState(menu);
  // console.log({ menuItems });
  const [activeCategory, setActiveCategory] = useState("");
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(menu);
      return;
    }
    const newItems = menu.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <Box width="80%" margin="80px auto">
      <Typography variant="h3" textAlign="center" color="hsl(209, 61%, 16%)">
        Our <b>Menu</b>
      </Typography>
      <Categories
        categories={categories}
        activeCategory={activeCategory}
        filterItems={filterItems}
      />
      {activeCategory !== "all" ? <Menu items={menuItems} category={activeCategory}/> : null}
      {activeCategory === "all" ? <Menuall items={menuItems} /> : null} 
        
      {/* </Box> */}
    </Box>
  );
};

export default Menulist;
