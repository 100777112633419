import { Paper, Stack, Button, Box, Divider, Typography } from "@mui/material";
import React from "react";
import Container from "../Container";
import Logo from "../Logo";
import menuConfigs from "../../../configs/menu.configs";
import uiConfigs from "../../../configs/ui.configs";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Container>
      <Paper
        square={true}
        sx={{
          backgroundImage: "unset",
          padding: "2rem",
          mb: { xs: 10, md: 0 },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction={{ xs: "column", md: "row " }}
          sx={{ height: "max-content", ...uiConfigs.style.mainContent }}
        >
          <Logo />
          <Box>
            {menuConfigs.main.map((item, index) => (
              <Button
                key={index}
                sx={{ color: "inherit" }}
                component={Link}
                to={item.path}
              >
                {item.display}
              </Button>
            ))}
          </Box>

        </Stack>
        <Divider
            sx={{ height: "max-content", ...uiConfigs.style.mainContent }}
          />
      </Paper>
    </Container>
  );
};

export default Footer;
